import { Component, Input } from "@angular/core";
import { Router } from "@angular/router";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { Junction } from "src/app/junctions/common/base.junction.model";
import { a4LandscapeSizeMM as a4 } from "src/app/config/app.const";
import { round } from "src/app/helper/app.util";
import { savedTmData } from "src/app/store/global.store";
import { AuthenticationService } from "src/app/services/authentication/authentication.service";
import { JunctionService } from "src/app/services/junction.service";

@Component({
  selector: "app-jn-result",
  templateUrl: "./jn-result.component.html",
  styleUrls: ["./jn-result.component.css"],
})
export class JnResultComponent {
  @Input() jn: Junction;
  pdfGenerationBtn = { text: "Download PDF Report", disabled: false };
  saveJnBtn = { text: "Save Junction", disabled: false };
  jnName = "";

  constructor(
    public authService: AuthenticationService,
    public jnService: JunctionService,
    private readonly router: Router
  ) {}
  get calcOutput(): any {
    return savedTmData[this.jn.calcId];
  }
  get qValue() {
    return this.calcOutput ? round(this.calcOutput.qvalue) : "-";
  }
  get uValue1() {
    return this.calcOutput ? round(this.calcOutput.uvalue1) : "-";
  }
  get uValue2() {
    return this.calcOutput ? round(this.calcOutput.uvalue2) : "-";
  }
  get psiValue() {
    return this.calcOutput ? round(this.calcOutput.psivalue) : "-";
  }
  get fValue() {
    return this.calcOutput ? round(this.calcOutput.fvalue) : "-";
  }
  login = () => this.router.navigate(["login"]);
  toggleView() {
    this.jn.tab = this.jn.tab === "junction" ? "temperature" : "junction";
  }
  view(tab: string): boolean {
    return this.jn.tab === tab;
  }
  public async generatePdfData() {
    this.jn.generateLabels();
    this.jn.showLabels = true;
    setTimeout(() => {
      this.processJunctionSvg();
      this.jn.showLabels = false;
    }, 1);
  }
  /**
   * http://stackoverflow.com/questions/3768565/drawing-a-svg-file-on-a-html5-canvas
   * https://jsfiddle.net/v21pow54/5/
   */
  private async processJunctionSvg(): Promise<void> {
    try {
      // move classes inside Svg
      const svg = document.getElementById("jn-svg-boundary");
      if (!svg) throw new Error("element not found");
      document
        .querySelectorAll(".svg-class-marker")
        .forEach((e) => svg.appendChild(e));
      // get svg data
      const xml: string = new XMLSerializer().serializeToString(svg);
      // make it base64
      const svg64: string = btoa(xml);
      const image64 = "data:image/svg+xml;base64," + svg64;
      // set it as the source of the img element
      const jnImg = document.getElementById("pdf-jn-img") as HTMLImageElement;
      if (!jnImg) throw new Error("image element not found");
      jnImg.src = image64;
    } catch (er) {
      console.log("Failed creating image for PDF report", er);
    } finally {
      // undo all DOM modification changes
      // this.jn.reloadJn();
    }
  }
  /**
   * https://stackoverflow.com/questions/56245991/how-to-concatenate-two-pages-with-html2canvas-and-jspdf
   * https://stackoverflow.com/questions/53178306/how-to-show-scrollable-all-contents-of-div-in-pdf-using-html2canvas
   *
   */
  async downloadPdf(): Promise<void> {
    this.pdfGenerationBtn.text = "Please Wait...";
    this.pdfGenerationBtn.disabled = true;
    var pdf = new jsPDF("l", "mm", "a4");
    // // Document of 210mm wide and 297mm high
    // new jsPDF('p', 'mm', [297, 210]);
    // // Document of 297mm wide and 210mm high
    // new jsPDF('l', 'mm', [297, 210]);

    let pages = ["pdf-html-container-1"];
    let promises = [];
    pages.forEach((p) => {
      promises.push(this.getCanvasData(document.getElementById(p)));
    });
    Promise.all(promises).then((images) => {
      let [w, h] = [a4.width, a4.height];
      try {
        images.forEach((img, i) => {
          // [1] add the image to current pdf page
          pdf.addImage(img, "JPEG", 0, 0, w, h, undefined, "FAST");
          // [2] replace footer with hyperlink
          pdf.setDrawColor("#FFFFFF");
          pdf.setFillColor("#FFFFFF");
          pdf.rect(13, 204, 35, 4, "FD");
          pdf.setTextColor("#d8d8d8");
          pdf.setFont("Roboto, sans-serif");
          pdf.setFontSize(8);
          pdf.textWithLink("Powered by AutoPSI.co.uk", 15, 206, {
            url: "https://autopsi.co.uk",
          });
          // [3] add new page to next
          pdf.addPage();
        });
        pdf.deletePage(pages.length + 1); // delete last empty page
        pdf.save(this.jn.pdfFileName);
      } catch (er) {
        console.error(er);
      } finally {
        this.pdfGenerationBtn.text = "Download PDF Report";
        this.pdfGenerationBtn.disabled = false;
      }
    });
  }
  private getCanvasData(element: HTMLElement) {
    const options = {
      dpi: 300, // Set to 300 DPI
      scale: 3, // Adjusts your resolution
      logging: true,
    };
    return new Promise((resolve, reject) => {
      html2canvas(element, options)
        .then(function (canvas: HTMLCanvasElement) {
          resolve(canvas.toDataURL("image/jpeg"));
        })
        .catch(function (er) {
          console.log(er);
          reject(`Error creating canvas [${element.id}]`);
        });
    });
  }
  async saveJunction(): Promise<void> {
    if (this.jn && this.jnName) {
      this.jn.name = this.jnName;
      const resp = await this.jnService.saveJunctionDTO(this.jn.jnDto);
    }
  }
}
