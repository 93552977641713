<div class="outer-container">
  <div class="top-content">
    <div class="inner-bg">
      <div class="container-fluid">
        <!-- Element choice -->
        <h2 class="text-green">Select an element:</h2>
        <div class="row">
          <div class="col-md-3" *ngFor="let element of elements; let i = index">
            <div class="outer-box {{ tabsActive[i] ? 'active' : 'inactive'}}" (click)="toggleClass(i)">
              <a (click)="getSubCat(element.name)">
                <div class="square-box" [ngStyle]="{'background': 'url('+element.imageURL+')'}">
                  <div class="box-label">
                    <p>{{element.name}}</p>
                    <span class="label-bg" [ngStyle]="{'background': element.color}"></span>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
        <!-- Junction choice -->
        <h2 class="text-green" *ngIf="displayedJunctions.length > 0">Select a junction:</h2>
        <div class="row">
          <div class="col-md-4" *ngFor="let junction of displayedJunctions">
            <a class="jn-link" [routerLink]="'/calculator'" [queryParams]="{ id: junction.code }"
              [ngStyle]="{'background-color': currentColor}">
              {{junction.title}}
              <mat-icon class="jn-link-arrow" aria-hidden="false" aria-label="Example home icon">east</mat-icon>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>