import { MaterialInfo } from "../interfaces/mat.info.interface";
import { BridgeDTO } from "../junctions/common/bridge.dto";
import { GroundDTO } from "../junctions/common/ground.dto";
import { BackGround } from "./app.const";

export const E05BLayerOptions: MaterialInfo[][] = [
  // [0] layer-1 conditional layer (only if the user selected the next layer as PIR Laminate)
  [
    {
      material: "Plasterboard facing to laminate",
      code: "AA",
      kvalue_options: [0.19],
      thickness_options: [{ val: 9.5, comb: "" }],
      background: BackGround.plasterboard,
    } as MaterialInfo,
  ],
  // [1] layer-2 options
  [
    {
      material: "Plasterboard",
      code: "AB",
      kvalue_options: [0.19, 0.21, 0.24, 0.25],
      thickness_options: [
        { val: 12.5, comb: "" },
        { val: 15, comb: "" },
      ],
      background: BackGround.plasterboard,
    } as MaterialInfo,
    {
      material: "Knauf PIR Laminate 35mm",
      code: "AC",
      kvalue_options: [0.023],
      thickness_options: [{ val: 25.5, comb: "" }],
      background: BackGround.laminate,
    } as MaterialInfo,
    {
      material: "Knauf PIR Laminate 50mm",
      code: "AD",
      kvalue_options: [0.023],
      thickness_options: [{ val: 40.5, comb: "" }],
      background: BackGround.laminate,
    } as MaterialInfo,
    {
      material: "Knauf PIR Laminate 65mm",
      code: "AE",
      kvalue_options: [0.023],
      thickness_options: [{ val: 55.5, comb: "" }],
      background: BackGround.laminate,
    } as MaterialInfo,
    {
      material: "Knauf PIR Laminate 75mm",
      code: "AF",
      kvalue_options: [0.023],
      thickness_options: [{ val: 65.5, comb: "" }],
      background: BackGround.laminate,
    } as MaterialInfo,
  ],
  // [2] layer-3 options
  [
    {
      material: "Drylining - plaster dabs",
      code: "AG",
      kvalue_options: [0.088],
      thickness_options: [{ val: 15, comb: "" }],
      background: BackGround.cavity,
    } as MaterialInfo,
  ],
  // [3] layer-4 options
  [
    {
      material: "Blockwork - Lightweight Aircrete",
      code: "AH",
      kvalue_options: [0.11],
      thickness_options: [
        { val: 100, comb: "" },
        { val: 140, comb: "" },
      ],
      background: BackGround.block,
    } as MaterialInfo,
    {
      material: "Blockwork - Standard Aircrete",
      code: "AI",
      kvalue_options: [0.15],
      thickness_options: [
        { val: 100, comb: "" },
        { val: 140, comb: "" },
      ],
      background: BackGround.block,
    } as MaterialInfo,
    {
      material: "Blockwork - High Strength Aircrete",
      code: "AJ",
      kvalue_options: [0.19],
      thickness_options: [
        { val: 100, comb: "" },
        { val: 140, comb: "" },
      ],
      background: BackGround.block,
    } as MaterialInfo,
    {
      material: "Blockwork - Medium Block",
      code: "AK",
      kvalue_options: [0.45, 0.5, 0.51],
      thickness_options: [
        { val: 100, comb: "" },
        { val: 140, comb: "" },
      ],
      background: BackGround.block,
    } as MaterialInfo,
    {
      material: "Blockwork - Dense Block",
      code: "AL",
      kvalue_options: [1.13],
      thickness_options: [
        { val: 100, comb: "" },
        { val: 140, comb: "" },
      ],
      background: BackGround.block,
    } as MaterialInfo,
  ],
  // [4] layer-5 options
  [
    {
      material: "DriTherm® Cavity Slab 37",
      code: "AM",
      kvalue_options: [0.037],
      thickness_options: [
        { val: 100, comb: "" },
        { val: 115, comb: "50+65mm" },
        { val: 125, comb: "" },
        { val: 130, comb: "2x65mm" },
        { val: 140, comb: "65+75mm" },
        { val: 150, comb: "" },
        { val: 160, comb: "75+85mm" },
        { val: 165, comb: "100+65mm" },
        { val: 170, comb: "2x85mm" },
        { val: 175, comb: "100+75mm" },
        { val: 185, comb: "100+85mm" },
        { val: 200, comb: "2x100mm" },
      ],
      background: BackGround.insulation,
    } as MaterialInfo,
    {
      material: "DriTherm® Cavity Slab 34",
      code: "AN",
      kvalue_options: [0.034],
      thickness_options: [
        { val: 100, comb: "" },
        { val: 125, comb: "" },
        { val: 150, comb: "" },
        { val: 175, comb: "100+75mm" },
        { val: 200, comb: "2x100mm" },
      ],
      background: BackGround.insulation,
    } as MaterialInfo,
    {
      material: "DriTherm® Cavity Slab 32",
      code: "AO",
      kvalue_options: [0.032],
      thickness_options: [
        { val: 100, comb: "" },
        { val: 125, comb: "" },
        { val: 150, comb: "" },
        { val: 160, comb: "75+85mm" },
        { val: 170, comb: "2x85mm" },
        { val: 175, comb: "100+75mm" },
        { val: 185, comb: "100+85mm" },
        { val: 200, comb: "2x100mm" },
      ],
      background: BackGround.insulation,
    } as MaterialInfo,
    {
      material: "Knauf Supafil® 34",
      code: "AP",
      kvalue_options: [0.034],
      thickness_options: [
        { val: 100, comb: "" },
        { val: 125, comb: "" },
        { val: 150, comb: "" },
        { val: 175, comb: "" },
        { val: 200, comb: "" },
      ],
      background: BackGround.insulation,
    } as MaterialInfo,
  ],
  // [5] layer-6 options
  [
    {
      material: "Blockwork - Lightweight Aircrete",
      code: "AQ",
      kvalue_options: [0.11],
      thickness_options: [
        { val: 100, comb: "" },
        { val: 140, comb: "" },
      ],
      background: BackGround.block,
    } as MaterialInfo,
    {
      material: "Blockwork - Standard Aircrete",
      code: "AR",
      kvalue_options: [0.15],
      thickness_options: [
        { val: 100, comb: "" },
        { val: 140, comb: "" },
      ],
      background: BackGround.block,
    } as MaterialInfo,
    {
      material: "Blockwork - High Strength Aircrete",
      code: "AS",
      kvalue_options: [0.19],
      thickness_options: [
        { val: 100, comb: "" },
        { val: 140, comb: "" },
      ],
      background: BackGround.block,
    } as MaterialInfo,
    {
      material: "Blockwork - Medium Block",
      code: "AT",
      kvalue_options: [0.45, 0.5, 0.51],
      thickness_options: [
        { val: 100, comb: "" },
        { val: 140, comb: "" },
      ],
      background: BackGround.block,
    } as MaterialInfo,
    {
      material: "Blockwork - Dense Block",
      code: "AU",
      kvalue_options: [1.21],
      thickness_options: [
        { val: 100, comb: "" },
        { val: 140, comb: "" },
      ],
      background: BackGround.block,
    } as MaterialInfo,
    {
      material: "Brick",
      code: "AV",
      kvalue_options: [0.77],
      thickness_options: [{ val: 102.5, comb: "" }],
      background: BackGround.brick,
    } as MaterialInfo,
    {
      material: "Natural Stone - Cut to face",
      code: "AW",
      kvalue_options: [2.3],
      thickness_options: [
        { val: 100, comb: "" },
        { val: 150, comb: "" },
      ],
      background: BackGround.stone,
    } as MaterialInfo,
  ],
  // [6] layer-7 conditional layer (only if the user selected the previous layer as Blockwork)
  [
    {
      material: "Render - Sand Cement",
      code: "AX",
      kvalue_options: [1.0],
      thickness_options: [{ val: 20, comb: "" }],
      background: BackGround.render,
    } as MaterialInfo,
  ],
];
export const E05BFloorLayers = [
  {
    material: "Screed",
    thickness: 75,
    kvalue: 1.15,
    background: BackGround.block,
  },
  {
    material: "Insulation",
    thickness: 100,
    kvalue: 0.022,
    background: BackGround.insulation_pink,
  },
  {
    material: "Concrete Beam (parallel to the wall)",
    thickness: 150,
    kvalue: 1.33,
    background: BackGround.concrete_beam,
  },
  {
    material: "Slab",
    thickness: 100,
    kvalue: 1.15,
    background: BackGround.slab,
  },
  {
    material: "Slab",
    thickness: 100,
    kvalue: 1.15,
    background: BackGround.slab,
  },
];
export const E05BGroundLayers = [
  // Ground layer [1]
  {
    material: "Block",
    height: 650,
    kvalue: 1.33,
    background: BackGround.concrete,
  },
  // Ground layer [2]
  {
    material: "Insulation",
    height: 225,
    kvalue: 0.032,
    background: BackGround.insulation_044,
  },
  // Ground layer [3]
  {
    material: "Cavity",
    height: 225,
    kvalue: 0.548,
    background: BackGround.cavity,
  },
  // Ground layer [4]
  {
    material: "Concrete",
    height: 200,
    kvalue: 2.0,
    background: BackGround.concrete_k2,
  },
];
export const E05BGround = [
  { base: { x: 0, y: 1000 }, width: 1850, height: 800 } as GroundDTO,
];
export const E05BBridges = [
  // Bridge [1]
  {
    material: "Plasterboard",
    base: { x: undefined, y: undefined },
    width: 12.5,
    height: 100,
    kvalue: 0.19,
    background: BackGround.plasterboard,
  } as BridgeDTO,
  // Bridge [2]
  {
    material: "Insulation",
    base: { x: undefined, y: undefined },
    width: 30,
    height: undefined,
    kvalue: 0.033,
    background: BackGround.insulation_pink,
  } as BridgeDTO,
];
export const E05BChecklist = [
  "Perimeter insulation with a resistance of at least 0.8 W/m²K installed",
  "Floor insulation is tightly against the external wall",
  "Cavity insulation continues at least 225mm below the top of the concrete",
];
export const E05BDetails = [
  "Screed; 75mm deep/ 1.15 W/mK",
  "Edge Insulation; 30mm wide/ 0.022 W/mK",
  "Insulation (rigid foam); 100mm deep/ 0.022 W/mK",
  "Slab; 100mm deep/ 1.15 W/mK",
];
