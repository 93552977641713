import { Component } from "@angular/core";

import { AuthenticationService } from "../../services/authentication/authentication.service";

@Component({
  selector: "app-welcome",
  templateUrl: "./welcome.component.html",
  styleUrls: ["./welcome.component.css"],
})
export class WelcomeComponent {
  public userAuthed = false;

  constructor(public authService: AuthenticationService) {
    if (authService.isLoggedIn$) {
      this.userAuthed = true;
    }
  }

  logout = () => this.authService.logout().subscribe();
}
