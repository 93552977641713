import {
  E12FloorLayerOptions,
  E12LayerOptions,
} from "src/app/config/e12.options";
import { BridgeDTO } from "src/app/junctions/common/bridge.dto";
import { Bridge } from "src/app/junctions/common/bridge.model";
import { JnDTO } from "src/app/junctions/common/jn.dto";
import { LayerDTO } from "src/app/junctions/common/layer.dto";
import { E12DTO } from "src/app/junctions/e12/e12.dto";
import { E12Junction } from "src/app/junctions/e12/e12.junction.model";
import * as template from "src/app/junctions/e12/e12.templates";

export function genE12Inputs(
  start: number,
  count: number
): { opts: any; total: number } {
  const output: any = {};
  let n = 0;
  const templateBridges: BridgeDTO[] = getTemplateBridges();
  createLayerOptions(0).forEach((lyr0) => {
    createLayerOptions(1).forEach((lyr1) => {
      createLayerOptions(2).forEach((lyr2) => {
        createLayerOptions(3).forEach((lyr3) => {
          createLayerOptions(4).forEach((lyr4) => {
            createLayerOptions(5).forEach((lyr5) => {
              createLayerOptions(6).forEach((lyr6) => {
                createFlrLayerOptions(0).forEach((flyr0) => {
                  createFlrLayerOptions(1).forEach((flyr1) => {
                    createFlrLayerOptions(2).forEach((flyr2) => {
                      createFlrLayerOptions(3).forEach((flyr3) => {
                        if (n >= start && n < start + count) {
                          const e12: E12Junction = createNewE12Junction(
                            [lyr0, lyr1, lyr2, lyr3, lyr4, lyr5, lyr6],
                            [flyr0, flyr1, flyr2, flyr3],
                            templateBridges
                          );
                          if (!e12.calcId.includes("@INVALID@"))
                            output[e12.calcId] = e12.calcInput;
                        }
                        n++;
                      });
                    });
                  });
                });
              });
            });
          });
        });
      });
    });
  });
  console.log(`Input generated: start: ${start}, end: ${start + count - 1}`);
  console.log(`count: ${Object.keys(output).length}, total: ${n}`);
  return { opts: output, total: n };
}
function createLayerOptions(lyrIndex: number): LayerDTO[] {
  const lyrOpts = [];
  E12LayerOptions[lyrIndex].forEach((matOpt, i) => {
    const matIndex = i;
    matOpt.kvalue_options.forEach((kvalOpt, j) => {
      const kvlIndex = j;
      matOpt.thickness_options.forEach((thkOpt, k) => {
        const thkIndex = k;
        lyrOpts.push({ matIndex, thkIndex, kvlIndex } as LayerDTO);
      });
    });
  });
  return lyrOpts;
}
function createFlrLayerOptions(lyrIndex: number): LayerDTO[] {
  const lyrOpts = [];
  E12FloorLayerOptions[lyrIndex].forEach((matOpt, i) => {
    const matIndex = i;
    matOpt.kvalue_options.forEach((kvalOpt, j) => {
      const kvlIndex = j;
      matOpt.thickness_options.forEach((thkOpt, k) => {
        const thkIndex = k;
        lyrOpts.push({ matIndex, thkIndex, kvlIndex } as LayerDTO);
      });
    });
  });
  return lyrOpts;
}
function createNewE12Junction(
  layers: LayerDTO[],
  flrLayers: LayerDTO[],
  bridges: BridgeDTO[]
): E12Junction {
  const jnDTO = {
    _id: "dummy",
    name: "E12 (dummy)",
    jntype: "E12",
    jndata: { layers, flrLayers, bridges, extras: [] } as E12DTO,
  } as JnDTO;
  return new E12Junction(jnDTO);
}
function getTemplateBridges(): BridgeDTO[] {
  const bdgDTOs: BridgeDTO[] = [];
  const bgs: Bridge[] = template.getNewE12Junction().bridges;
  bgs.forEach((b) => bdgDTOs.push(b.dto_copy));
  return bdgDTOs;
}
