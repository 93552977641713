import { E01LayerOptions } from "src/app/config/e01.options";
import { BridgeDTO } from "src/app/junctions/common/bridge.dto";
import { Bridge } from "src/app/junctions/common/bridge.model";
import { JnDTO } from "src/app/junctions/common/jn.dto";
import { LayerDTO } from "src/app/junctions/common/layer.dto";
import { E01DTO } from "src/app/junctions/e01/e01.dto";
import { E01Junction } from "src/app/junctions/e01/e01.junction.model";
import * as template from "src/app/junctions/e01/e01.templates";

export function getAllPossibleE01Inputs(): any {
  const output = {};
  const templateBridges: BridgeDTO[] = getTemplateBridges();
  createLayerOptions(0).forEach((lyr0) => {
    createLayerOptions(1).forEach((lyr1) => {
      createLayerOptions(2).forEach((lyr2) => {
        createLayerOptions(3).forEach((lyr3) => {
          createLayerOptions(4).forEach((lyr4) => {
            createLayerOptions(5).forEach((lyr5) => {
              createLayerOptions(6).forEach((lyr6) => {
                const e01: E01Junction = createNewE01Junction(
                  lyr0,
                  lyr1,
                  lyr2,
                  lyr3,
                  lyr4,
                  lyr5,
                  lyr6,
                  templateBridges
                );
                output[e01.calcId] = e01.calcInput;
              });
            });
          });
        });
      });
    });
  });
  return output;
}
function createLayerOptions(lyrIndex: number): LayerDTO[] {
  const lyrOpts = [];
  E01LayerOptions[lyrIndex].forEach((matOpt, i) => {
    const matIndex = i;
    matOpt.kvalue_options.forEach((kvalOpt, j) => {
      const kvlIndex = j;
      matOpt.thickness_options.forEach((thkOpt, k) => {
        const thkIndex = k;
        lyrOpts.push({ matIndex, thkIndex, kvlIndex } as LayerDTO);
      });
    });
  });
  return lyrOpts;
}
function createNewE01Junction(
  lyr0: LayerDTO,
  lyr1: LayerDTO,
  lyr2: LayerDTO,
  lyr3: LayerDTO,
  lyr4: LayerDTO,
  lyr5: LayerDTO,
  lyr6: LayerDTO,
  bridges: BridgeDTO[]
): E01Junction {
  const jnDTO = {
    _id: "dummy",
    name: "E01 (dummy)",
    jntype: "E01",
    jndata: {
      layers: [lyr0, lyr1, lyr2, lyr3, lyr4, lyr5, lyr6],
      bridges,
      extras: [],
    } as E01DTO,
  } as JnDTO;
  return new E01Junction(jnDTO);
}
function getTemplateBridges(): BridgeDTO[] {
  const bdgDTOs: BridgeDTO[] = [];
  const bgs: Bridge[] = template.getNewE01Junction().bridges;
  bgs.forEach((b) => bdgDTOs.push(b.dto_copy));
  return bdgDTOs;
}
