import { createNewJnId } from "src/app/helper/app.util";
import { JnDTO } from "../common/jn.dto";
import { LayerDTO } from "../common/layer.dto";
import { E07DTO } from "./e07.dto";
import { E07Junction } from "./e07.junction.model";

export function getNewE07Junction(): E07Junction {
  const jnDTO = {
    _id: createNewJnId(),
    name: "E-07 Sep Floor",
    jntype: "E07",
    jndata: {
      layers: [
        {
          matIndex: 0,
          thkIndex: 0,
          kvlIndex: 0,
        } as LayerDTO,
        {
          matIndex: 0,
          thkIndex: 0,
          kvlIndex: 0,
        } as LayerDTO,
        {
          matIndex: 0,
          thkIndex: 0,
          kvlIndex: 0,
        } as LayerDTO,
        {
          matIndex: 0,
          thkIndex: 0,
          kvlIndex: 0,
        } as LayerDTO,
        {
          matIndex: 2,
          thkIndex: 2,
          kvlIndex: 0,
        } as LayerDTO,
        {
          matIndex: 5,
          thkIndex: 0,
          kvlIndex: 0,
        } as LayerDTO,
        {
          matIndex: 0,
          thkIndex: 0,
          kvlIndex: 0,
        } as LayerDTO,
      ],
      flrLayers: [
        {
          matIndex: 0,
          thkIndex: 0,
          kvlIndex: 0,
        } as LayerDTO,
        {
          matIndex: 0,
          thkIndex: 0,
          kvlIndex: 0,
        } as LayerDTO,
        {
          matIndex: 0,
          thkIndex: 0,
          kvlIndex: 0,
        } as LayerDTO,
        {
          matIndex: 0,
          thkIndex: 0,
          kvlIndex: 0,
        } as LayerDTO,
      ],
    } as E07DTO,
  } as JnDTO;
  return new E07Junction(jnDTO);
}
