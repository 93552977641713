import { getTMColor } from "../components/calculator/junctions/thermal-model/tm.color";
import { Junction } from "../junctions/common/base.junction.model";

class ThermalModelCanvas {
  tmData: number[][];
  displayLoader = true;

  private getDeltaX(jnType: string): number {
    if (jnType === "E01") return -45;
    if (jnType === "E02A") return -45;
    if (jnType === "E03") return -45;
    if (jnType === "E04") return -25;
    return 35; // "E05A","E05B","E06","E07","E10","E11","E12","E13","E16","E17"
  }
  private getDeltaY(jnType: string): number {
    if (jnType === "E01") return 35;
    if (jnType === "E02A") return 35;
    if (jnType === "E03") return -35;
    if (jnType === "E04") return -45;
    return 35; // "E05A","E05B","E06","E07","E10","E11","E12","E13","E16","E17"
  }
  setTmData(data: number[][]) {
    this.tmData = data;
    this.displayLoader = false;
  }
  clearTmData() {
    this.tmData = undefined;
    this.displayLoader = true;
  }
  private getCanvas(jn: Junction): HTMLCanvasElement {
    const canvas = document.getElementById(jn.canvasId) as HTMLCanvasElement;
    if (!canvas) {
      console.error(`canvas element with id[${jn.canvasId}] not created`);
      return undefined;
    }
    return canvas;
  }
  async drawTMCanvasPlaceHolder(jn: Junction): Promise<void> {
    if (jn.tab !== "temperature") return undefined;
    const canvas: HTMLCanvasElement = this.getCanvas(jn);
    if (!canvas) return;
    canvas.width = 10;
    canvas.height = 10;
    if (canvas.getContext) {
      const ctx = canvas.getContext("2d");
      ctx.fillStyle = "white";
      ctx.fillRect(0, 0, 100, 100);
      ctx.save();
      canvas.style.width = "10px";
      canvas.style.height = "10px";
    }
  }
  async drawTMCanvas(jn: Junction): Promise<HTMLCanvasElement> {
    if (jn.tab !== "temperature") return undefined;
    const canvas: HTMLCanvasElement = this.getCanvas(jn);
    if (!this.tmData || !canvas) return undefined;
    const cols = this.tmData[0].length;
    const rows = this.tmData.length;
    canvas.width = cols;
    canvas.height = rows;
    if (canvas.getContext) {
      const ctx = canvas.getContext("2d");
      // default fill entire canvas
      ctx.fillStyle = "white";
      ctx.fillRect(0, 0, canvas.width, canvas.height);
      // now fill based on the data
      for (let i = 0; i < rows; i++) {
        for (let j = 0; j < cols; j++) {
          ctx.fillStyle = getTMColor(this.tmData[i][j]);
          ctx.fillRect(j, i, 1, 1);
        }
      }
      ctx.save();
      canvas.style.width = `${jn.vw * jn.tmWidthFactor}px`;
      canvas.style.height = `${jn.vw * jn.tmHeightFactor}px`;
      return canvas;
    }
    return undefined;
  }
  calculateToolTip(jn: Junction, event: MouseEvent, toolTip: any): void {
    const canvas: HTMLCanvasElement = this.getCanvas(jn);
    if (tmCanvas.tmData && canvas) {
      try {
        const wef = jn.vw / this.tmData[0].length;
        const hef = jn.vw / this.tmData.length;
        const mouseX = +event.offsetX;
        const mouseY = +event.offsetY;
        const dx = this.getDeltaX(jn.jntype);
        const dy = this.getDeltaY(jn.jntype);
        toolTip.mouse.x = mouseX > dx ? mouseX - dx : mouseX + dx;
        toolTip.mouse.y = mouseY > dy ? mouseY - dy : mouseY + dy;
        const rect = canvas.getBoundingClientRect();
        const dataX = Math.round((event.clientX - rect.left) / wef);
        const dataY = Math.round((event.clientY - rect.top) / hef);
        if (this.tmData && this.tmData[dataY] && this.tmData[dataY][dataX]) {
          toolTip.text = `${Math.floor(this.tmData[dataY][dataX] * 100) / 100}`;
          toolTip.visibility = "visible";
        } else {
          toolTip.visibility = "hidden";
        }
      } catch (er) {
        console.error(er);
      }
    }
  }
}

export const tmCanvas = new ThermalModelCanvas();
