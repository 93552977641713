import { Component, Input } from "@angular/core";
import { BackGround } from "src/app/config/app.const";
import { block_base64 } from "src/app/store/data/base64/block.base64";
import { render_base64 } from "src/app/store/data/base64/render.base64";
import { stone_base64 } from "src/app/store/data/base64/stone.base64";
import { brick_base64 } from "src/app/store/data/base64/brick.base64";
import { cavity_base64 } from "src/app/store/data/base64/cavity.base64";
import { darkgray_background_base64 } from "src/app/store/data/base64/darkgray.background.base64";
import { insulation_base64 } from "src/app/store/data/base64/insulation.base64";
import { plasterboard_base64 } from "src/app/store/data/base64/plasterboard.base64";
import { Junction } from "src/app/junctions/common/base.junction.model";
import { steel_base64 } from "src/app/store/data/base64/steel.base64";
import { perforated_steel_base64 } from "src/app/store/data/base64/perforated_steel.base64";
import { insulation_pink_base64 } from "src/app/store/data/base64/insulation_pink.base64";
import { pvc_base64 } from "src/app/store/data/base64/pvc.background.base64";
import { ground_base64 } from "src/app/store/data/base64/ground.background.base64";
import { laminate_base64 } from "src/app/store/data/base64/laminate.base64";
import { timber_base64 } from "src/app/store/data/base64/timber.base64";
import { timber2_base64 } from "src/app/store/data/base64/timber2.base64";
import { concrete_base64 } from "src/app/store/data/base64/concrete.base64";
import { concrete_k2_base64 } from "src/app/store/data/base64/concrete.k2.base64";
import { slab_base64 } from "src/app/store/data/base64/slab.base64";
import { insulation_044_base64 } from "src/app/store/data/base64/insulation0_044.base64";
import { wood_base64 } from "src/app/store/data/base64/wood.base64";
import { wood_dark_base64 } from "src/app/store/data/base64/wood_dark.base64";
import { concrete_beam_base64 } from "src/app/store/data/base64/concrete.beam.base64";
import { screed_base64 } from "src/app/store/data/base64/screed.base64";
import { cavity_pink_base64 } from "src/app/store/data/base64/cavity_pink.base64";
import { clay_base64 } from "src/app/store/data/base64/clay.base64";

@Component({
  selector: "app-svg-class",
  templateUrl: "./svg-class.component.svg",
  styleUrls: [],
})
export class SvgClassComponent {
  @Input() className: string;
  @Input() patternName: string;
  @Input() background: number;
  @Input() jn: Junction;

  constructor() {}

  get scaleFactor(): string {
    if (this.jn.jntype === "E01") return "scale(0.4)";
    if (this.jn.jntype === "E02A") return "scale(0.4)";
    if (this.jn.jntype === "E03") return "scale(0.5)";
    if (this.jn.jntype === "E04") return "scale(0.6)";
    if (this.jn.jntype === "E05A") return "scale(1.2)";
    if (this.jn.jntype === "E05B") return "scale(1.2)";
    if (this.jn.jntype === "E06") return "scale(0.7)";
    if (this.jn.jntype === "E07") return "scale(0.7)";
    if (this.jn.jntype === "E10") return "scale(0.7)";
    if (this.jn.jntype === "E11") return "scale(0.7)";
    if (this.jn.jntype === "E12") return "scale(0.7)";
    if (this.jn.jntype === "E13") return "scale(0.7)";
    if (this.jn.jntype === "E16") return "scale(1)";
    if (this.jn.jntype === "E17") return "scale(1)";
    return "scale(1)";
  }
  get strokeWidth(): number {
    if (this.jn.jntype === "E01") return 0.4;
    if (this.jn.jntype === "E02A") return 0.4;
    if (["E03", "E04"].includes(this.jn.jntype)) return 0.6;
    if (this.jn.jntype === "E05A") return 1.5;
    if (this.jn.jntype === "E05B") return 1.5;
    if (this.jn.jntype === "E06") return 0.7;
    if (this.jn.jntype === "E07") return 0.7;
    if (this.jn.jntype === "E10") return 0.7;
    if (this.jn.jntype === "E11") return 0.7;
    if (this.jn.jntype === "E12") return 0.7;
    if (this.jn.jntype === "E13") return 0.7;
    if (this.jn.jntype === "E16") return 1;
    if (this.jn.jntype === "E17") return 1;
    return 1;
  }
  get rotate(): boolean {
    return this.jn.jntype === "E04";
  }
  get backGroundSvg() {
    const b = this.background;
    if (b === BackGround.render) return render_base64;
    if (b === BackGround.stone) return stone_base64;
    if (b === BackGround.brick) return brick_base64;
    if (b === BackGround.block) return block_base64;
    if (b === BackGround.cavity) return cavity_base64;
    if (b === BackGround.cavity_pink) return cavity_pink_base64;
    if (b === BackGround.plasterboard) return plasterboard_base64;
    if (b === BackGround.insulation) return insulation_base64;
    if (b === BackGround.steel) return steel_base64;
    if (b === BackGround.perforated_steel) return perforated_steel_base64;
    if (b === BackGround.insulation_pink) return insulation_pink_base64;
    if (b === BackGround.pvc) return pvc_base64;
    if (b === BackGround.ground) return ground_base64;
    if (b === BackGround.laminate) return laminate_base64;
    if (b === BackGround.timber) return timber_base64;
    if (b === BackGround.timber2) return timber2_base64;
    if (b === BackGround.concrete) return concrete_base64;
    if (b === BackGround.concrete_k2) return concrete_k2_base64;
    if (b === BackGround.concrete_beam) return concrete_beam_base64;
    if (b === BackGround.slab) return slab_base64;
    if (b === BackGround.insulation_044) return insulation_044_base64;
    if (b === BackGround.insulation_044_noBorder) return insulation_044_base64;
    if (b === BackGround.wood) return wood_base64;
    if (b === BackGround.wood_dark) return wood_dark_base64;
    if (b === BackGround.wood_dark_noBorder) return wood_dark_base64;
    if (b === BackGround.screed) return screed_base64;
    if (b === BackGround.clay) return clay_base64;
    if (b === BackGround.darkGray) return darkgray_background_base64;
    if (b === BackGround.none) return ground_base64; // should not be used
    return cavity_base64;
  }
  get borderColor(): string {
    if (this.background === BackGround.insulation_044_noBorder)
      return "orangered";
    else if (this.background === BackGround.wood_dark_noBorder)
      return "darkbrown";
    else return "black";
  }
}
