import { JnDTO } from "../common/jn.dto";
import { Junction } from "../common/base.junction.model";
import { E02ADTO } from "./e02a.dto";
import { Point } from "src/app/interfaces/point.interface";
import { PolyLayerPart } from "../common/poly.layer.part.model";
import { E02ABridges, E02ALayerOptions } from "src/app/config/e02a.options";
import { getReqId, mmToM } from "src/app/helper/app.util";
import { BridgeDTO } from "../common/bridge.dto";
import { Bridge } from "../common/bridge.model";
import { Label } from "src/app/interfaces/label.interface";
import { LayerDTO } from "../common/layer.dto";
import { MaterialInfo } from "src/app/interfaces/mat.info.interface";

export class E02AJunction extends Junction {
  pvcThickness = 2; // mm
  extra1 = { thickness: 3, top: 153 };

  constructor(private dto: JnDTO) {
    super();
    this.generateShapes();
  }
  get jnWidth(): number {
    return 650; // max possible thickness
  }
  get jnHeight(): number {
    return 480;
  }
  get tmWidthFactor(): number {
    return this.totalThickness / 650;
  }
  get tmHeightFactor(): number {
    return this.jnHeight / 650;
  }
  get id(): string {
    return this.dto._id;
  }
  get name(): string {
    return this.dto.name;
  }
  set name(s: string) {
    this.dto.name = s;
  }
  get jntype(): string {
    return this.dto.jntype;
  }
  get jnData(): E02ADTO {
    return this.dto.jndata as E02ADTO;
  }
  get jnDto(): JnDTO {
    return this.dto;
  }
  get owner(): string {
    return this.jnDto.owner;
  }
  get calcId(): string {
    let cid = "E02A@";
    this.jnData.layers.forEach((lyr: LayerDTO, index: number) => {
      if (this.isLayerHidden(index)) {
        cid += "@_@_@_@";
      } else {
        const m: MaterialInfo = E02ALayerOptions[index][lyr.matIndex];
        const code = m.code;
        const thk = m.thickness_options[lyr.thkIndex].val;
        const kval = m.kvalue_options[lyr.kvlIndex];
        cid += `@${code}@${thk}@${kval}@`;
      }
    });
    return cid + "@";
  }
  get laminateLayerIsOn(): boolean {
    return this.jnData.layers[1].matIndex !== 0; // 0 : Plasterboard
  }
  get blockworkIsOn(): boolean {
    return [0, 1, 2, 3, 4].includes(this.jnData.layers[5].matIndex); // Blockworks
  }
  get totalThickness(): number {
    return this.jnData.layers.reduce((x, lyrDTO, i) => {
      const m: MaterialInfo = E02ALayerOptions[i][lyrDTO.matIndex];
      return this.isLayerHidden(i)
        ? x
        : x + m.thickness_options[lyrDTO.thkIndex].val;
    }, 0);
  }
  get left(): number {
    return (this.jnWidth - this.totalThickness) / 2;
  }
  get calcInput(): any {
    const layers = [];
    this.jnData.layers.forEach((lyr: LayerDTO, index: number) => {
      if (!this.isLayerHidden(index)) {
        const m: MaterialInfo = E02ALayerOptions[index][lyr.matIndex];
        const layer: any = {
          material: m.material,
          kvalue: m.kvalue_options[lyr.kvlIndex],
          thicknessx: mmToM(m.thickness_options[lyr.thkIndex].val),
        };
        layers.push(layer);
      }
    });
    // add rse and rsi
    layers[layers.length - 1].rse = 0.04;
    layers[0].rsi = 0.13;
    // format bridges data
    // Tarik uses his predefined bridge values. Bridge is sent to server to avoid error but not used
    const bridges = [];
    this.bridges.forEach((bridge: Bridge) => {
      bridges.push({
        material: bridge.material,
        kvalue: bridge.kvalue,
        xstart: mmToM(bridge.base.x - this.left),
        xend: mmToM(bridge.base.x + bridge.width - this.left),
        ystart: mmToM(this.jnHeight - bridge.base.y - bridge.height),
        yend: mmToM(this.jnHeight - bridge.base.y),
      });
    });
    // create input
    return {
      _id: this.calcId,
      reqid: getReqId(),
      jntype: "E02A",
      frame: "timber",
      rotate: 90,
      size: [600, 600],
      layers: layers.reverse(),
      bridges,
      intemp: 20,
      extemp: 0,
    };
  }
  getLayerHeight(lyrIndex: number): number {
    return this.jnHeight - E02ABridges[3].height;
  }
  getLayerWidth(lyrIndex: number): number {
    const layerData = this.jnData.layers[lyrIndex];
    const m: MaterialInfo = E02ALayerOptions[lyrIndex][layerData.matIndex];
    return m.thickness_options[layerData.thkIndex].val;
  }
  isLayerHidden(i: number): boolean {
    return (
      (i === 0 && !this.laminateLayerIsOn) || (i === 6 && !this.blockworkIsOn)
    );
  }
  public generateShapes(): void {
    this.generateLayerParts();
    this.generateBridges();
    this.generateExtras();
  }
  public generateLayerParts(): void {
    this.layerParts = [];
    let bp = this.left + this.totalThickness;
    this.jnData.layers.forEach((lyrDTO: LayerDTO, i: number) => {
      if (!this.isLayerHidden(i)) {
        const m: MaterialInfo = E02ALayerOptions[i][lyrDTO.matIndex];
        const thk = m.thickness_options[lyrDTO.thkIndex].val;
        bp = bp - thk;
        const h = this.getLayerHeight(i);
        const points = [
          { x: bp, y: 0 } as Point,
          { x: bp + thk, y: 0 } as Point,
          { x: bp + thk, y: h } as Point,
          { x: bp, y: h } as Point,
        ];
        this.layerParts.push(new PolyLayerPart(i, points, m.background));
      }
    });
  }
  private generateBridges(): void {
    this.bridges = [];
    // Bridge [1]
    const b1: BridgeDTO = E02ABridges[0];
    b1.width =
      (this.laminateLayerIsOn ? this.getLayerWidth(0) : 0) +
      this.getLayerWidth(1) +
      this.getLayerWidth(2) +
      this.getLayerWidth(3) +
      this.getLayerWidth(4) -
      30;
    b1.height = this.laminateLayerIsOn
      ? this.getLayerWidth(0)
      : this.getLayerWidth(1);
    b1.base.x =
      this.left +
      (this.blockworkIsOn ? this.getLayerWidth(6) : 0) +
      this.getLayerWidth(5) +
      30;
    b1.base.y = this.getLayerHeight(4);
    this.bridges.push(new Bridge(0, b1.background, b1));
    // Bridge [2]
    const b2: BridgeDTO = E02ABridges[1];
    b2.width = this.getLayerWidth(4);
    b2.base.x =
      this.left +
      (this.blockworkIsOn ? this.getLayerWidth(6) : 0) +
      this.getLayerWidth(5);
    b2.base.y = this.getLayerHeight(4) - b2.height;
    this.bridges.push(new Bridge(1, b2.background, b2));
    // Bridge [3]
    const b3: BridgeDTO = E02ABridges[2];
    b3.width = this.getLayerWidth(4) - this.pvcThickness * 2;
    b3.base.x =
      this.left +
      (this.blockworkIsOn ? this.getLayerWidth(6) : 0) +
      this.getLayerWidth(5) +
      this.pvcThickness;
    b3.base.y = this.getLayerHeight(4) - b3.height - this.pvcThickness;
    this.bridges.push(new Bridge(2, b3.background, b3));
    // Bridge [4]
    const b4: BridgeDTO = E02ABridges[3];
    b4.base.x =
      this.left +
      (this.blockworkIsOn ? this.getLayerWidth(6) : 0) +
      this.getLayerWidth(5) -
      40;
    b4.base.y = this.getLayerHeight(4);
    this.bridges.push(new Bridge(3, b4.background, b4));
    // Bridge [5]
    const b5: BridgeDTO = E02ABridges[4];
    b5.width = this.getLayerWidth(5);
    b5.base.x = this.left + (this.blockworkIsOn ? this.getLayerWidth(6) : 0);
    b5.base.y = this.getLayerHeight(4) - b5.height;
    this.bridges.push(new Bridge(4, b5.background, b5));
    // Bridge [6]
    const b6: BridgeDTO = E02ABridges[5];
    b6.width = this.getLayerWidth(3);
    b6.base.x =
      this.left +
      (this.blockworkIsOn ? this.getLayerWidth(6) : 0) +
      this.getLayerWidth(5) +
      this.getLayerWidth(4);
    b6.base.y = this.getLayerHeight(4) - b6.height;
    this.bridges.push(new Bridge(5, b6.background, b6));
  }
  private generateExtras(): void {
    this.extras = [];
  }
  public generateLabels(): void {
    this.labels = [];
    let start = 0;
    // layer [0]
    let i = 0;
    let m = this.getMaterial(i);
    if (m) {
      this.labels[start + i] = {
        text: m,
        start: { x: this.calculateLeft(i), y: 15 } as Point,
        mid: { x: this.calculateLeft(i), y: 15 } as Point,
        end: { x: this.jnWidth, y: 15 } as Point,
      } as Label;
    }
    // layer [1]
    i = 1;
    m = this.getMaterial(i);
    if (m) {
      this.labels[start + i] = {
        text: m,
        start: { x: this.calculateLeft(i), y: 45 } as Point,
        mid: { x: this.calculateLeft(i), y: 45 } as Point,
        end: { x: this.jnWidth, y: 45 } as Point,
      } as Label;
    }
    // layer [2]
    i = 2;
    m = this.getMaterial(i);
    if (m) {
      this.labels[start + i] = {
        text: m,
        start: { x: this.calculateLeft(i), y: 85 } as Point,
        mid: { x: this.calculateLeft(i), y: 85 } as Point,
        end: { x: this.jnWidth, y: 85 } as Point,
      } as Label;
    }
    // layer [3]
    i = 3;
    m = this.getMaterial(i);
    if (m) {
      this.labels[start + i] = {
        text: m,
        start: { x: this.calculateLeft(i), y: 123 } as Point,
        mid: { x: this.calculateLeft(i), y: 123 } as Point,
        end: { x: this.jnWidth, y: 123 } as Point,
      } as Label;
    }
    // layer [4]
    i = 4;
    m = this.getMaterial(i);
    if (m) {
      this.labels[start + i] = {
        text: m,
        start: { x: this.calculateLeft(i), y: 165 } as Point,
        mid: { x: this.calculateLeft(i), y: 165 } as Point,
        end: { x: this.jnWidth, y: 165 } as Point,
      } as Label;
    }
    // layer [5]
    i = 5;
    m = this.getMaterial(i);
    if (m) {
      this.labels[start + i] = {
        text: m,
        start: { x: this.calculateLeft(i), y: 200 } as Point,
        mid: { x: this.calculateLeft(i), y: 200 } as Point,
        end: { x: this.jnWidth, y: 200 } as Point,
      } as Label;
    }
    // layer [6]
    i = 6;
    m = this.getMaterial(i);
    if (m) {
      this.labels[start + i] = {
        text: m,
        start: { x: this.calculateLeft(i), y: 240 } as Point,
        mid: { x: this.calculateLeft(i), y: 240 } as Point,
        end: { x: this.jnWidth, y: 240 } as Point,
      } as Label;
    }

    // Fixed label
    const nx1 = this.bridges[2].base.x + this.bridges[2].width / 2;
    this.labels[10] = {
      text: "45mm insulation k=0.022",
      start: { x: nx1, y: 405 } as Point,
      mid: { x: nx1, y: 405 } as Point,
      end: { x: this.jnWidth, y: 405 } as Point,
    } as Label;
    // Fixed label
    const nx2 = this.bridges[1].base.x + this.bridges[1].width;
    this.labels[11] = {
      text: "2mm PVC k=0.2",
      start: { x: nx2, y: 430 } as Point,
      mid: { x: nx2, y: 430 } as Point,
      end: { x: this.jnWidth, y: 430 } as Point,
    } as Label;
    // Fixed label
    const nx3 = this.bridges[3].base.x + this.bridges[3].width;
    this.labels[12] = {
      text: "Adiabatic frame",
      start: { x: nx3, y: 470 } as Point,
      mid: { x: nx3, y: 470 } as Point,
      end: { x: this.jnWidth, y: 470 } as Point,
    } as Label;
  }
  private getMaterial(i: number): string {
    if (this.isLayerHidden(i)) return null;
    const layerDTO: LayerDTO = this.jnData.layers[i];
    const m: MaterialInfo = E02ALayerOptions[i][layerDTO.matIndex];
    return m.material;
  }
  private getThickness(i: number): number {
    if (this.isLayerHidden(i)) return 0;
    const layerDTO: LayerDTO = this.jnData.layers[i];
    const m: MaterialInfo = E02ALayerOptions[i][layerDTO.matIndex];
    return m.thickness_options[layerDTO.thkIndex].val;
  }
  private calculateLeft(i: number): number {
    let w = this.left;
    for (let j = 6; j > i; j--) {
      w += this.getThickness(j);
    }
    return w + this.getThickness(i) / 2;
  }
}
