<div id="content">
  <div class="container">
    <div class="row">
      <div class="main-box black">
        <div class="form-container">
          <h2>USER REGISTRATION</h2>

          <form [formGroup]="registerForm" (ngSubmit)="registerUser()">
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <mat-form-field appearance="fill" autofilled="true">
                    <mat-label>First Name</mat-label>
                    <input type="text" matInput formControlName="firstName" required>
                    <mat-error *ngIf="registerFormControls.firstName.hasError('maxlength')">
                      First name must be max 100 characters long!
                    </mat-error>
                    <mat-error *ngIf="registerFormControls.firstName.hasError('required')">
                      First name field is <strong>required</strong>
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <mat-form-field appearance="fill" autofilled="true">
                    <mat-label>Last name</mat-label>
                    <input type="text" matInput formControlName="lastName" required>
                    <mat-error *ngIf="registerFormControls.lastName.hasError('maxlength')">
                      Last name must be max 100 characters long!
                    </mat-error>
                    <mat-error *ngIf="registerFormControls.lastName.hasError('required')">
                      Last name field is <strong>required</strong>
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <div class="form-group">
                  <mat-form-field appearance="fill" autofilled="true">
                    <mat-label>Email</mat-label>
                    <input type="email" matInput formControlName="email" placeholder="Ex. pat@example.com" required>
                    <mat-error
                      *ngIf="registerFormControls.email.hasError('email') && !registerFormControls.email.hasError('required')">
                      Please enter a valid email address
                    </mat-error>
                    <mat-error *ngIf="registerFormControls.email.hasError('required')">
                      Email is <strong>required</strong>
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <mat-form-field appearance="fill" autofilled="true">
                    <mat-label>Password</mat-label>
                    <input type="password" matInput formControlName="password" required>
                    <mat-error *ngIf="registerFormControls.password.hasError('pattern')">
                      Your password must contain at least one lower case character, uppercase character and a
                      number!
                    </mat-error>
                    <mat-error *ngIf="registerFormControls.password.hasError('minlength')">
                      Your password must be at least 8 characters long!
                    </mat-error>
                    <mat-error *ngIf="registerFormControls.password.hasError('maxlength')">
                      Your password must be max 25 characters long!
                    </mat-error>
                    <mat-error *ngIf="registerFormControls.password.hasError('required')">
                      Password is <strong>required</strong>
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <mat-form-field appearance="fill" autofilled="true">
                    <mat-label>Confirm Password</mat-label>
                    <input type="password" matInput formControlName="confirmPassword" required>
                    <mat-error *ngIf="registerFormControls.confirmPassword.hasError('pattern')">
                      Your password must contain at least one lower case character, uppercase character and a
                      number!
                    </mat-error>
                    <mat-error *ngIf="registerFormControls.confirmPassword.hasError('minlength')">
                      Your password must be at least 8 characters long!
                    </mat-error>
                    <mat-error *ngIf="registerFormControls.confirmPassword.hasError('maxlength')">
                      Your password must be max 25 characters long!
                    </mat-error>
                    <mat-error *ngIf="registerFormControls.confirmPassword.hasError('required')">
                      Password is <strong>required</strong>
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>
            </div>
            <div class="row justify-content-center">
              <div class="col-md-6">
                <p class="form-text">Do you have an account? <br> <a routerLink="/login">Please click here to
                    log in</a>
                </p>
              </div>
              <div class="col-md-8">
                <p *ngIf="errorMessage" class="error-message">{{ errorMessage }}</p>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <button class="btn btn-main full" [disabled]="registerForm.invalid" type="submit">Register<i
                    class="fa fa-arrow-right al-right" aria-hidden="true"></i></button>
              </div>
              <div class="col-md-6">
                <button type="reset" class="btn btn-main orange full">Reset<i class="fa fa-times al-right"
                    aria-hidden="true"></i></button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>