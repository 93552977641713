import { deepCopy } from "src/app/helper/app.util";
import { Point } from "src/app/interfaces/point.interface";
import { globals } from "src/app/store/global.store";
import { BridgeDTO } from "./bridge.dto";

export class Bridge {
  constructor(
    public index: number,
    public background: number,
    private dto: BridgeDTO
  ) {}

  get id(): string {
    return `bdg-${this.index}`;
  }
  get className(): string {
    return `cls-${this.id}`;
  }
  get patternName(): string {
    return `pat-${this.id}`;
  }
  get selectedClass(): string {
    return this.isSelected ? "selected-element-bridge" : "";
  }
  get isSelected(): boolean {
    return globals.selectedBridge === this.id;
  }
  get base(): Point {
    return this.dto.base;
  }
  set base(pt: Point) {
    this.dto.base = pt;
  }
  get width(): number {
    return this.dto.width;
  }
  set width(n: number) {
    this.dto.width = n;
  }
  get height(): number {
    return this.dto.height;
  }
  set height(n: number) {
    this.dto.height = n;
  }
  get material(): string {
    return this.dto.material;
  }
  set material(s: string) {
    this.dto.material = s;
  }
  get kvalue(): number {
    return this.dto.kvalue;
  }
  set kvalue(k: number) {
    this.dto.kvalue = k;
  }
  get points(): Point[] {
    return [
      { x: this.base.x, y: this.base.y } as Point,
      { x: this.base.x + this.width, y: this.base.y } as Point,
      { x: this.base.x + this.width, y: this.base.y + this.height } as Point,
      { x: this.base.x, y: this.base.y + this.height } as Point,
    ];
  }
  get dto_copy(): BridgeDTO {
    return deepCopy(this.dto);
  }
}
