import { Point } from "src/app/interfaces/point.interface";

export abstract class LayerPart {
  abstract get layerIndex(): number;
  abstract get orient(): string;
  abstract get isSelected(): boolean;
  abstract get points(): Point[];

  get id(): string {
    return `lyr-${this.orient}-${this.layerIndex}`;
  }
  get className(): string {
    return `cls-${this.id}`;
  }
  get patternName(): string {
    return `pat-${this.id}`;
  }
  get selectedClass(): string {
    return this.isSelected ? "selected-element-layer" : "";
  }
}
