export const globals = {
  selectedLayer: { index: undefined, orient: undefined },
  selectedBridge: undefined,
  selectedExtra: undefined,
  selectedFolder: undefined,
};
export const savedTmData = {};
export const initGlobalData = () => {
  // [1] clear the thermal model data
  for (let key in savedTmData) delete savedTmData[key];
  console.log(`Cleaning local TM data.[${Object.keys(savedTmData).length}]`);
  // [2] cleanup the globals
  globals.selectedLayer.index = undefined;
  globals.selectedLayer.orient = undefined;
  globals.selectedBridge = undefined;
  globals.selectedExtra = undefined;
};
