import { createNewCanvasId } from "src/app/helper/app.util";
import { Label } from "src/app/interfaces/label.interface";
import { JunctionService } from "src/app/services/junction.service";
import { Bridge } from "./bridge.model";
import { Extra } from "./extra.model";
import { Ground } from "./ground.model";
import { JnDTO } from "./jn.dto";
import { PolyLayerPart } from "./poly.layer.part.model";

export abstract class Junction {
  labels: Label[] = [];
  showLabels = false;

  public isJnSaved: boolean;
  public vw: number; // View Size
  public tab = "junction";
  public canvasId = createNewCanvasId();
  public layerParts: PolyLayerPart[];
  public bridges: Bridge[];
  public extras: Extra[];
  public grounds: Ground[];

  abstract get id(): string;
  abstract get name(): string;
  abstract set name(s: string);
  abstract get jntype(): string;
  abstract get jnWidth(): number;
  abstract get jnHeight(): number;
  abstract get tmWidthFactor(): number;
  abstract get tmHeightFactor(): number;
  abstract get calcId(): string;
  abstract get calcInput(): any;
  abstract get jnDto(): JnDTO;
  abstract get owner(): string;

  abstract generateShapes(): void;
  abstract generateLabels(): void;

  get viewBox(): string {
    return `0 0 ${this.jnWidth} ${this.jnHeight}`;
  }
  get pdfFileName(): string {
    return this.name.replace(/[^A-Z0-9]+/gi, "_") + ".pdf";
  }
  public saveJnToAnalytics = (jnService: JunctionService) => jnService.saveJnToAnalytics(this);
}
