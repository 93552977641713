<div class="report-header">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-6">
        <img class="knauf-brand" src="/assets/images/knaufinsulation.png" alt="KNAUF INSULATION" />
      </div>
      <div class="col-md-6">
        <div class="link-wrp">
          <a class="knauf-link" href="https://knaufinsulation.co.uk" target="_blank"
            rel="noopener noreferrer"><span>knauf</span>insulation.co.uk</a>
        </div>
      </div>
    </div>
  </div>
</div>