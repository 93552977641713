import { Component, Input } from "@angular/core";
import { E13Junction } from "src/app/junctions/e13/e13.junction.model";
import {
  E13FloorLayerOptions,
  E13LayerOptions,
} from "src/app/config/e13.options";
import { globals } from "src/app/store/global.store";
import { tmCanvas } from "src/app/helper/tm.cnv.util";
import { CalcService } from "src/app/services/calc.service";
import { LayerOrient } from "src/app/config/app.const";
import { LayerDTO } from "src/app/junctions/common/layer.dto";
import { JunctionService } from "src/app/services/junction.service";

@Component({
  selector: "app-e13-editor",
  templateUrl: "./e13-editor.component.html",
  styleUrls: ["./e13-editor.component.css"],
})
export class E13EditorComponent {
  options = E13LayerOptions;
  @Input() e13: E13Junction;

  constructor(
    private calcService: CalcService,
    private jnService: JunctionService
  ) {
    globals.selectedLayer.index = undefined;
    globals.selectedLayer.orient = undefined;
  }
  get layers(): LayerDTO[] {
    return this.e13.jnData.layers;
  }
  get flrLayers(): LayerDTO[] {
    return this.e13.jnData.flrLayers;
  }
  selectedClass(i: number): string {
    return globals.selectedLayer.index === i &&
      globals.selectedLayer.orient === LayerOrient.Y
      ? "selected-row"
      : "";
  }
  selectedFlrClass(i: number): string {
    return globals.selectedLayer.index === i &&
      globals.selectedLayer.orient === LayerOrient.X
      ? "selected-row"
      : "";
  }
  displayIndex(i: number): number {
    return this.e13.laminateLayerIsOn ? i + 1 : i;
  }
  displayFlrIndex(i: number): number {
    return i + 1;
  }
  layerClicked($event: any, index: number): void {
    globals.selectedLayer.index = index;
    globals.selectedLayer.orient = LayerOrient.Y;
    globals.selectedBridge = undefined;
    $event.stopPropagation();
  }
  flrLayerClicked($event: any, index: number): void {
    globals.selectedLayer.index = index;
    globals.selectedLayer.orient = LayerOrient.X;
    globals.selectedBridge = undefined;
    $event.stopPropagation();
  }
  // Layer 1
  get lyr1MatIndex(): number {
    return this.layers[0].matIndex;
  }
  set lyr1MatIndex(i: number) {
    this.layers[0].matIndex = i;
  }
  get lyr1MatOpts(): any[] {
    return E13LayerOptions[0];
  }
  get lyr1ThkIndex(): number {
    return this.layers[0].thkIndex;
  }
  set lyr1ThkIndex(i: number) {
    this.layers[0].thkIndex = i;
  }
  get lyr1ThkOpts(): any[] {
    return E13LayerOptions[0][this.lyr1MatIndex].thickness_options;
  }
  get lyr1KvlIndex(): number {
    return this.layers[0].kvlIndex;
  }
  set lyr1KvlIndex(i: number) {
    this.layers[0].kvlIndex = i;
  }
  get lyr1KvlOpts(): any[] {
    return E13LayerOptions[0][this.lyr1MatIndex].kvalue_options;
  }
  lyr1MatChanged(): void {
    this.lyr1KvlIndex = 0;
    this.lyr1KvalChanged();
  }
  lyr1KvalChanged(): void {
    this.lyr1ThkIndex = 0;
    this.lyr1ThkChanged();
  }
  lyr1ThkChanged(): void {
    this.handleChange();
  }
  // Layer 2
  get lyr2MatIndex(): number {
    return this.layers[1].matIndex;
  }
  set lyr2MatIndex(i: number) {
    this.layers[1].matIndex = i;
  }
  get lyr2MatOpts(): any[] {
    return E13LayerOptions[1];
  }
  get lyr2ThkIndex(): number {
    return this.layers[1].thkIndex;
  }
  set lyr2ThkIndex(i: number) {
    this.layers[1].thkIndex = i;
  }
  get lyr2ThkOpts(): any[] {
    return E13LayerOptions[1][this.lyr2MatIndex].thickness_options;
  }
  get lyr2KvlIndex(): number {
    return this.layers[1].kvlIndex;
  }
  set lyr2KvlIndex(i: number) {
    this.layers[1].kvlIndex = i;
  }
  get lyr2KvlOpts(): any[] {
    return E13LayerOptions[1][this.lyr2MatIndex].kvalue_options;
  }
  lyr2MatChanged(): void {
    this.lyr2KvlIndex = 0;
    this.lyr2KvalChanged();
  }
  lyr2KvalChanged(): void {
    this.lyr2ThkIndex = 0;
    this.lyr2ThkChanged();
  }
  lyr2ThkChanged(): void {
    this.handleChange();
  }
  // Layer 3
  get lyr3MatIndex(): number {
    return this.layers[2].matIndex;
  }
  set lyr3MatIndex(i: number) {
    this.layers[2].matIndex = i;
  }
  get lyr3MatOpts(): any[] {
    return E13LayerOptions[2];
  }
  get lyr3ThkIndex(): number {
    return this.layers[2].thkIndex;
  }
  set lyr3ThkIndex(i: number) {
    this.layers[2].thkIndex = i;
  }
  get lyr3ThkOpts(): any[] {
    return E13LayerOptions[2][this.lyr3MatIndex].thickness_options;
  }
  get lyr3KvlIndex(): number {
    return this.layers[2].kvlIndex;
  }
  set lyr3KvlIndex(i: number) {
    this.layers[2].kvlIndex = i;
  }
  get lyr3KvlOpts(): any[] {
    return E13LayerOptions[2][this.lyr3MatIndex].kvalue_options;
  }
  lyr3MatChanged(): void {
    this.lyr3KvlIndex = 0;
    this.lyr3KvalChanged();
  }
  lyr3KvalChanged(): void {
    this.lyr3ThkIndex = 0;
    this.lyr3ThkChanged();
  }
  lyr3ThkChanged(): void {
    this.handleChange();
  }
  // Layer 4
  get lyr4MatIndex(): number {
    return this.layers[3].matIndex;
  }
  set lyr4MatIndex(i: number) {
    this.layers[3].matIndex = i;
  }
  get lyr4MatOpts(): any[] {
    return E13LayerOptions[3];
  }
  get lyr4ThkIndex(): number {
    return this.layers[3].thkIndex;
  }
  set lyr4ThkIndex(i: number) {
    this.layers[3].thkIndex = i;
  }
  get lyr4ThkOpts(): any[] {
    return E13LayerOptions[3][this.lyr4MatIndex].thickness_options;
  }
  get lyr4KvlIndex(): number {
    return this.layers[3].kvlIndex;
  }
  set lyr4KvlIndex(i: number) {
    this.layers[3].kvlIndex = i;
  }
  get lyr4KvlOpts(): any[] {
    return E13LayerOptions[3][this.lyr4MatIndex].kvalue_options;
  }
  lyr4MatChanged(): void {
    this.lyr4KvlIndex = 0;
    this.lyr4KvalChanged();
  }
  lyr4KvalChanged(): void {
    this.lyr4ThkIndex = 0;
    this.lyr4ThkChanged();
  }
  lyr4ThkChanged(): void {
    this.handleChange();
  }
  // Layer 5
  get lyr5MatIndex(): number {
    return this.layers[4].matIndex;
  }
  set lyr5MatIndex(i: number) {
    this.layers[4].matIndex = i;
  }
  get lyr5MatOpts(): any[] {
    return E13LayerOptions[4];
  }
  get lyr5ThkIndex(): number {
    return this.layers[4].thkIndex;
  }
  set lyr5ThkIndex(i: number) {
    this.layers[4].thkIndex = i;
  }
  get lyr5ThkOpts(): any[] {
    return E13LayerOptions[4][this.lyr5MatIndex].thickness_options;
  }
  get lyr5KvlIndex(): number {
    return this.layers[4].kvlIndex;
  }
  set lyr5KvlIndex(i: number) {
    this.layers[4].kvlIndex = i;
  }
  get lyr5KvlOpts(): any[] {
    return E13LayerOptions[4][this.lyr5MatIndex].kvalue_options;
  }
  lyr5MatChanged(): void {
    this.lyr5KvlIndex = 0;
    this.lyr5KvalChanged();
  }
  lyr5KvalChanged(): void {
    this.lyr5ThkIndex = 0;
    this.lyr5ThkChanged();
  }
  lyr5ThkChanged(): void {
    this.handleChange();
  }
  // Layer 6
  get lyr6MatIndex(): number {
    return this.layers[5].matIndex;
  }
  set lyr6MatIndex(i: number) {
    this.layers[5].matIndex = i;
  }
  get lyr6MatOpts(): any[] {
    return E13LayerOptions[5];
  }
  get lyr6ThkIndex(): number {
    return this.layers[5].thkIndex;
  }
  set lyr6ThkIndex(i: number) {
    this.layers[5].thkIndex = i;
  }
  get lyr6ThkOpts(): any[] {
    return E13LayerOptions[5][this.lyr6MatIndex].thickness_options;
  }
  get lyr6KvlIndex(): number {
    return this.layers[5].kvlIndex;
  }
  set lyr6KvlIndex(i: number) {
    this.layers[5].kvlIndex = i;
  }
  get lyr6KvlOpts(): any[] {
    return E13LayerOptions[5][this.lyr6MatIndex].kvalue_options;
  }
  lyr6MatChanged(): void {
    this.lyr6KvlIndex = 0;
    this.lyr6KvalChanged();
  }
  lyr6KvalChanged(): void {
    this.lyr6ThkIndex = 0;
    this.lyr6ThkChanged();
  }
  lyr6ThkChanged(): void {
    this.handleChange();
  }
  // Layer 7
  get lyr7MatIndex(): number {
    return this.layers[6].matIndex;
  }
  set lyr7MatIndex(i: number) {
    this.layers[6].matIndex = i;
  }
  get lyr7MatOpts(): any[] {
    return E13LayerOptions[6];
  }
  get lyr7ThkIndex(): number {
    return this.layers[6].thkIndex;
  }
  set lyr7ThkIndex(i: number) {
    this.layers[6].thkIndex = i;
  }
  get lyr7ThkOpts(): any[] {
    return E13LayerOptions[6][this.lyr7MatIndex].thickness_options;
  }
  get lyr7KvlIndex(): number {
    return this.layers[6].kvlIndex;
  }
  set lyr7KvlIndex(i: number) {
    this.layers[6].kvlIndex = i;
  }
  get lyr7KvlOpts(): any[] {
    return E13LayerOptions[6][this.lyr7MatIndex].kvalue_options;
  }
  lyr7MatChanged(): void {
    this.lyr7KvlIndex = 0;
    this.lyr7KvalChanged();
  }
  lyr7KvalChanged(): void {
    this.lyr7ThkIndex = 0;
    this.lyr7ThkChanged();
  }
  lyr7ThkChanged(): void {
    this.handleChange();
  }

  // Floor Layer 1
  get flrLyr1MatIndex(): number {
    return this.flrLayers[0].matIndex;
  }
  set flrLyr1MatIndex(i: number) {
    this.flrLayers[0].matIndex = i;
  }
  get flrLyr1MatOpts(): any[] {
    return E13FloorLayerOptions[0];
  }
  get flrLyr1ThkIndex(): number {
    return this.flrLayers[0].thkIndex;
  }
  set flrLyr1ThkIndex(i: number) {
    this.flrLayers[0].thkIndex = i;
  }
  get flrLyr1ThkOpts(): any[] {
    return E13FloorLayerOptions[0][this.flrLyr1MatIndex].thickness_options;
  }
  get flrLyr1KvlIndex(): number {
    return this.flrLayers[0].kvlIndex;
  }
  set flrLyr1KvlIndex(i: number) {
    this.flrLayers[0].kvlIndex = i;
  }
  get flrLyr1KvlOpts(): any[] {
    return E13FloorLayerOptions[0][this.flrLyr1MatIndex].kvalue_options;
  }
  flrLyr1MatChanged(): void {
    this.flrLyr1KvlIndex = 0;
    this.flrLyr1KvalChanged();
  }
  flrLyr1KvalChanged(): void {
    this.flrLyr1ThkIndex = 0;
    this.flrLyr1ThkChanged();
  }
  flrLyr1ThkChanged(): void {
    this.handleChange();
  }
  // Floor Layer 2
  get flrLyr2MatIndex(): number {
    return this.flrLayers[1].matIndex;
  }
  set flrLyr2MatIndex(i: number) {
    this.flrLayers[1].matIndex = i;
  }
  get flrLyr2MatOpts(): any[] {
    return E13FloorLayerOptions[1];
  }
  get flrLyr2ThkIndex(): number {
    return this.flrLayers[1].thkIndex;
  }
  set flrLyr2ThkIndex(i: number) {
    this.flrLayers[1].thkIndex = i;
  }
  get flrLyr2ThkOpts(): any[] {
    return E13FloorLayerOptions[1][this.flrLyr2MatIndex].thickness_options;
  }
  get flrLyr2KvlIndex(): number {
    return this.flrLayers[1].kvlIndex;
  }
  set flrLyr2KvlIndex(i: number) {
    this.flrLayers[1].kvlIndex = i;
  }
  get flrLyr2KvlOpts(): any[] {
    return E13FloorLayerOptions[1][this.flrLyr2MatIndex].kvalue_options;
  }
  flrLyr2MatChanged(): void {
    this.flrLyr2KvlIndex = 0;
    this.flrLyr2KvalChanged();
  }
  flrLyr2KvalChanged(): void {
    this.flrLyr2ThkIndex = 0;
    this.flrLyr2ThkChanged();
  }
  flrLyr2ThkChanged(): void {
    this.handleChange();
  }
  // Floor Layer 3
  get flrLyr3MatIndex(): number {
    return this.flrLayers[2].matIndex;
  }
  set flrLyr3MatIndex(i: number) {
    this.flrLayers[2].matIndex = i;
  }
  get flrLyr3MatOpts(): any[] {
    return E13FloorLayerOptions[2];
  }
  get flrLyr3ThkIndex(): number {
    return this.flrLayers[2].thkIndex;
  }
  set flrLyr3ThkIndex(i: number) {
    this.flrLayers[2].thkIndex = i;
  }
  get flrLyr3ThkOpts(): any[] {
    return E13FloorLayerOptions[2][this.flrLyr3MatIndex].thickness_options;
  }
  get flrLyr3KvlIndex(): number {
    return this.flrLayers[2].kvlIndex;
  }
  set flrLyr3KvlIndex(i: number) {
    this.flrLayers[2].kvlIndex = i;
  }
  get flrLyr3KvlOpts(): any[] {
    return E13FloorLayerOptions[2][this.flrLyr3MatIndex].kvalue_options;
  }
  flrLyr3MatChanged(): void {
    this.flrLyr3KvlIndex = 0;
    this.flrLyr3KvalChanged();
  }
  flrLyr3KvalChanged(): void {
    this.flrLyr3ThkIndex = 0;
    this.flrLyr3ThkChanged();
  }
  flrLyr3ThkChanged(): void {
    this.handleChange();
  }
  // Floor Layer 4
  get flrLyr4MatIndex(): number {
    return this.flrLayers[3].matIndex;
  }
  set flrLyr4MatIndex(i: number) {
    this.flrLayers[3].matIndex = i;
  }
  get flrLyr4MatOpts(): any[] {
    return E13FloorLayerOptions[3];
  }
  get flrLyr4ThkIndex(): number {
    return this.flrLayers[3].thkIndex;
  }
  set flrLyr4ThkIndex(i: number) {
    this.flrLayers[3].thkIndex = i;
  }
  get flrLyr4ThkOpts(): any[] {
    return E13FloorLayerOptions[3][this.flrLyr4MatIndex].thickness_options;
  }
  get flrLyr4KvlIndex(): number {
    return this.flrLayers[3].kvlIndex;
  }
  set flrLyr4KvlIndex(i: number) {
    this.flrLayers[3].kvlIndex = i;
  }
  get flrLyr4KvlOpts(): any[] {
    return E13FloorLayerOptions[3][this.flrLyr4MatIndex].kvalue_options;
  }
  flrLyr4MatChanged(): void {
    this.flrLyr4KvlIndex = 0;
    this.flrLyr4KvalChanged();
  }
  flrLyr4KvalChanged(): void {
    this.flrLyr4ThkIndex = 0;
    this.flrLyr4ThkChanged();
  }
  flrLyr4ThkChanged(): void {
    this.handleChange();
  }
  // Floor Layer 5
  get flrLyr5MatIndex(): number {
    return this.flrLayers[4].matIndex;
  }
  set flrLyr5MatIndex(i: number) {
    this.flrLayers[4].matIndex = i;
  }
  get flrLyr5MatOpts(): any[] {
    return E13FloorLayerOptions[4];
  }
  get flrLyr5ThkIndex(): number {
    return this.flrLayers[4].thkIndex;
  }
  set flrLyr5ThkIndex(i: number) {
    this.flrLayers[4].thkIndex = i;
  }
  get flrLyr5ThkOpts(): any[] {
    return E13FloorLayerOptions[4][this.flrLyr5MatIndex].thickness_options;
  }
  get flrLyr5KvlIndex(): number {
    return this.flrLayers[4].kvlIndex;
  }
  set flrLyr5KvlIndex(i: number) {
    this.flrLayers[4].kvlIndex = i;
  }
  get flrLyr5KvlOpts(): any[] {
    return E13FloorLayerOptions[4][this.flrLyr5MatIndex].kvalue_options;
  }
  flrLyr5MatChanged(): void {
    this.flrLyr5KvlIndex = 0;
    this.flrLyr5KvalChanged();
  }
  flrLyr5KvalChanged(): void {
    this.flrLyr5ThkIndex = 0;
    this.flrLyr5ThkChanged();
  }
  flrLyr5ThkChanged(): void {
    this.handleChange();
  }

  handleChange(): void {
    this.e13.generateShapes();
    this.drawCanvas();
    this.e13.saveJnToAnalytics(this.jnService).subscribe({
      next: (res) => console.log(`Success Logging Jn to Analytics: ${res}`),
      error: (err) =>
        console.error(`Error Logging Jn to Analytics: ${err.message ?? err}`),
    });
  }

  async drawCanvas(): Promise<void> {
    tmCanvas.clearTmData();
    const p1 = this.calcService.fetchCalcData(this.e13.calcId);
    const p2 = tmCanvas.drawTMCanvasPlaceHolder(this.e13);
    Promise.all([p1, p2]).then(async (values) => {
      const calcData = values[0]; // return of p1
      if (calcData) {
        tmCanvas.setTmData(calcData.tMatrix);
        await tmCanvas.drawTMCanvas(this.e13);
      }
    });
  }
}
