<div class="container">
  <mat-tab-group class="example-stretched-tabs mat-elevation-z2" dynamicHeight mat-align-tabs="center">
    <mat-tab [disabled]="tabsLoadingMap[0]">
      <ng-template mat-tab-label>
        <div class="mat-tab-label-content">Current Month Analytics</div>
        <svg *ngIf="tabsLoadingMap[0]" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
          class="bi bi-arrow-clockwise" viewBox="0 0 16 16">
          <path fill-rule="evenodd" d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z" />
          <path
            d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z" />
        </svg>
      </ng-template>

      <table *ngIf="currentMonthAnalytics && !tabsLoadingMap[0]">
        <tr class="header-row">
          <th rowspan="2">Date</th>
          <th rowspan="2">Total</th>
          <th rowspan="2">Logged</th>
          <th rowspan="2">Non-Logged</th>
          <th colspan="5">Walls</th>
          <th colspan="3">Roofs</th>
          <th colspan="3">Floors</th>
          <th colspan="5">Windows</th>
        </tr>
        <tr>
          <th>Sub Total</th>
          <th>E01</th>
          <th>E02A</th>
          <th>E16</th>
          <th>E17</th>

          <th>Sub Total</th>
          <th>E06</th>
          <th>E10</th>
          <th>E11</th>
          <th>E12</th>
          <th>E13</th>

          <th>Sub Total</th>
          <th>E05A</th>
          <th>E05B</th>

          <th>Sub Total</th>
          <th>E01</th>
          <th>E02A</th>
          <th>E03</th>
          <th>E04</th>
        </tr>
        <tr>
          <td>{{currentMonthAnalytics.date}}</td>
          <td>{{currentMonthAnalytics.total}}</td>
          <td>{{currentMonthAnalytics.logged}}</td>
          <td>{{currentMonthAnalytics.nonLogged}}</td>

          <td style="font-weight: 600;">{{currentMonthAnalytics.byType.walls.subTotal}}</td>
          <td>{{currentMonthAnalytics.byType.walls.e1}}</td>
          <td>{{currentMonthAnalytics.byType.walls.e2A}}</td>
          <td>{{currentMonthAnalytics.byType.walls.e16}}</td>
          <td>{{currentMonthAnalytics.byType.walls.e17}}</td>

          <td style="font-weight: 600;">{{currentMonthAnalytics.byType.ceiling.subTotal}}</td>
          <td>{{currentMonthAnalytics.byType.ceiling.e06}}</td>
          <td>{{currentMonthAnalytics.byType.ceiling.e10}}</td>
          <td>{{currentMonthAnalytics.byType.ceiling.e11}}</td>
          <td>{{currentMonthAnalytics.byType.ceiling.e12}}</td>
          <td>{{currentMonthAnalytics.byType.ceiling.e13}}</td>

          <td style="font-weight: 600;">{{currentMonthAnalytics.byType.floor.subTotal}}</td>
          <td>{{currentMonthAnalytics.byType.floor.e5A}}</td>
          <td>{{currentMonthAnalytics.byType.floor.e5B}}</td>

          <td style="font-weight: 600;">{{currentMonthAnalytics.byType.windows.subTotal}}</td>
          <td>{{currentMonthAnalytics.byType.windows.e1}}</td>
          <td>{{currentMonthAnalytics.byType.windows.e2A}}</td>
          <td>{{currentMonthAnalytics.byType.windows.e3}}</td>
          <td>{{currentMonthAnalytics.byType.windows.e4}}</td>
        </tr>
      </table>
    </mat-tab>
    <mat-tab [disabled]="tabsLoadingMap[1]">
      <ng-template mat-tab-label>
        <div class="mat-tab-label-content">12 Months Analytics</div>
        <svg *ngIf="tabsLoadingMap[1]" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
          class="bi bi-arrow-clockwise" viewBox="0 0 16 16">
          <path fill-rule="evenodd" d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z" />
          <path
            d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z" />
        </svg>
      </ng-template>

      <table *ngIf="historicAnalytics.length > 0">
        <tr class="header-row">
          <th rowspan="2">Month</th>
          <th rowspan="2">Total</th>
          <th rowspan="2">Logged</th>
          <th rowspan="2">Non-Logged</th>
          <th colspan="5">Walls</th>
          <th colspan="3">Roofs</th>
          <th colspan="3">Floors</th>
          <th colspan="5">Windows</th>
        </tr>
        <tr>
          <th>Sub Total</th>
          <th>E01</th>
          <th>E02A</th>
          <th>E16</th>
          <th>E17</th>

          <th>Sub Total</th>
          <th>E06</th>
          <th>E10</th>
          <th>E11</th>
          <th>E12</th>
          <th>E13</th>

          <th>Sub Total</th>
          <th>E05A</th>
          <th>E05B</th>

          <th>Sub Total</th>
          <th>E01</th>
          <th>E02A</th>
          <th>E03</th>
          <th>E04</th>
        </tr>
        <tr *ngFor="let month of historicAnalytics">
          <td>{{month.date}}</td>
          <td>{{month.total}}</td>
          <td>{{month.logged}}</td>
          <td>{{month.nonLogged}}</td>

          <td style="font-weight: 600;">{{month.byType.walls.subTotal}}</td>
          <td>{{month.byType.walls.e1}}</td>
          <td>{{month.byType.walls.e2A}}</td>
          <td>{{month.byType.walls.e1}}</td>
          <td>{{month.byType.walls.e17}}</td>

          <td style="font-weight: 600;">{{month.byType.ceiling.subTotal}}</td>
          <td>{{month.byType.ceiling.e06}}</td>
          <td>{{month.byType.ceiling.e10}}</td>
          <td>{{month.byType.ceiling.e11}}</td>
          <td>{{month.byType.ceiling.e12}}</td>
          <td>{{month.byType.ceiling.e13}}</td>

          <td style="font-weight: 600;">{{month.byType.floor.subTotal}}</td>
          <td>{{month.byType.floor.e5A}}</td>
          <td>{{month.byType.floor.e5B}}</td>

          <td style="font-weight: 600;">{{month.byType.windows.subTotal}}</td>
          <td>{{month.byType.windows.e1}}</td>
          <td>{{month.byType.windows.e2A}}</td>
          <td>{{month.byType.windows.e3}}</td>
          <td>{{month.byType.windows.e4}}</td>
        </tr>
      </table>
    </mat-tab>
  </mat-tab-group>
</div>