import { JnDTO } from "../common/jn.dto";
import { Junction } from "../common/base.junction.model";
import { E16DTO } from "./e16.dto";
import { Point } from "src/app/interfaces/point.interface";
import { PolyLayerPart } from "../common/poly.layer.part.model";
import { E16LayerOptions } from "src/app/config/e16.options";
import { getReqId, mmToM } from "src/app/helper/app.util";
import { Label } from "src/app/interfaces/label.interface";
import { LayerDTO } from "../common/layer.dto";
import { MaterialInfo } from "src/app/interfaces/mat.info.interface";

export class E16Junction extends Junction {
  spaceForLabel = 100;
  constructor(private dto: JnDTO) {
    super();
    this.generateShapes();
  }
  get jnWidth(): number {
    return 1200;
  }
  get jnHeight(): number {
    return 1200;
  }
  get tmWidthFactor(): number {
    return 1;
  }
  get tmHeightFactor(): number {
    return 1;
  }
  get id(): string {
    return this.dto._id;
  }
  get name(): string {
    return this.dto.name;
  }
  set name(s: string) {
    this.dto.name = s;
  }
  get jntype(): string {
    return this.dto.jntype;
  }
  get jnData(): E16DTO {
    return this.dto.jndata as E16DTO;
  }
  get jnDto(): JnDTO {
    return this.dto;
  }
  get owner(): string {
    return this.jnDto.owner;
  }
  get calcId(): string {
    let cid = "E16@";
    this.jnData.layers.forEach((lyr: LayerDTO, index: number) => {
      if (this.isLayerHidden(index)) {
        cid += "@_@_@_@";
      } else {
        const m: MaterialInfo = E16LayerOptions[index][lyr.matIndex];
        const code = m.code;
        const thk = m.thickness_options[lyr.thkIndex].val;
        const kval = m.kvalue_options[lyr.kvlIndex];
        cid += `@${code}@${thk}@${kval}@`;
      }
    });
    return cid + "@";
  }
  get laminateLayerIsOn(): boolean {
    return this.jnData.layers[1].matIndex !== 0; // 0 : Plasterboard
  }
  get blockworkIsOn(): boolean {
    return [0, 1, 2, 3, 4].includes(this.jnData.layers[5].matIndex); // Blockworks
  }
  get totalThickness(): number {
    return this.jnData.layers.reduce((x, lyrDTO, i) => {
      const m: MaterialInfo = E16LayerOptions[i][lyrDTO.matIndex];
      return this.isLayerHidden(i)
        ? x
        : x + m.thickness_options[lyrDTO.thkIndex].val;
    }, 0);
  }
  isLayerHidden(i: number): boolean {
    return (
      (i === 0 && !this.laminateLayerIsOn) || (i === 6 && !this.blockworkIsOn)
    );
  }
  public generateShapes(): void {
    this.generateLayerParts();
    // this.generateBridges();
    // this.generateExtras();
  }
  public generateLayerParts(): void {
    this.layerParts = [];
    const w = this.jnWidth - this.spaceForLabel;
    const h = this.jnHeight;
    let bp = this.totalThickness;
    this.jnData.layers.forEach((lyrDTO: LayerDTO, i: number) => {
      if (!this.isLayerHidden(i)) {
        const m: MaterialInfo = E16LayerOptions[i][lyrDTO.matIndex];
        const thk = m.thickness_options[lyrDTO.thkIndex].val;
        bp = bp - thk;
        const points = [
          { x: bp, y: bp } as Point,
          { x: w, y: bp } as Point,
          { x: w, y: bp + thk } as Point,
          { x: bp + thk, y: bp + thk } as Point,
          { x: bp + thk, y: h } as Point,
          { x: bp, y: h } as Point,
        ];
        this.layerParts.push(new PolyLayerPart(i, points, m.background));
      }
    });
  }
  // private generateBridges(): void {
  //   this.bridges = [];
  //   this.jnData.bridges.forEach((el: BridgeDTO, index: number) => {
  //     this.bridges.push(
  //       new Bridge(index, e16_materials[el.material].background, el)
  //     );
  //   });
  // }
  // private generateExtras(): void {
  //   this.extras = [];
  //   this.jnData.extras.forEach((extra: ExtraDTO, index: number) => {
  //     this.extras.push(new Extra(index, extra));
  //   });
  // }
  get calcInput(): any {
    const layers = [];
    this.jnData.layers.forEach((lyr: LayerDTO, index: number) => {
      if (!this.isLayerHidden(index)) {
        const m: MaterialInfo = E16LayerOptions[index][lyr.matIndex];
        const layer: any = {
          material: m.material,
          kvalue: m.kvalue_options[lyr.kvlIndex],
          thicknessx: mmToM(m.thickness_options[lyr.thkIndex].val),
          thicknessy: mmToM(m.thickness_options[lyr.thkIndex].val),
        };
        layers.push(layer);
      }
    });
    // add rse and rsi
    layers[layers.length - 1].rse = 0.04;
    layers[0].rsi = 0.13;
    // format bridges data
    // Tarik uses his predefined bridge values. Bridge is sent to server to avoid error but not used
    const bridges = [];
    // create input
    return {
      _id: this.calcId,
      reqid: getReqId(),
      jntype: "E16",
      frame: "timber",
      rotate: 90,
      size: [600, 600],
      layers: layers.reverse(),
      bridges,
      intemp: 20,
      extemp: 0,
    };
  }
  public generateLabels(): void {
    this.labels = [];
    // Fixed label
    this.labels[2] = {
      text: "Ensure continuity of insulation of the corner",
      start: { x: 200, y: 210 } as Point,
      mid: { x: 200, y: 210 } as Point,
      end: { x: this.jnWidth, y: 210 } as Point,
    } as Label;

    let start = 6;
    // layer [0]
    let i = 0;
    let m = this.getMaterial(i);
    if (m) {
      this.labels[start + i] = {
        text: m,
        start: { x: this.calculateLeft(i), y: 600 } as Point,
        mid: { x: this.calculateLeft(i), y: 600 } as Point,
        end: { x: this.jnWidth, y: 600 } as Point,
      } as Label;
    }
    // layer [1]
    i = 1;
    m = this.getMaterial(i);
    if (m) {
      this.labels[start + i] = {
        text: m,
        start: { x: this.calculateLeft(i), y: 700 } as Point,
        mid: { x: this.calculateLeft(i), y: 700 } as Point,
        end: { x: this.jnWidth, y: 700 } as Point,
      } as Label;
    }
    // layer [2]
    i = 2;
    m = this.getMaterial(i);
    if (m) {
      this.labels[start + i] = {
        text: m,
        start: { x: this.calculateLeft(i), y: 785 } as Point,
        mid: { x: this.calculateLeft(i), y: 785 } as Point,
        end: { x: this.jnWidth, y: 785 } as Point,
      } as Label;
    }
    // layer [3]
    i = 3;
    m = this.getMaterial(i);
    if (m) {
      this.labels[start + i] = {
        text: m,
        start: { x: this.calculateLeft(i), y: 885 } as Point,
        mid: { x: this.calculateLeft(i), y: 885 } as Point,
        end: { x: this.jnWidth, y: 885 } as Point,
      } as Label;
    }
    // layer [4]
    i = 4;
    m = this.getMaterial(i);
    if (m) {
      this.labels[start + i] = {
        text: m,
        start: { x: this.calculateLeft(i), y: 980 } as Point,
        mid: { x: this.calculateLeft(i), y: 980 } as Point,
        end: { x: this.jnWidth, y: 980 } as Point,
      } as Label;
    }
    // layer [5]
    i = 5;
    m = this.getMaterial(i);
    if (m) {
      this.labels[start + i] = {
        text: m,
        start: { x: this.calculateLeft(i), y: 1075 } as Point,
        mid: { x: this.calculateLeft(i), y: 1075 } as Point,
        end: { x: this.jnWidth, y: 1075 } as Point,
      } as Label;
    }
    // layer [6]
    i = 6;
    m = this.getMaterial(i);
    if (m) {
      this.labels[start + i] = {
        text: m,
        start: { x: this.calculateLeft(i), y: 1170 } as Point,
        mid: { x: this.calculateLeft(i), y: 1170 } as Point,
        end: { x: this.jnWidth, y: 1170 } as Point,
      } as Label;
    }
  }
  private getMaterial(i: number): string {
    if (this.isLayerHidden(i)) return null;
    const layerDTO: LayerDTO = this.jnData.layers[i];
    const m: MaterialInfo = E16LayerOptions[i][layerDTO.matIndex];
    return m.material;
  }
  private getThickness(i: number): number {
    if (this.isLayerHidden(i)) return 0;
    const layerDTO: LayerDTO = this.jnData.layers[i];
    const m: MaterialInfo = E16LayerOptions[i][layerDTO.matIndex];
    return m.thickness_options[layerDTO.thkIndex].val;
  }
  private calculateLeft(i: number): number {
    let w = 0;
    for (let j = 6; j > i; j--) {
      w += this.getThickness(j);
    }
    return w + this.getThickness(i) / 2;
  }
}
