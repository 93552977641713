import { LayerOrient } from "src/app/config/app.const";
import { Point } from "src/app/interfaces/point.interface";
import { globals } from "src/app/store/global.store";
import { LayerPart } from "./base.layer.part.model";

export class PolyLayerPart extends LayerPart {
  constructor(
    public readonly layerIndex: number,
    public readonly points: Point[],
    public readonly background: number,
    public readonly orient: LayerOrient = LayerOrient.N
  ) {
    super();
  }

  get isSelected(): boolean {
    return (
      globals.selectedLayer.index === this.layerIndex &&
      globals.selectedLayer.orient === this.orient
    );
  }
}
