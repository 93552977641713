export const temp = { in: 20, ex: 0 };
export enum LayerOrient {
  X = "x", // Horizontal
  Y = "y", // Vertical
  G = "g", // Ground
  N = "n", // None
}
export const prefixes = {
  junction_id: "j",
  canvas_id: "cnv-",
};
export enum BackGround {
  brick = 0,
  block = 1,
  cavity = 2,
  cavity_pink = 3,
  plasterboard = 4,
  insulation = 5,
  render = 6,
  stone = 7,
  steel = 8,
  perforated_steel = 9,
  insulation_pink = 10,
  pvc = 11,
  ground = 12,
  laminate = 13,
  timber = 14,
  timber2 = 15,
  concrete = 16,
  concrete_k2 = 17,
  slab = 18,
  insulation_044 = 19,
  insulation_044_noBorder = 20,
  wood = 21,
  wood_dark = 22,
  wood_dark_noBorder = 23,
  concrete_beam = 24,
  screed = 25,
  clay = 26,
  darkGray = 53,
  none = -1,
}
export const a4LandscapeSizeMM = {
  width: 297,
  height: 210,
};
