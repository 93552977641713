import { E05ALayerOptions } from "src/app/config/e05a.options";
import { BridgeDTO } from "src/app/junctions/common/bridge.dto";
import { JnDTO } from "src/app/junctions/common/jn.dto";
import { LayerDTO } from "src/app/junctions/common/layer.dto";
import { E05ADTO } from "src/app/junctions/e05a/e05a.dto";
import { E05AJunction } from "src/app/junctions/e05a/e05a.junction.model";

export function getAllPossibleE05AInputs(): any {
  const output = {};
  const templateBridges: BridgeDTO[] = [];
  createLayerOptions(0).forEach((lyr0) => {
    createLayerOptions(1).forEach((lyr1) => {
      createLayerOptions(2).forEach((lyr2) => {
        createLayerOptions(3).forEach((lyr3) => {
          createLayerOptions(4).forEach((lyr4) => {
            createLayerOptions(5).forEach((lyr5) => {
              createLayerOptions(6).forEach((lyr6) => {
                const e05a: E05AJunction = createNewE05AJunction(
                  lyr0,
                  lyr1,
                  lyr2,
                  lyr3,
                  lyr4,
                  lyr5,
                  lyr6,
                  templateBridges
                );
                output[e05a.calcId] = e05a.calcInput;
              });
            });
          });
        });
      });
    });
  });
  return output;
}
function createLayerOptions(lyrIndex: number): LayerDTO[] {
  const lyrOpts = [];
  E05ALayerOptions[lyrIndex].forEach((matOpt, i) => {
    const matIndex = i;
    matOpt.kvalue_options.forEach((kvalOpt, j) => {
      const kvlIndex = j;
      matOpt.thickness_options.forEach((thkOpt, k) => {
        const thkIndex = k;
        lyrOpts.push({ matIndex, thkIndex, kvlIndex } as LayerDTO);
      });
    });
  });
  return lyrOpts;
}
function createNewE05AJunction(
  lyr0: LayerDTO,
  lyr1: LayerDTO,
  lyr2: LayerDTO,
  lyr3: LayerDTO,
  lyr4: LayerDTO,
  lyr5: LayerDTO,
  lyr6: LayerDTO,
  bridges: BridgeDTO[]
): E05AJunction {
  const jnDTO = {
    _id: "dummy",
    name: "E05A (dummy)",
    jntype: "E05A",
    jndata: {
      layers: [lyr0, lyr1, lyr2, lyr3, lyr4, lyr5, lyr6],
      bridges,
      extras: [],
    } as E05ADTO,
  } as JnDTO;
  return new E05AJunction(jnDTO);
}
