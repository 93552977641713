import { Component, OnInit, Input } from "@angular/core";
import { calculateViewWidth } from "src/app/helper/app.util";
import { Junction } from "src/app/junctions/common/base.junction.model";
import { CalcService } from "src/app/services/calc.service";
import { JunctionService } from "src/app/services/junction.service";
import { globals } from "src/app/store/global.store";

@Component({
  selector: "app-junction",
  templateUrl: "./junction.component.html",
  styleUrls: ["./junction.component.css"],
})
export class JunctionComponent implements OnInit {
  @Input() jn: Junction;

  constructor(private calcService: CalcService, public jnService: JunctionService) { }
  ngOnInit(): void {
    this.calcService.fetchCalcData(this.jn.calcId); // prefetch first calc-data
    this.jn.vw = calculateViewWidth();
  }
  select(jnType: string): boolean {
    return this.jn.jntype.startsWith(jnType);
  }
  view(tab: string): boolean {
    return this.jn.tab === tab;
  }
  clickedOutside(): void {
    globals.selectedLayer.index = undefined;
    globals.selectedLayer.orient = undefined;
    globals.selectedBridge = undefined;
  }
}
