import { Component } from "@angular/core";

@Component({
  selector: "app-pdf-footer",
  templateUrl: "./pdf-footer.component.html",
  styleUrls: ["./pdf-footer.component.css"],
})
export class PdfFooterComponent {
  constructor() {}
}
