<div id="content">
  <div class="container">
    <div class="row">
      <div class="main-box">
        <div *ngIf="verificationError">
          <p class="note">Sorry, we cannot find your email address!</p>
          <p class="note">Redirecting to the Login page...</p>
        </div>

        <div class="form-container" *ngIf="!verificationError">
          <h2 class="create-password-text">Create new password</h2>

          <form [formGroup]="passwordForm" (ngSubmit)="submitForm()">
            <div class="form-group">
              <mat-form-field appearance="fill" autofilled="true">
                <mat-label>Password</mat-label>
                <input type="password" matInput formControlName="password">
                <mat-error *ngIf="passwordFormControls.password.hasError('pattern')">
                  Password must contain at least one lower case character, uppercase character and a
                  number!
                </mat-error>
                <mat-error *ngIf="passwordFormControls.password.hasError('minlength')">
                  Password must be at least 8 characters long!
                </mat-error>
                <mat-error *ngIf="passwordFormControls.password.hasError('maxlength')">
                  Password must be max 25 characters long!
                </mat-error>
                <mat-error *ngIf="passwordFormControls.password.hasError('required')">
                  Password is <strong>required</strong>
                </mat-error>
              </mat-form-field>
            </div>
            <div class="form-group">
              <mat-form-field appearance="fill" autofilled="true">
                <mat-label>Confirm Password</mat-label>
                <input type="password" matInput formControlName="confirmPassword">
                <mat-error *ngIf="passwordFormControls.confirmPassword.hasError('pattern')">
                  Confirm Password must contain at least one lower case character, uppercase character and
                  a
                  number!
                </mat-error>
                <mat-error *ngIf="passwordFormControls.password.hasError('minlength')">
                  Confirm Password must be at least 8 characters long!
                </mat-error>
                <mat-error *ngIf="passwordFormControls.confirmPassword.hasError('maxlength')">
                  Confirm Password must be max 25 characters long!
                </mat-error>
                <mat-error *ngIf="passwordFormControls.confirmPassword.hasError('required')">
                  Confirm Password is <strong>required</strong>.
                </mat-error>
                <mat-error *ngIf="passwordFormControls.confirmPassword.hasError('mustMatch')">
                  Passwords must <strong>match</strong>.
                </mat-error>
              </mat-form-field>
            </div>

            <div class="cta-section">
              <button class="btn btn-main green" [class.active]="!passwordForm.invalid"
                [disabled]="passwordForm.invalid" type="submit">Submit <i class="fa fa-arrow-right al-right"
                  aria-hidden="true"></i></button>
            </div>
          </form>
        </div>

        <p *ngIf="errorMessage" class="error-message">{{ errorMessage }}</p>

      </div>
    </div>
  </div>
</div>