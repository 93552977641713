<div class="wrapper">
  <div class="outer-container">
    <div class="inner-row">
      <div class="main-banner">
        <h1>PSI Value Calculator</h1>
        <div class="row justify-content-center">
          <div class="col-8">
            <button type="button" class="btn btn-main blue" routerLink="/select">
              Start Calculator
              <i class="fa fa-calculator al-right" aria-hidden="true"></i>
            </button>
            <button type="button" *ngIf="authService.getCurrentUser$() | async" class="btn btn-main green"
              routerLink="/saved-junctions">
              Go To My Saved Junctions
              <i class="fa fa-calculator al-right" aria-hidden="true"></i>
            </button>
            <button type="button" *ngIf="authService.getCurrentUser$() | async" class="btn btn-main yellow"
              (click)="logout()">
              Logout
              <i class="fa fa-user al-right" aria-hidden="true"></i>
            </button>
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="col-8">
            <div class="row">
              <div class="col-lg-6">
                <button type="button" *ngIf="!(authService.getCurrentUser$() | async)" class="btn btn-main"
                  routerLink="/login">
                  Login
                  <i class="fa fa-user al-right" aria-hidden="true"></i>
                </button>
              </div>

              <div class="col-lg-6">
                <button type="button" *ngIf="!(authService.getCurrentUser$() | async)" class="btn btn-main violet"
                  routerLink="/register">
                  Register
                  <i class="fa fa-user-plus al-right" aria-hidden="true"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="note-container">
        <p>
          Welcome to the Knauf Insulation PSI Value Calculator. You can start
          your free calculation by clicking at 'Start Calculator' button on the
          left.<br /><br />
          If you've never used the calculator before, you will be guided through
          the first few steps of your calculation. To contact our Technical
          Support Team at any point during the calculation, please click on
          'Contact Us' button on tob Navigation Bar. <br /><br />
          If you need a bespoke PSI Value Calculation that is not in the
          calculator, please contact our Technical Support Team to discuss your
          requirements
          <a href="https://www.knaufinsulation.co.uk/technical-support/insulation-technical-support-team-tst#tst-contact-form"
            target="_blank">here</a>.
        </p>
      </div>
    </div>
  </div>
</div>