import { Component, OnInit } from "@angular/core";
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  UntypedFormControl,
  Validators,
} from "@angular/forms";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ActivatedRoute, Router } from "@angular/router";
import { MustMatch } from "src/app/helper/must-match";
import { AuthenticationService } from "src/app/services/authentication/authentication.service";

@Component({
  selector: "app-password-reset",
  templateUrl: "./password-reset.component.html",
  styleUrls: ["./../auth.css"],
})
export class PasswordResetComponent implements OnInit {
  loading: boolean = false;
  verificationError = false;
  passwordForm: UntypedFormGroup;
  private token: string;
  errorMessage: string;

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly authService: AuthenticationService,
    private readonly router: Router,
    private readonly formBuilder: UntypedFormBuilder,
    private snackBar: MatSnackBar
  ) {
    this.activatedRoute.queryParams.subscribe((params) => {
      if (!params.token) {
        this.handleError();
      } else {
        this.token = params.token;
      }
    });
  }

  ngOnInit(): void {
    this.passwordForm = this.formBuilder.group(
      {
        password: new UntypedFormControl("", [
          Validators.required,
          Validators.minLength(8),
          Validators.maxLength(25),
          //Validator is for the letters (both uppercase and lowercase) and numbers
          Validators.pattern("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])[a-zA-Z0-9]+$"),
        ]),
        confirmPassword: new UntypedFormControl("", [
          Validators.minLength(8),
          Validators.maxLength(25),
          Validators.required,
          //Validator is for the letters (both uppercase and lowercase) and numbers
          Validators.pattern("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])[a-zA-Z0-9]+$"),
        ]),
      },
      {
        validator: MustMatch("password", "confirmPassword"),
      }
    );
  }

  get passwordFormControls() {
    return this.passwordForm.controls;
  }

  submitForm(): void {
    if (this.passwordForm.invalid) return;

    this.authService
      .resetPassword({ ...this.passwordForm.value, token: this.token })
      .subscribe({
        next: () => {
          this.snackBar.open("Password updated!", "Ok", {
            duration: 0,
            horizontalPosition: "center",
            verticalPosition: "top",
            panelClass: ["success"],
          });
          this.passwordForm.reset();
          this.router.navigate(["login"]);
        },
        error: (err) => {
          this.errorMessage = err?.error?.message
            ? err.error.message
            : "There was an issue while trying to reset your password!";
        },
      });
  }

  private handleError = () => {
    this.verificationError = true;
    setTimeout(() => {
      this.router.navigate(["login"]);
    }, 3000);
  };
}
