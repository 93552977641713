import { Component, Input } from "@angular/core";
import { E16Junction } from "src/app/junctions/e16/e16.junction.model";
import { E16LayerOptions } from "src/app/config/e16.options";
import { globals } from "src/app/store/global.store";
import { tmCanvas } from "src/app/helper/tm.cnv.util";
import { CalcService } from "src/app/services/calc.service";
import { LayerOrient } from "src/app/config/app.const";
import { LayerDTO } from "src/app/junctions/common/layer.dto";
import { JunctionService } from "src/app/services/junction.service";

@Component({
  selector: "app-e16-editor",
  templateUrl: "./e16-editor.component.html",
  styleUrls: ["./e16-editor.component.css"],
})
export class E16EditorComponent {
  options = E16LayerOptions;
  @Input() e16: E16Junction;

  constructor(
    private calcService: CalcService,
    private jnService: JunctionService
  ) {
    globals.selectedLayer.index = undefined;
    globals.selectedLayer.orient = undefined;
  }
  get layers(): LayerDTO[] {
    return this.e16.jnData.layers;
  }
  selectedClass(i: number): string {
    return globals.selectedLayer.index === i ? "selected-row" : "";
  }
  displayIndex(i: number): number {
    return this.e16.laminateLayerIsOn ? i + 1 : i;
  }
  layerClicked($event: any, index: number): void {
    globals.selectedLayer.index = index;
    globals.selectedLayer.orient = LayerOrient.N;
    globals.selectedBridge = undefined;
    $event.stopPropagation();
  }
  // Layer 1
  get lyr1MatIndex(): number {
    return this.layers[0].matIndex;
  }
  set lyr1MatIndex(i: number) {
    this.layers[0].matIndex = i;
  }
  get lyr1MatOpts(): any[] {
    return E16LayerOptions[0];
  }
  get lyr1ThkIndex(): number {
    return this.layers[0].thkIndex;
  }
  set lyr1ThkIndex(i: number) {
    this.layers[0].thkIndex = i;
  }
  get lyr1ThkOpts(): any[] {
    return E16LayerOptions[0][this.lyr1MatIndex].thickness_options;
  }
  get lyr1KvlIndex(): number {
    return this.layers[0].kvlIndex;
  }
  set lyr1KvlIndex(i: number) {
    this.layers[0].kvlIndex = i;
  }
  get lyr1KvlOpts(): any[] {
    return E16LayerOptions[0][this.lyr1MatIndex].kvalue_options;
  }
  lyr1MatChanged(): void {
    this.lyr1KvlIndex = 0;
    this.lyr1KvalChanged();
  }
  lyr1KvalChanged(): void {
    this.lyr1ThkIndex = 0;
    this.lyr1ThkChanged();
  }
  lyr1ThkChanged(): void {
    this.handleChange();
  }
  // Layer 2
  get lyr2MatIndex(): number {
    return this.layers[1].matIndex;
  }
  set lyr2MatIndex(i: number) {
    this.layers[1].matIndex = i;
  }
  get lyr2MatOpts(): any[] {
    return E16LayerOptions[1];
  }
  get lyr2ThkIndex(): number {
    return this.layers[1].thkIndex;
  }
  set lyr2ThkIndex(i: number) {
    this.layers[1].thkIndex = i;
  }
  get lyr2ThkOpts(): any[] {
    return E16LayerOptions[1][this.lyr2MatIndex].thickness_options;
  }
  get lyr2KvlIndex(): number {
    return this.layers[1].kvlIndex;
  }
  set lyr2KvlIndex(i: number) {
    this.layers[1].kvlIndex = i;
  }
  get lyr2KvlOpts(): any[] {
    return E16LayerOptions[1][this.lyr2MatIndex].kvalue_options;
  }
  lyr2MatChanged(): void {
    this.lyr2KvlIndex = 0;
    this.lyr2KvalChanged();
  }
  lyr2KvalChanged(): void {
    this.lyr2ThkIndex = 0;
    this.lyr2ThkChanged();
  }
  lyr2ThkChanged(): void {
    this.handleChange();
  }
  // Layer 3
  get lyr3MatIndex(): number {
    return this.layers[2].matIndex;
  }
  set lyr3MatIndex(i: number) {
    this.layers[2].matIndex = i;
  }
  get lyr3MatOpts(): any[] {
    return E16LayerOptions[2];
  }
  get lyr3ThkIndex(): number {
    return this.layers[2].thkIndex;
  }
  set lyr3ThkIndex(i: number) {
    this.layers[2].thkIndex = i;
  }
  get lyr3ThkOpts(): any[] {
    return E16LayerOptions[2][this.lyr3MatIndex].thickness_options;
  }
  get lyr3KvlIndex(): number {
    return this.layers[2].kvlIndex;
  }
  set lyr3KvlIndex(i: number) {
    this.layers[2].kvlIndex = i;
  }
  get lyr3KvlOpts(): any[] {
    return E16LayerOptions[2][this.lyr3MatIndex].kvalue_options;
  }
  lyr3MatChanged(): void {
    this.lyr3KvlIndex = 0;
    this.lyr3KvalChanged();
  }
  lyr3KvalChanged(): void {
    this.lyr3ThkIndex = 0;
    this.lyr3ThkChanged();
  }
  lyr3ThkChanged(): void {
    this.handleChange();
  }
  // Layer 4
  get lyr4MatIndex(): number {
    return this.layers[3].matIndex;
  }
  set lyr4MatIndex(i: number) {
    this.layers[3].matIndex = i;
  }
  get lyr4MatOpts(): any[] {
    return E16LayerOptions[3];
  }
  get lyr4ThkIndex(): number {
    return this.layers[3].thkIndex;
  }
  set lyr4ThkIndex(i: number) {
    this.layers[3].thkIndex = i;
  }
  get lyr4ThkOpts(): any[] {
    return E16LayerOptions[3][this.lyr4MatIndex].thickness_options;
  }
  get lyr4KvlIndex(): number {
    return this.layers[3].kvlIndex;
  }
  set lyr4KvlIndex(i: number) {
    this.layers[3].kvlIndex = i;
  }
  get lyr4KvlOpts(): any[] {
    return E16LayerOptions[3][this.lyr4MatIndex].kvalue_options;
  }
  lyr4MatChanged(): void {
    this.lyr4KvlIndex = 0;
    this.lyr4KvalChanged();
  }
  lyr4KvalChanged(): void {
    this.lyr4ThkIndex = 0;
    this.lyr4ThkChanged();
  }
  lyr4ThkChanged(): void {
    this.handleChange();
  }
  // Layer 5
  get lyr5MatIndex(): number {
    return this.layers[4].matIndex;
  }
  set lyr5MatIndex(i: number) {
    this.layers[4].matIndex = i;
  }
  get lyr5MatOpts(): any[] {
    return E16LayerOptions[4];
  }
  get lyr5ThkIndex(): number {
    return this.layers[4].thkIndex;
  }
  set lyr5ThkIndex(i: number) {
    this.layers[4].thkIndex = i;
  }
  get lyr5ThkOpts(): any[] {
    return E16LayerOptions[4][this.lyr5MatIndex].thickness_options;
  }
  get lyr5KvlIndex(): number {
    return this.layers[4].kvlIndex;
  }
  set lyr5KvlIndex(i: number) {
    this.layers[4].kvlIndex = i;
  }
  get lyr5KvlOpts(): any[] {
    return E16LayerOptions[4][this.lyr5MatIndex].kvalue_options;
  }
  lyr5MatChanged(): void {
    this.lyr5KvlIndex = 0;
    this.lyr5KvalChanged();
  }
  lyr5KvalChanged(): void {
    this.lyr5ThkIndex = 0;
    this.lyr5ThkChanged();
  }
  lyr5ThkChanged(): void {
    this.handleChange();
  }
  // Layer 6
  get lyr6MatIndex(): number {
    return this.layers[5].matIndex;
  }
  set lyr6MatIndex(i: number) {
    this.layers[5].matIndex = i;
  }
  get lyr6MatOpts(): any[] {
    return E16LayerOptions[5];
  }
  get lyr6ThkIndex(): number {
    return this.layers[5].thkIndex;
  }
  set lyr6ThkIndex(i: number) {
    this.layers[5].thkIndex = i;
  }
  get lyr6ThkOpts(): any[] {
    return E16LayerOptions[5][this.lyr6MatIndex].thickness_options;
  }
  get lyr6KvlIndex(): number {
    return this.layers[5].kvlIndex;
  }
  set lyr6KvlIndex(i: number) {
    this.layers[5].kvlIndex = i;
  }
  get lyr6KvlOpts(): any[] {
    return E16LayerOptions[5][this.lyr6MatIndex].kvalue_options;
  }
  lyr6MatChanged(): void {
    this.lyr6KvlIndex = 0;
    this.lyr6KvalChanged();
  }
  lyr6KvalChanged(): void {
    this.lyr6ThkIndex = 0;
    this.lyr6ThkChanged();
  }
  lyr6ThkChanged(): void {
    this.handleChange();
  }
  // Layer 7
  get lyr7MatIndex(): number {
    return this.layers[6].matIndex;
  }
  set lyr7MatIndex(i: number) {
    this.layers[6].matIndex = i;
  }
  get lyr7MatOpts(): any[] {
    return E16LayerOptions[6];
  }
  get lyr7ThkIndex(): number {
    return this.layers[6].thkIndex;
  }
  set lyr7ThkIndex(i: number) {
    this.layers[6].thkIndex = i;
  }
  get lyr7ThkOpts(): any[] {
    return E16LayerOptions[6][this.lyr7MatIndex].thickness_options;
  }
  get lyr7KvlIndex(): number {
    return this.layers[6].kvlIndex;
  }
  set lyr7KvlIndex(i: number) {
    this.layers[6].kvlIndex = i;
  }
  get lyr7KvlOpts(): any[] {
    return E16LayerOptions[6][this.lyr7MatIndex].kvalue_options;
  }
  lyr7MatChanged(): void {
    this.lyr7KvlIndex = 0;
    this.lyr7KvalChanged();
  }
  lyr7KvalChanged(): void {
    this.lyr7ThkIndex = 0;
    this.lyr7ThkChanged();
  }
  lyr7ThkChanged(): void {
    this.handleChange();
  }

  handleChange(): void {
    this.e16.generateShapes();
    this.drawCanvas();
    this.e16.saveJnToAnalytics(this.jnService).subscribe({
      next: (res) => console.log(`Success Logging Jn to Analytics: ${res}`),
      error: (err) =>
        console.error(`Error Logging Jn to Analytics: ${err.message ?? err}`),
    });
  }

  async drawCanvas(): Promise<void> {
    tmCanvas.clearTmData();
    const p1 = this.calcService.fetchCalcData(this.e16.calcId);
    const p2 = tmCanvas.drawTMCanvasPlaceHolder(this.e16);
    Promise.all([p1, p2]).then(async (values) => {
      const calcData = values[0]; // return of p1
      if (calcData) {
        tmCanvas.setTmData(calcData.tMatrix);
        await tmCanvas.drawTMCanvas(this.e16);
      }
    });
  }
}
