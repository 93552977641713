import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';

import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { AuthenticationService } from '../services/authentication/authentication.service';

@Injectable({
  providedIn: 'root'
})
export class IsAdminGuard implements CanActivate {
  constructor(
    private readonly authService: AuthenticationService,
    private readonly router: Router,
  ) { }

  canActivate(): Observable<boolean> {
    return this.authService.isUserAdmin$()
      .pipe(
        tap(isAdmin => {
          if (!isAdmin) {
            this.router.navigate(['']);
          }
        })
      )
  }
}
