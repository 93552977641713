import { Component, OnInit } from "@angular/core";
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  UntypedFormControl,
  Validators,
} from "@angular/forms";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MustMatch } from "src/app/helper/must-match";
import { RegisterUserDetails } from "src/app/interfaces/user/registerUserDetails.interface";
import { AuthenticationService } from "src/app/services/authentication/authentication.service";
import { Router } from "@angular/router";

@Component({
  selector: "app-register",
  templateUrl: "./register.component.html",
  styleUrls: ["./../auth.css"],
})
export class RegisterComponent implements OnInit {
  registerForm: UntypedFormGroup;
  errorMessage: string;

  constructor(
    private router: Router,
    private readonly formBuilder: UntypedFormBuilder,
    readonly authService: AuthenticationService,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit(): void {
    this.registerForm = this.formBuilder.group(
      {
        email: new UntypedFormControl("", [Validators.email, Validators.required]),
        password: new UntypedFormControl("", [
          Validators.minLength(8),
          Validators.maxLength(25),
          Validators.required,
          //Validator is for the letters (both uppercase and lowercase) and numbers
          Validators.pattern("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])[a-zA-Z0-9]+$"),
        ]),
        confirmPassword: new UntypedFormControl("", [
          Validators.minLength(8),
          Validators.maxLength(25),
          Validators.required,
          //Validator is for the letters (both uppercase and lowercase) and numbers
          Validators.pattern("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])[a-zA-Z0-9]+$"),
        ]),
        firstName: new UntypedFormControl("", [
          Validators.required,
          Validators.maxLength(100),
        ]),
        lastName: new UntypedFormControl("", [
          Validators.required,
          Validators.maxLength(100),
        ]),
      },
      {
        validator: MustMatch("password", "confirmPassword"),
      }
    );
  }

  get registerFormControls() {
    return this.registerForm.controls;
  }

  registerUser(): void {
    if (this.registerForm.invalid) return;

    this.authService
      .registerUser(this.registerForm.value as RegisterUserDetails)
      .subscribe({
        next: () => {
          this.snackBar.open(
            `User with email: ${this.registerFormControls.email.value} successfully created!`,
            "Ok",
            {
              duration: 0,
              horizontalPosition: "center",
              verticalPosition: "top",
              panelClass: ["success"],
            }
          ),
            this.registerForm.reset(),
            setTimeout(() => {
              this.router.navigate(["login"]);
            }, 2000);
        },
        error: (err) =>
          (this.errorMessage = err.error.message
            ? err.error.message
            : "There was an issue while registering the user!"),
      });
  }
}
