import { JnDTO } from "../common/jn.dto";
import { Junction } from "../common/base.junction.model";
import { E10DTO } from "./e10.dto";
import { Point } from "src/app/interfaces/point.interface";
import { PolyLayerPart } from "../common/poly.layer.part.model";
import {
  E10Bridges,
  E10FloorLayerOptions,
  E10LayerOptions,
} from "src/app/config/e10.options";
import { getReqId, mmToM } from "src/app/helper/app.util";
import { BridgeDTO } from "../common/bridge.dto";
import { Bridge } from "../common/bridge.model";
import { BackGround, LayerOrient } from "src/app/config/app.const";
import { Label } from "src/app/interfaces/label.interface";
import { LayerDTO } from "../common/layer.dto";
import { MaterialInfo } from "src/app/interfaces/mat.info.interface";

export class E10Junction extends Junction {
  spaceForLabel = 100;
  constructor(private dto: JnDTO) {
    super();
    this.generateShapes();
  }
  get jnWidth(): number {
    return 1000; // max possible thickness
  }
  get jnHeight(): number {
    return 1000;
  }
  get tmWidthFactor(): number {
    return 1;
  }
  get tmHeightFactor(): number {
    return 1;
  }
  get id(): string {
    return this.dto._id;
  }
  get name(): string {
    return this.dto.name;
  }
  set name(s: string) {
    this.dto.name = s;
  }
  get jntype(): string {
    return this.dto.jntype;
  }
  get jnData(): E10DTO {
    return this.dto.jndata as E10DTO;
  }
  get jnDto(): JnDTO {
    return this.dto;
  }
  get owner(): string {
    return this.jnDto.owner;
  }
  get calcId(): string {
    let cid = "E10@";
    this.jnData.layers.forEach((lyr: LayerDTO, index: number) => {
      if (this.isLayerHidden(index)) cid += "@_@_@";
      else {
        const m: MaterialInfo = E10LayerOptions[index][lyr.matIndex];
        const thk = m.thickness_options[lyr.thkIndex].val;
        const kval = m.kvalue_options[lyr.kvlIndex];
        cid += `@${thk}@${kval}@`;
      }
    });
    cid += "@@";
    // Floor layer 1 - Air Space not considered
    // Floor layer 2 - Insulation between joists
    const FL1 = this.jnData.flrLayers[0];
    const m1: MaterialInfo = E10FloorLayerOptions[0][FL1.matIndex];
    const thk1 = m1.thickness_options[FL1.thkIndex].val;
    const kval1 = m1.kvalue_options[FL1.kvlIndex];
    // Floor layer 3 - Insulation top layer - cross laid
    const FL2 = this.jnData.flrLayers[1];
    const m2: MaterialInfo = E10FloorLayerOptions[1][FL2.matIndex];
    const thk2 = m2.thickness_options[FL2.thkIndex].val;
    const kval2 = m2.kvalue_options[FL2.kvlIndex];
    // Floor layer 4 - Insulation top layer 2 - cross laid
    const FL3 = this.jnData.flrLayers[2];
    const m3: MaterialInfo = E10FloorLayerOptions[2][FL3.matIndex];
    const thk3 = m3.thickness_options[FL3.thkIndex].val;
    const kval3 = m3.kvalue_options[FL3.kvlIndex];
    if (kval1 === kval2 && kval2 === kval3)
      cid += `@${thk1 + thk2 + thk3}@${kval1}@`;
    else cid += `@INVALID@INVALID@`;
    // Floor layer 5 - Air Space not considered
    const FL4 = this.jnData.flrLayers[3];
    const m4: MaterialInfo = E10FloorLayerOptions[3][FL4.matIndex];
    const thk4 = m4.thickness_options[FL4.thkIndex].val;
    const kval4 = m4.kvalue_options[FL4.kvlIndex];
    cid += `@${thk4}@${kval4}@`;
    return cid + "@";
  }
  get laminateLayerIsOn(): boolean {
    return this.jnData.layers[1].matIndex !== 0; // 0 : Plasterboard
  }
  get blockworkIsOn(): boolean {
    return [0, 1, 2, 3, 4].includes(this.jnData.layers[5].matIndex); // Blockworks
  }
  get totalThickness(): number {
    return this.jnData.layers.reduce((x, lyrDTO, i) => {
      const m: MaterialInfo = E10LayerOptions[i][lyrDTO.matIndex];
      return this.isLayerHidden(i)
        ? x
        : x + m.thickness_options[lyrDTO.thkIndex].val;
    }, 0);
  }
  get roof1Points(): Point[] {
    const allPoints: Point[] = [];
    let point = { x: 0, y: 425 } as Point;
    for (let i = 0; i < 12; i++) {
      allPoints.push({ x: point.x, y: point.y } as Point);
      allPoints.push({ x: point.x + 60, y: point.y } as Point);
      point = { x: point.x + 60, y: point.y - 40 } as Point;
    }
    return [
      ...allPoints,
      { x: this.jnWidth - this.spaceForLabel, y: 0 } as Point,
      { x: this.jnWidth - this.spaceForLabel, y: 400 } as Point,
      {
        x:
          (this.blockworkIsOn ? this.getLayerWidth(6) : 0) +
          this.getLayerWidth(5) +
          this.getLayerWidth(4),
        y: 400,
      } as Point,
      {
        x:
          (this.blockworkIsOn ? this.getLayerWidth(6) : 0) +
          this.getLayerWidth(5) +
          this.getLayerWidth(4),
        y: 600,
      } as Point,
      { x: 0, y: 600 } as Point,
    ];
  }
  get roof2Points(): Point[] {
    const x =
      (this.blockworkIsOn ? this.getLayerWidth(6) : 0) +
      this.getLayerWidth(5) +
      this.getLayerWidth(4) +
      this.getLayerWidth(3);
    return [
      { x: x, y: 400 } as Point,
      { x: this.jnWidth - this.spaceForLabel, y: 400 } as Point,
      { x: this.jnWidth - this.spaceForLabel, y: 412.5 } as Point,
      { x: x, y: 412.5 } as Point,
    ];
  }
  get totalThkOfCombFlrLyrs(): number {
    const flyr0: LayerDTO = this.jnData.flrLayers[0];
    const m0: MaterialInfo = E10FloorLayerOptions[0][flyr0.matIndex];
    const thk0 = m0.thickness_options[flyr0.thkIndex].val;
    const flyr1: LayerDTO = this.jnData.flrLayers[1];
    const m1: MaterialInfo = E10FloorLayerOptions[1][flyr1.matIndex];
    const thk1 = m1.thickness_options[flyr1.thkIndex].val;
    const flyr2: LayerDTO = this.jnData.flrLayers[2];
    const m2: MaterialInfo = E10FloorLayerOptions[2][flyr2.matIndex];
    const thk2 = m2.thickness_options[flyr2.thkIndex].val;
    return thk0 + thk1 + thk2;
  }
  get kvalOfCombFlrLyrs(): number {
    const flyr0: LayerDTO = this.jnData.flrLayers[0];
    const m0: MaterialInfo = E10FloorLayerOptions[0][flyr0.matIndex];
    const kval = (m0.kvalue_options[flyr0.kvlIndex] ?? 0) * 1000;
    return kval;
  }
  get calcInput(): any {
    // Layers Y
    const layersy = [];
    this.jnData.layers.forEach((lyr: LayerDTO, index: number) => {
      if (!this.isLayerHidden(index)) {
        const m: MaterialInfo = E10LayerOptions[index][lyr.matIndex];
        const layer: any = {
          material: m.material,
          kvalue: m.kvalue_options[lyr.kvlIndex],
          thickness: mmToM(m.thickness_options[lyr.thkIndex].val),
        };
        layersy.push(layer);
      }
    });
    // add rse and rsi
    layersy[layersy.length - 1].rse = 0.04;
    layersy[0].rsi = 0.13;
    //Layers X
    const layersx = [];
    this.jnData.flrLayers.forEach((lyr: LayerDTO, index: number) => {
      const m: MaterialInfo = E10FloorLayerOptions[index][lyr.matIndex];
      const layer: any = {
        material: m.material,
        kvalue: m.kvalue_options[lyr.kvlIndex],
        thickness: mmToM(m.thickness_options[lyr.thkIndex].val),
      };
      layersx.push(layer);
    });
    // add rse and rsi
    layersx[0].rse = 0.04;
    layersx[layersx.length - 1].rsi = 0.1;
    // format bridges data
    // Tarik uses his predefined bridge values. Bridge is sent to server to avoid error but not used
    const bridges = [];
    this.bridges.forEach((bridge: Bridge) => {
      bridges.push({
        material: bridge.material,
        kvalue: bridge.kvalue,
        xstart: mmToM(bridge.base.x),
        xend: mmToM(bridge.base.x + bridge.width),
        ystart: mmToM(this.jnHeight - bridge.base.y - bridge.height),
        yend: mmToM(this.jnHeight - bridge.base.y),
      });
    });
    // create input
    return {
      _id: this.calcId,
      reqid: getReqId(),
      jntype: "E10",
      frame: "timber",
      rotate: 90,
      size: [300, 300],
      layersx: layersx,
      layersy: layersy.reverse(),
      bridges,
      intemp: 20,
      extemp: 0,
    };
  }
  getLayerWidth(lyrIndex: number): number {
    const layerData = this.jnData.layers[lyrIndex];
    const m: MaterialInfo = E10LayerOptions[lyrIndex][layerData.matIndex];
    return m.thickness_options[layerData.thkIndex].val;
  }
  getLayerHeight(lyrIndex: number): number {
    if ([4, 5, 6].includes(lyrIndex)) return 400;
    if ([0, 1, 2].includes(lyrIndex)) return 587.5;
    return 600;
  }
  isLayerHidden(i: number): boolean {
    return (
      (i === 0 && !this.laminateLayerIsOn) || (i === 6 && !this.blockworkIsOn)
    );
  }
  public generateShapes(): void {
    this.layerParts = [];
    this.generateLayerParts();
    this.generateFlrLayerParts();
    this.generateBridges();
  }
  public generateLayerParts(): void {
    let bp = this.totalThickness;
    this.jnData.layers.forEach((lyrDTO: LayerDTO, i: number) => {
      if (!this.isLayerHidden(i)) {
        const m: MaterialInfo = E10LayerOptions[i][lyrDTO.matIndex];
        const thk = m.thickness_options[lyrDTO.thkIndex].val;
        bp = bp - thk;
        const y = this.jnHeight - this.getLayerHeight(i);
        const points = [
          { x: bp, y: y } as Point,
          { x: bp + thk, y: y } as Point,
          { x: bp + thk, y: this.jnHeight } as Point,
          { x: bp, y: this.jnHeight } as Point,
        ];
        this.layerParts.push(
          new PolyLayerPart(i, points, m.background, LayerOrient.Y)
        );
      }
    });
  }
  private generateFlrLayerParts(): void {
    const r1 = new PolyLayerPart(
      0, // this covers layers with index [0,1,2]
      this.roof1Points,
      BackGround.insulation_044,
      LayerOrient.X
    );
    const r2 = new PolyLayerPart(
      3,
      this.roof2Points,
      BackGround.plasterboard,
      LayerOrient.X
    );
    this.layerParts.push(r1, r2);
  }
  private generateBridges(): void {
    this.bridges = [];
    // Bridge [1]
    const b1: BridgeDTO = E10Bridges[0];
    b1.base.x = this.totalThickness;
    b1.base.y = 400 - b1.height;
    this.bridges.push(new Bridge(0, b1.background, b1));
  }
  public generateLabels(): void {
    this.labels = [];
    // Fixed label
    this.labels[2] = {
      text: `${this.totalThkOfCombFlrLyrs}mm Knauf Insulation Loft Roll ${this.kvalOfCombFlrLyrs}`,
      start: { x: 800, y: 200 } as Point,
      mid: { x: 800, y: 200 } as Point,
      end: { x: this.jnWidth, y: 200 } as Point,
    } as Label;
    // Fixed label
    this.labels[3] = {
      text: "Ensure continuity of insulation between the loft and the external wall",
      start: { x: 320, y: 260 } as Point,
      mid: { x: 320, y: 260 } as Point,
      end: { x: this.jnWidth, y: 260 } as Point,
    } as Label;
    // Fixed label
    this.labels[5] = {
      text: "Fully fill the void with insulation",
      start: { x: 200, y: 435 } as Point,
      mid: { x: 200, y: 435 } as Point,
      end: { x: this.jnWidth, y: 435 } as Point,
    } as Label;

    let start = 6;
    // layer [0]
    let i = 0;
    let m = this.getMaterial(i);
    if (m) {
      this.labels[start + i] = {
        text: m,
        start: { x: this.calculateLeft(i), y: 500 } as Point,
        mid: { x: this.calculateLeft(i), y: 500 } as Point,
        end: { x: this.jnWidth, y: 500 } as Point,
      } as Label;
    }
    // layer [1]
    i = 1;
    m = this.getMaterial(i);
    if (m) {
      this.labels[start + i] = {
        text: m,
        start: { x: this.calculateLeft(i), y: 578 } as Point,
        mid: { x: this.calculateLeft(i), y: 578 } as Point,
        end: { x: this.jnWidth, y: 578 } as Point,
      } as Label;
    }
    // layer [2]
    i = 2;
    m = this.getMaterial(i);
    if (m) {
      this.labels[start + i] = {
        text: m,
        start: { x: this.calculateLeft(i), y: 655 } as Point,
        mid: { x: this.calculateLeft(i), y: 655 } as Point,
        end: { x: this.jnWidth, y: 655 } as Point,
      } as Label;
    }
    // layer [3]
    i = 3;
    m = this.getMaterial(i);
    if (m) {
      this.labels[start + i] = {
        text: m,
        start: { x: this.calculateLeft(i), y: 737 } as Point,
        mid: { x: this.calculateLeft(i), y: 737 } as Point,
        end: { x: this.jnWidth, y: 737 } as Point,
      } as Label;
    }
    // layer [4]
    i = 4;
    m = this.getMaterial(i);
    if (m) {
      this.labels[start + i] = {
        text: m,
        start: { x: this.calculateLeft(i), y: 818 } as Point,
        mid: { x: this.calculateLeft(i), y: 818 } as Point,
        end: { x: this.jnWidth, y: 818 } as Point,
      } as Label;
    }
    // layer [5]
    i = 5;
    m = this.getMaterial(i);
    if (m) {
      this.labels[start + i] = {
        text: m,
        start: { x: this.calculateLeft(i), y: 895 } as Point,
        mid: { x: this.calculateLeft(i), y: 895 } as Point,
        end: { x: this.jnWidth, y: 895 } as Point,
      } as Label;
    }
    // layer [6]
    i = 6;
    m = this.getMaterial(i);
    if (m) {
      this.labels[start + i] = {
        text: m,
        start: { x: this.calculateLeft(i), y: 973 } as Point,
        mid: { x: this.calculateLeft(i), y: 973 } as Point,
        end: { x: this.jnWidth, y: 973 } as Point,
      } as Label;
    }
  }
  private getMaterial(i: number): string {
    if (this.isLayerHidden(i)) return null;
    const layerDTO: LayerDTO = this.jnData.layers[i];
    const m: MaterialInfo = E10LayerOptions[i][layerDTO.matIndex];
    return m.material;
  }
  private getThickness(i: number): number {
    if (this.isLayerHidden(i)) return 0;
    const layerDTO: LayerDTO = this.jnData.layers[i];
    const m: MaterialInfo = E10LayerOptions[i][layerDTO.matIndex];
    return m.thickness_options[layerDTO.thkIndex].val;
  }
  private calculateLeft(i: number): number {
    let w = 0;
    for (let j = 6; j > i; j--) {
      w += this.getThickness(j);
    }
    return w + this.getThickness(i) / 2;
  }
}
