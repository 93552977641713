<div class="e05a-editor">
  <h1 class="jn-name">{{ e05a.name }}</h1>
  <table>
    <tr>
      <th width="5%">Layer No.</th>
      <th width="40%">Material</th>
      <th width="15%">K-Value (W/mK)</th>
      <th width="15%">Thickness Options (mm)</th>
      <th width="15%">Suggested product thicknesses Combination</th>
    </tr>
    <tr
      id="layer1"
      [ngClass]="selectedClass(0)"
      *ngIf="e05a.laminateLayerIsOn"
      (click)="layerClicked($event, 0)"
    >
      <td>{{ displayIndex(0) }}</td>
      <td>
        <select
          [ngClass]="lyr1MatOpts.length > 1 ? 'multi' : 'single'"
          [(ngModel)]="lyr1MatIndex"
          (change)="lyr1MatChanged()"
          [disabled]="lyr1MatOpts.length === 1"
        >
          <option
            class="opts"
            *ngFor="let op of lyr1MatOpts; let i = index"
            [ngValue]="i"
          >
            {{ op.material }}
          </option>
        </select>
      </td>
      <td>
        <select
          [(ngModel)]="lyr1KvlIndex"
          (change)="lyr1KvalChanged()"
          [disabled]="lyr1KvlOpts.length === 1"
        >
          <option *ngFor="let op of lyr1KvlOpts; let i = index" [ngValue]="i">
            {{ op.toFixed(3) }}
          </option>
        </select>
      </td>
      <td>
        <select
          [ngClass]="lyr1ThkOpts.length > 1 ? 'multi' : 'single'"
          [(ngModel)]="lyr1ThkIndex"
          (change)="lyr1ThkChanged()"
          [disabled]="lyr1ThkOpts.length === 1"
        >
          <option *ngFor="let op of lyr1ThkOpts; let i = index" [ngValue]="i">
            {{ op.val }}
          </option>
        </select>
      </td>
      <td>{{ lyr1ThkOpts[lyr1ThkIndex].comb }}</td>
    </tr>
    <tr
      id="layer2"
      [ngClass]="selectedClass(1)"
      (click)="layerClicked($event, 1)"
    >
      <td>{{ displayIndex(1) }}</td>
      <td>
        <select
          [ngClass]="lyr2MatOpts.length > 1 ? 'multi' : 'single'"
          [(ngModel)]="lyr2MatIndex"
          (change)="lyr2MatChanged()"
          [disabled]="lyr2MatOpts.length === 1"
        >
          <option
            class="opts"
            *ngFor="let op of lyr2MatOpts; let i = index"
            [ngValue]="i"
          >
            {{ op.material }}
          </option>
        </select>
      </td>
      <td>
        <select
          *ngIf="lyr2KvlOpts.length > 0"
          [ngClass]="lyr2KvlOpts.length > 1 ? 'multi' : 'single'"
          [(ngModel)]="lyr2KvlIndex"
          (change)="lyr2KvalChanged()"
          [disabled]="lyr2KvlOpts.length === 1"
        >
          <option *ngFor="let op of lyr2KvlOpts; let i = index" [ngValue]="i">
            {{ op.toFixed(3) }}
          </option>
        </select>
      </td>
      <td>
        <select
          *ngIf="lyr2ThkOpts.length > 0"
          [ngClass]="lyr2ThkOpts.length > 1 ? 'multi' : 'single'"
          [(ngModel)]="lyr2ThkIndex"
          (change)="lyr2ThkChanged()"
          [disabled]="lyr2ThkOpts.length === 1"
        >
          <option *ngFor="let op of lyr2ThkOpts; let i = index" [ngValue]="i">
            {{ op.val }}
          </option>
        </select>
      </td>
      <td>{{ lyr2ThkOpts[lyr2ThkIndex].comb }}</td>
    </tr>
    <tr
      id="layer3"
      [ngClass]="selectedClass(2)"
      (click)="layerClicked($event, 2)"
    >
      <td>{{ displayIndex(2) }}</td>
      <td>
        <select
          [ngClass]="lyr3MatOpts.length > 1 ? 'multi' : 'single'"
          [(ngModel)]="lyr3MatIndex"
          (change)="lyr3MatChanged()"
          [disabled]="lyr3MatOpts.length === 1"
        >
          <option
            class="opts"
            *ngFor="let op of lyr3MatOpts; let i = index"
            [ngValue]="i"
          >
            {{ op.material }}
          </option>
        </select>
      </td>
      <td>
        <select
          [ngClass]="lyr3KvlOpts.length > 1 ? 'multi' : 'single'"
          [(ngModel)]="lyr3KvlIndex"
          (change)="lyr3KvalChanged()"
          [disabled]="lyr3KvlOpts.length === 1"
        >
          <option *ngFor="let op of lyr3KvlOpts; let i = index" [ngValue]="i">
            {{ op.toFixed(3) }}
          </option>
        </select>
      </td>
      <td>
        <select
          [ngClass]="lyr3ThkOpts.length > 1 ? 'multi' : 'single'"
          [(ngModel)]="lyr3ThkIndex"
          (change)="lyr3ThkChanged()"
          [disabled]="lyr3ThkOpts.length === 1"
        >
          <option *ngFor="let op of lyr3ThkOpts; let i = index" [ngValue]="i">
            {{ op.val }}
          </option>
        </select>
      </td>
      <td>{{ lyr3ThkOpts[lyr3ThkIndex].comb }}</td>
    </tr>
    <tr
      id="layer4"
      [ngClass]="selectedClass(3)"
      (click)="layerClicked($event, 3)"
    >
      <td>{{ displayIndex(3) }}</td>
      <td>
        <select
          [ngClass]="lyr4MatOpts.length > 1 ? 'multi' : 'single'"
          [(ngModel)]="lyr4MatIndex"
          (change)="lyr4MatChanged()"
          [disabled]="lyr4MatOpts.length === 1"
        >
          <option
            class="opts"
            *ngFor="let op of lyr4MatOpts; let i = index"
            [ngValue]="i"
          >
            {{ op.material }}
          </option>
        </select>
      </td>
      <td>
        <select
          [ngClass]="lyr4KvlOpts.length > 1 ? 'multi' : 'single'"
          [(ngModel)]="lyr4KvlIndex"
          (change)="lyr4KvalChanged()"
          [disabled]="lyr4KvlOpts.length === 1"
        >
          <option *ngFor="let op of lyr4KvlOpts; let i = index" [ngValue]="i">
            {{ op.toFixed(3) }}
          </option>
        </select>
      </td>
      <td>
        <select
          [ngClass]="lyr4ThkOpts.length > 1 ? 'multi' : 'single'"
          [(ngModel)]="lyr4ThkIndex"
          (change)="lyr4ThkChanged()"
          [disabled]="lyr4ThkOpts.length === 1"
        >
          <option *ngFor="let op of lyr4ThkOpts; let i = index" [ngValue]="i">
            {{ op.val }}
          </option>
        </select>
      </td>
      <td>{{ lyr4ThkOpts[lyr4ThkIndex].comb }}</td>
    </tr>
    <tr
      id="layer5"
      [ngClass]="selectedClass(4)"
      (click)="layerClicked($event, 4)"
    >
      <td>{{ displayIndex(4) }}</td>
      <td>
        <select
          [ngClass]="lyr5MatOpts.length > 1 ? 'multi' : 'single'"
          [(ngModel)]="lyr5MatIndex"
          (change)="lyr5MatChanged()"
          [disabled]="lyr5MatOpts.length === 1"
        >
          <option
            class="opts"
            *ngFor="let op of lyr5MatOpts; let i = index"
            [ngValue]="i"
          >
            {{ op.material }}
          </option>
        </select>
      </td>
      <td>
        <select
          [ngClass]="lyr5KvlOpts.length > 1 ? 'multi' : 'single'"
          [(ngModel)]="lyr5KvlIndex"
          (change)="lyr5KvalChanged()"
          [disabled]="lyr5KvlOpts.length === 1"
        >
          <option *ngFor="let op of lyr5KvlOpts; let i = index" [ngValue]="i">
            {{ op.toFixed(3) }}
          </option>
        </select>
      </td>
      <td>
        <select
          [ngClass]="lyr5ThkOpts.length > 1 ? 'multi' : 'single'"
          [(ngModel)]="lyr5ThkIndex"
          (change)="lyr5ThkChanged()"
          [disabled]="lyr5ThkOpts.length === 1"
        >
          <option *ngFor="let op of lyr5ThkOpts; let i = index" [ngValue]="i">
            {{ op.val }}
          </option>
        </select>
      </td>
      <td *ngIf="lyr5ThkOpts[lyr5ThkIndex] !== undefined">
        {{ lyr5ThkOpts[lyr5ThkIndex].comb }}
      </td>
    </tr>
    <tr
      id="layer6"
      [ngClass]="selectedClass(5)"
      (click)="layerClicked($event, 5)"
    >
      <td>{{ displayIndex(5) }}</td>
      <td>
        <select
          [ngClass]="lyr6MatOpts.length > 1 ? 'multi' : 'single'"
          [(ngModel)]="lyr6MatIndex"
          (change)="lyr6MatChanged()"
          [disabled]="lyr6MatOpts.length === 1"
        >
          <option
            class="opts"
            *ngFor="let op of lyr6MatOpts; let i = index"
            [ngValue]="i"
          >
            {{ op.material }}
          </option>
        </select>
      </td>
      <td>
        <select
          [ngClass]="lyr6KvlOpts.length > 1 ? 'multi' : 'single'"
          [(ngModel)]="lyr6KvlIndex"
          (change)="lyr6KvalChanged()"
          [disabled]="lyr6KvlOpts.length === 1"
        >
          <option *ngFor="let op of lyr6KvlOpts; let i = index" [ngValue]="i">
            {{ op.toFixed(3) }}
          </option>
        </select>
      </td>
      <td>
        <select
          [ngClass]="lyr6ThkOpts.length > 1 ? 'multi' : 'single'"
          [(ngModel)]="lyr6ThkIndex"
          (change)="lyr6ThkChanged()"
          [disabled]="lyr6ThkOpts.length === 1"
        >
          <option *ngFor="let op of lyr6ThkOpts; let i = index" [ngValue]="i">
            {{ op.val }}
          </option>
        </select>
      </td>
      <td>&nbsp;</td>
    </tr>
    <tr
      id="layer7"
      [ngClass]="selectedClass(6)"
      *ngIf="e05a.blockworkIsOn"
      (click)="layerClicked($event, 6)"
    >
      <td>{{ displayIndex(6) }}</td>
      <td>
        <select
          [ngClass]="lyr7MatOpts.length > 1 ? 'multi' : 'single'"
          [(ngModel)]="lyr7MatIndex"
          (change)="lyr7MatChanged()"
          [disabled]="lyr7MatOpts.length === 1"
        >
          <option
            class="opts"
            *ngFor="let op of lyr7MatOpts; let i = index"
            [ngValue]="i"
          >
            {{ op.material }}
          </option>
        </select>
      </td>
      <td>
        <select
          [ngClass]="lyr7KvlOpts.length > 1 ? 'multi' : 'single'"
          [(ngModel)]="lyr7KvlIndex"
          (change)="lyr7KvalChanged()"
          [disabled]="lyr7KvlOpts.length === 1"
        >
          <option *ngFor="let op of lyr7KvlOpts; let i = index" [ngValue]="i">
            {{ op.toFixed(3) }}
          </option>
        </select>
      </td>
      <td>
        <select
          [ngClass]="lyr7ThkOpts.length > 1 ? 'multi' : 'single'"
          [(ngModel)]="lyr7ThkIndex"
          (change)="lyr7ThkChanged()"
          [disabled]="lyr7ThkOpts.length === 1"
        >
          <option *ngFor="let op of lyr7ThkOpts; let i = index" [ngValue]="i">
            {{ op.val }}
          </option>
        </select>
      </td>
      <td>{{ lyr7ThkOpts[lyr7ThkIndex].comb }}</td>
    </tr>
  </table>
</div>
