<div class="navbar navbar-light bg-light">
  <div class="nav-info">
    <button class="btn arrow-btn" (click)="back()">
      <i class="fa fa-arrow-circle-left" aria-hidden="true"></i>Back
    </button>
  </div>
  <div>
    <span class="navbar-brand mb-0 h1">KNAUF INSULATION PSI-VALUE CALCULATOR</span>
  </div>
  <div class="d-none d-md-block">
    <a type="button" class="btn btn-sml green-btn" href="https://www.knaufinsulation.co.uk/contact-us" target="_blank"
      rel=noopener>Contact Us</a>
    <button type="button" *ngIf="authService.getCurrentUser$() | async" class="btn btn-sml yellow-btn"
      (click)="logout()">Logout</button>
    <button type="button" *ngIf="!(authService.getCurrentUser$() | async)" class="btn btn-sml green-btn"
      routerLink='/login'>Login</button>
    <button type="button" *ngIf="!(authService.getCurrentUser$() | async)" class="btn btn-sml violet-btn"
      routerLink='/register'>Register</button>
    <button type="button" class="btn btn-sml green-btn" routerLink='/home'>Home</button>
  </div>
</div>
<ng-content></ng-content>