import { Point } from "../interfaces/point.interface";
import { prefixes } from "../config/app.const";

export function getPointsSvgFormat(
  points: Point[],
  viewEnhanceFactor: number
): string {
  let svgFormat = "";
  points.forEach((el) => {
    svgFormat += `${el.x * viewEnhanceFactor},${el.y * viewEnhanceFactor} `;
  });
  return svgFormat.trim();
}
/**
 * Converts svg strings to points
 * @param pointStr : format "x1,y1 x2,y2 x3,y3"
 * Return: Point[]
 */
export function convertStringToPoints(pointStr: string): Point[] {
  try {
    const points = [];
    pointStr.split(" ").forEach((el) => {
      const pt = el.split(",");
      points.push({ x: Number(pt[0]), y: Number(pt[1]) } as Point);
    });
    return points;
  } catch (er) {
    console.error("failed parsing input", pointStr, er);
    return [];
  }
}
/**
 * Converts points to svg string
 * @param points : Point[]
 * @returns pointStr : string in format "x1,y1 x2,y2 x3,y3"
 */
export function convertPointsToString(points: Point[]): string {
  try {
    let pointStr = "";
    points.forEach((pt) => {
      pointStr += `${pt.x},${pt.y} `;
    });
    return pointStr.trim();
  } catch (er) {
    console.error("failed parsing input", points, er);
    return "";
  }
}
export function getAbsolutePointsSvgFormat(
  base: Point,
  points: Point[],
  viewEnhanceFactor: number
): string {
  let svgFormat = "";
  points.forEach((el) => {
    svgFormat += `${(base.x + el.x) * viewEnhanceFactor},${
      (base.y + el.y) * viewEnhanceFactor
    } `;
  });
  return svgFormat.trim();
}
export function calculateViewWidth(): number {
  const MIN_WIDTH = 200; // px
  const getElement = (id: string) => document.getElementById(id) as HTMLElement;
  const container = getElement("right-area-of-jn-display");
  const calc = getElement("result-box");
  const winHeight = window.innerHeight;
  if (!container || !calc || !winHeight) return MIN_WIDTH;
  const containerBox = container.getBoundingClientRect();
  const calcBox = calc.getBoundingClientRect();
  const w = containerBox.width;
  const h = winHeight - calcBox.height - 100;
  const vw = w < h ? w : h;
  return Math.floor(vw < MIN_WIDTH ? MIN_WIDTH : vw);
}
export function createNewJnId(): string {
  return createNewId(prefixes.junction_id);
}
export function isValidJnId(jnId: string): boolean {
  return validateId(jnId, prefixes.junction_id);
}
export function createNewCanvasId(): string {
  return createNewId(prefixes.canvas_id);
}
function createNewId(prefix: string): string {
  // ID => prefix + random_string + epoch
  // Prefix: a input string
  // Random_string: random 3 characters string
  // Epoch: time in epoch (uniqueness)
  return (
    prefix +
    getRandomChar() +
    getRandomChar() +
    getRandomChar() +
    Date.now().toString(36)
  );
}
function validateId(id: string, prefix: string): boolean {
  try {
    if (!id.startsWith(prefix)) return false;
    const epochStr = id.substring(prefix.length + 3); // get the epoch part of the id
    const epoch = parseInt(epochStr, 36); // convert from base 36 to base 10
    if (epoch < 1639605101000) return false; // 1639605101000 => 15 December 2021 21:51:41:000 GMT
    if (epoch > 2554754106000) return false; // 2554754106000 => 15 December 2050 21:55:06:000 GMT
    return true;
  } catch (er) {
    // string was modified
    return false;
  }
}
function getRandomChar() {
  const alphabet =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  return alphabet[Math.floor(Math.random() * alphabet.length)];
}
export function getReqId(): number {
  return Math.floor(Math.random() * 100000); // random request id
}
export function mmToM(n: number): number {
  return n / 1000;
}
export function round(n: number): number {
  if (n === undefined) return 0;
  const af = 1000; // accuracy factor
  return Math.floor(n * af) / af;
}
export const padInt = (n: number) => n.toString(36).padStart(2, "0");
export const padStr = (s: string) => s.padStart(2, "@");
/**
 * Deep Copy of a object:
 * Ref. https://gist.github.com/erikvullings/ada7af09925082cbb89f40ed962d475e
 */
export function deepCopy<T>(target: T): T {
  if (target === null) {
    return target;
  }
  if (target instanceof Date) {
    return new Date(target.getTime()) as any;
  }
  // First part is for array and second part is for Realm.Collection
  // if (target instanceof Array || typeof (target as any).type === 'string') {
  if (typeof target === "object") {
    if (typeof target[(Symbol as any).iterator] === "function") {
      const cp = [] as any[];
      if ((target as any as any[]).length > 0) {
        for (const arrayMember of target as any as any[]) {
          cp.push(deepCopy(arrayMember));
        }
      }
      return cp as any as T;
    } else {
      const targetKeys = Object.keys(target);
      const cp = {};
      if (targetKeys.length > 0) {
        for (const key of targetKeys) {
          cp[key] = deepCopy(target[key]);
        }
      }
      return cp as T;
    }
  }
  // Means that object is atomic
  return target;
}
