import { BackGround } from "src/app/config/app.const";
import { Point } from "src/app/interfaces/point.interface";
import { GroundDTO } from "./ground.dto";

export class Ground {
  background: number = BackGround.ground;

  constructor(
    public index: number,
    private dto: GroundDTO,
    private jntype: string
  ) {}

  get id(): string {
    return `gnd-${this.index}`;
  }
  get className(): string {
    return `cls-${this.id}`;
  }
  get patternName(): string {
    return `pat-${this.id}`;
  }
  get base(): Point {
    return this.dto.base;
  }
  set base(pt: Point) {
    this.dto.base = pt;
  }
  get width(): number {
    return this.dto.width;
  }
  set width(n: number) {
    this.dto.width = n;
  }
  get height(): number {
    return this.dto.height;
  }
  set height(n: number) {
    this.dto.height = n;
  }
  get points(): Point[] {
    return this.jntype === "E05B" ? this.e05bPoints : this.e05aPoints;
  }
  private get e05aPoints(): Point[] {
    return [
      { x: this.base.x, y: this.base.y } as Point,
      { x: this.base.x + this.width, y: this.base.y } as Point,
      { x: this.base.x + this.width, y: this.base.y + this.height } as Point,
      { x: this.base.x, y: this.base.y + this.height } as Point,
    ];
  }
  private get e05bPoints(): Point[] {
    return [
      { x: this.base.x, y: this.base.y } as Point,
      { x: this.base.x + this.width / 3, y: this.base.y } as Point,
      { x: this.base.x + this.width / 3, y: this.base.y + 225 } as Point,
      { x: this.base.x + this.width, y: this.base.y + 225 } as Point,
      { x: this.base.x + this.width, y: this.base.y + this.height } as Point,
      { x: this.base.x, y: this.base.y + this.height } as Point,
    ];
  }
}
