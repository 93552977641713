import { JnDTO } from "../common/jn.dto";
import { Junction } from "../common/base.junction.model";
import { E01DTO } from "./e01.dto";
import { Point } from "src/app/interfaces/point.interface";
import { PolyLayerPart } from "../common/poly.layer.part.model";
import { E01Bridges, E01LayerOptions } from "src/app/config/e01.options";
import {
  convertPointsToString,
  getReqId,
  mmToM,
} from "src/app/helper/app.util";
import { BridgeDTO } from "../common/bridge.dto";
import { Bridge } from "../common/bridge.model";
import { ExtraDTO } from "../common/extra.dto";
import { Extra } from "../common/extra.model";
import { BackGround } from "src/app/config/app.const";
import { Label } from "src/app/interfaces/label.interface";
import { LayerDTO } from "../common/layer.dto";
import { MaterialInfo } from "src/app/interfaces/mat.info.interface";
import { JunctionService } from "src/app/services/junction.service";

export class E01Junction extends Junction {
  extra1 = { thickness: 3, top: 153 };
  constructor(private dto: JnDTO) {
    super();
    this.generateShapes();
  }
  get jnWidth(): number {
    return 650; // max possible thickness
  }
  get jnHeight(): number {
    return 480;
  }
  get tmWidthFactor(): number {
    return this.totalThickness / 650;
  }
  get tmHeightFactor(): number {
    return this.jnHeight / 650;
  }
  get id(): string {
    return this.dto._id;
  }
  get name(): string {
    return this.dto.name;
  }
  set name(s: string) {
    this.dto.name = s;
  }
  get jntype(): string {
    return this.dto.jntype;
  }
  get jnData(): E01DTO {
    return this.dto.jndata as E01DTO;
  }
  get jnDto(): JnDTO {
    return this.dto;
  }
  get owner(): string {
    return this.jnDto.owner;
  }
  get calcId(): string {
    let cid = "E01@";
    this.jnData.layers.forEach((lyr: LayerDTO, index: number) => {
      if (this.isLayerHidden(index)) {
        cid += "@_@_@_@";
      } else {
        const m: MaterialInfo = E01LayerOptions[index][lyr.matIndex];
        const code = m.code;
        const thk = m.thickness_options[lyr.thkIndex].val;
        const kval = m.kvalue_options[lyr.kvlIndex];
        cid += `@${code}@${thk}@${kval}@`;
      }
    });
    return cid + "@";
  }
  get laminateLayerIsOn(): boolean {
    return this.jnData.layers[1].matIndex !== 0; // 0 : Plasterboard
  }
  get blockworkIsOn(): boolean {
    return [0, 1, 2, 3, 4].includes(this.jnData.layers[5].matIndex); // Blockworks
  }
  get totalThickness(): number {
    return this.jnData.layers.reduce((x, lyrDTO, i) => {
      const m: MaterialInfo = E01LayerOptions[i][lyrDTO.matIndex];
      return this.isLayerHidden(i)
        ? x
        : x + m.thickness_options[lyrDTO.thkIndex].val;
    }, 0);
  }
  get left(): number {
    return (this.jnWidth - this.totalThickness) / 2;
  }
  get calcInput(): any {
    const layers = [];
    this.jnData.layers.forEach((lyr: LayerDTO, index: number) => {
      if (!this.isLayerHidden(index)) {
        const m: MaterialInfo = E01LayerOptions[index][lyr.matIndex];
        const layer: any = {
          material: m.material,
          kvalue: m.kvalue_options[lyr.kvlIndex],
          thicknessx: mmToM(m.thickness_options[lyr.thkIndex].val),
        };
        layers.push(layer);
      }
    });
    // add rse and rsi
    layers[layers.length - 1].rse = 0.04;
    layers[0].rsi = 0.13;
    // format bridges data
    // Tarik uses his predefined bridge values. Bridge is sent to server to avoid error but not used
    const bridges = [];
    this.bridges.forEach((bridge: Bridge) => {
      bridges.push({
        material: bridge.material,
        kvalue: bridge.kvalue,
        xstart: mmToM(bridge.base.x - this.left),
        xend: mmToM(bridge.base.x + bridge.width - this.left),
        ystart: mmToM(this.jnHeight - bridge.base.y - bridge.height),
        yend: mmToM(this.jnHeight - bridge.base.y),
      });
    });
    // create input
    return {
      _id: this.calcId,
      reqid: getReqId(),
      jntype: "E01",
      frame: "timber",
      rotate: 90,
      size: [600, 600],
      layers: layers.reverse(),
      bridges,
      intemp: 20,
      extemp: 0,
    };
  }
  get extra1Base(): Point {
    return {
      x:
        this.left +
        (this.blockworkIsOn ? this.getLayerWidth(6) : 0) +
        this.getLayerWidth(5) * 0.2,
      y: this.extra1.top,
    } as Point;
  }
  get extra1Points(): Point[] {
    const leftWidth = this.getLayerWidth(5) * 0.8;
    const middleWidth = this.getLayerWidth(4);
    const rightWidth = this.getLayerWidth(3) * 0.75;
    const slope = 50;
    const height = 300;
    return [
      { x: leftWidth, y: slope } as Point, // Top-Left
      { x: leftWidth + middleWidth, y: 0 } as Point,
      {
        x: leftWidth + middleWidth,
        y: height - this.extra1.thickness,
      } as Point,
      {
        x: leftWidth + middleWidth + rightWidth,
        y: height - this.extra1.thickness,
      } as Point,
      {
        x: leftWidth + middleWidth + rightWidth - 5,
        y: height,
      } as Point, // Bottom-Right
      {
        x: leftWidth + middleWidth - this.extra1.thickness,
        y: height,
      } as Point,
      {
        x: leftWidth + middleWidth - this.extra1.thickness,
        y: this.extra1.thickness * 1.5,
      } as Point,
      {
        x: leftWidth + this.extra1.thickness,
        y: slope + this.extra1.thickness * 0.8,
      } as Point,
      {
        x: leftWidth + this.extra1.thickness,
        y: height,
      } as Point,
      { x: 0, y: height } as Point, // Left-Bottom
      { x: 0, y: height - this.extra1.thickness } as Point,
      {
        x: leftWidth,
        y: height - this.extra1.thickness,
      } as Point,
    ];
  }
  getLayerHeight(lyrIndex: number): number {
    if (lyrIndex === 0 || lyrIndex === 1) return this.jnHeight;
    if (lyrIndex === 2) return this.jnHeight - E01Bridges[0].height;
    if (lyrIndex === 4)
      return (
        this.jnHeight -
        E01Bridges[0].height -
        E01Bridges[1].height -
        E01Bridges[2].height +
        this.extra1.thickness
      );
    return (
      this.jnHeight -
      E01Bridges[0].height -
      E01Bridges[1].height -
      E01Bridges[2].height
    );
  }
  getLayerWidth(lyrIndex: number): number {
    const layerData = this.jnData.layers[lyrIndex];
    const m: MaterialInfo = E01LayerOptions[lyrIndex][layerData.matIndex];
    return m.thickness_options[layerData.thkIndex].val;
  }
  isLayerHidden(i: number): boolean {
    return (
      (i === 0 && !this.laminateLayerIsOn) || (i === 6 && !this.blockworkIsOn)
    );
  }
  public generateShapes(): void {
    this.generateLayerParts();
    this.generateBridges();
    this.generateExtras();
  }
  public generateLayerParts(): void {
    this.layerParts = [];
    let bp = this.left + this.totalThickness;
    this.jnData.layers.forEach((lyrDTO: LayerDTO, i: number) => {
      if (!this.isLayerHidden(i)) {
        const m: MaterialInfo = E01LayerOptions[i][lyrDTO.matIndex];
        const thk = m.thickness_options[lyrDTO.thkIndex].val;
        bp = bp - thk;
        const h = this.getLayerHeight(i);
        const points = [
          { x: bp, y: 0 } as Point,
          { x: bp + thk, y: 0 } as Point,
          { x: bp + thk, y: h } as Point,
          { x: bp, y: h } as Point,
        ];
        this.layerParts.push(new PolyLayerPart(i, points, m.background));
      }
    });
  }
  private generateBridges(): void {
    this.bridges = [];
    // Bridge [1]
    const b1: BridgeDTO = E01Bridges[0];
    b1.width =
      this.getLayerWidth(4) * 0.7 +
      this.getLayerWidth(3) +
      this.getLayerWidth(2) +
      this.getLayerWidth(1);
    b1.base.x =
      this.left +
      (this.blockworkIsOn ? this.getLayerWidth(6) : 0) +
      this.getLayerWidth(5) +
      this.getLayerWidth(4) * 0.3;
    b1.base.y = this.jnHeight - E01Bridges[0].height;
    this.bridges.push(new Bridge(0, b1.background, b1));
    // Bridge [2]
    const b2: BridgeDTO = E01Bridges[1];
    b2.width = this.getLayerWidth(4) * 0.7 + this.getLayerWidth(3);
    b2.base.x =
      this.left +
      (this.blockworkIsOn ? this.getLayerWidth(6) : 0) +
      this.getLayerWidth(5) +
      this.getLayerWidth(4) * 0.3;
    b2.base.y = this.jnHeight - E01Bridges[0].height - E01Bridges[1].height;
    this.bridges.push(new Bridge(1, b2.background, b2));
    // Bridge [3]
    const b3: BridgeDTO = E01Bridges[2];
    b3.width = this.getLayerWidth(3);
    b3.base.x =
      this.left +
      (this.blockworkIsOn ? this.getLayerWidth(6) : 0) +
      this.getLayerWidth(5) +
      this.getLayerWidth(4);
    b3.base.y =
      this.jnHeight -
      E01Bridges[0].height -
      E01Bridges[1].height -
      E01Bridges[2].height;
    this.bridges.push(new Bridge(2, b3.background, b3));
    // Bridge [4]
    const b4: BridgeDTO = E01Bridges[3];
    b4.width =
      this.getLayerWidth(5) * 0.4 +
      this.getLayerWidth(4) +
      this.getLayerWidth(3) * 0.4;
    b4.base.x =
      this.left +
      (this.blockworkIsOn ? this.getLayerWidth(6) : 0) +
      this.getLayerWidth(5) * 0.6;
    b4.base.y = this.jnHeight - E01Bridges[0].height - E01Bridges[1].height;
    this.bridges.push(new Bridge(3, b4.background, b4));
  }
  private generateExtras(): void {
    this.extras = [];
    const e1 = {
      type: "e01-extra-01",
      base: this.extra1Base,
      points: convertPointsToString(this.extra1Points),
      background: BackGround.steel,
    } as ExtraDTO;
    this.extras.push(new Extra(0, e1));
  }
  public generateLabels(): void {
    this.labels = [];
    let start = 1;
    // layer [0]
    let i = 0;
    let m = this.getMaterial(i);
    if (m) {
      this.labels[start + i] = {
        text: m,
        start: { x: this.calculateLeft(i), y: 45 } as Point,
        mid: { x: this.calculateLeft(i), y: 45 } as Point,
        end: { x: this.jnWidth, y: 45 } as Point,
      } as Label;
    }
    // layer [1]
    i = 1;
    m = this.getMaterial(i);
    if (m) {
      this.labels[start + i] = {
        text: m,
        start: { x: this.calculateLeft(i), y: 85 } as Point,
        mid: { x: this.calculateLeft(i), y: 85 } as Point,
        end: { x: this.jnWidth, y: 85 } as Point,
      } as Label;
    }
    // layer [2]
    i = 2;
    m = this.getMaterial(i);
    if (m) {
      this.labels[start + i] = {
        text: m,
        start: { x: this.calculateLeft(i), y: 123 } as Point,
        mid: { x: this.calculateLeft(i), y: 123 } as Point,
        end: { x: this.jnWidth, y: 123 } as Point,
      } as Label;
    }
    // layer [3]
    i = 3;
    m = this.getMaterial(i);
    if (m) {
      this.labels[start + i] = {
        text: m,
        start: { x: this.calculateLeft(i), y: 165 } as Point,
        mid: { x: this.calculateLeft(i), y: 165 } as Point,
        end: { x: this.jnWidth, y: 165 } as Point,
      } as Label;
    }
    // layer [4]
    i = 4;
    m = this.getMaterial(i);
    if (m) {
      this.labels[start + i] = {
        text: m,
        start: { x: this.calculateLeft(i), y: 200 } as Point,
        mid: { x: this.calculateLeft(i), y: 200 } as Point,
        end: { x: this.jnWidth, y: 200 } as Point,
      } as Label;
    }
    // layer [5]
    i = 5;
    m = this.getMaterial(i);
    if (m) {
      this.labels[start + i] = {
        text: m,
        start: { x: this.calculateLeft(i), y: 240 } as Point,
        mid: { x: this.calculateLeft(i), y: 240 } as Point,
        end: { x: this.jnWidth, y: 240 } as Point,
      } as Label;
    }
    // layer [6]
    i = 6;
    m = this.getMaterial(i);
    if (m) {
      this.labels[start + i] = {
        text: m,
        start: { x: this.calculateLeft(i), y: 278 } as Point,
        mid: { x: this.calculateLeft(i), y: 278 } as Point,
        end: { x: this.jnWidth, y: 278 } as Point,
      } as Label;
    }

    // Fixed label
    this.labels[8] = {
      text: "Minimum frame overlap to be 30mm",
      start: { x: 300, y: 450 } as Point,
      mid: { x: 300, y: 320 } as Point,
      end: { x: this.jnWidth, y: 320 } as Point,
    } as Label;
    // Fixed label
    this.labels[9] = {
      text: "The equivalent conductivity of the base plate shound be no more than 10W/mK",
      start: { x: 330, y: 450 } as Point,
      mid: { x: 330, y: 360 } as Point,
      end: { x: this.jnWidth, y: 360 } as Point,
    } as Label;
    // Fixed label
    this.labels[11] = {
      text: "Apply insulation with R-value of at least 0.5m2K/W",
      start: { x: 450, y: 460 } as Point,
      mid: { x: 450, y: 430 } as Point,
      end: { x: this.jnWidth, y: 430 } as Point,
    } as Label;
  }
  private getMaterial(i: number): string {
    if (this.isLayerHidden(i)) return null;
    const layerDTO: LayerDTO = this.jnData.layers[i];
    const m: MaterialInfo = E01LayerOptions[i][layerDTO.matIndex];
    return m.material;
  }
  private getThickness(i: number): number {
    if (this.isLayerHidden(i)) return 0;
    const layerDTO: LayerDTO = this.jnData.layers[i];
    const m: MaterialInfo = E01LayerOptions[i][layerDTO.matIndex];
    return m.thickness_options[layerDTO.thkIndex].val;
  }
  private calculateLeft(i: number): number {
    let w = this.left;
    for (let j = 6; j > i; j--) {
      w += this.getThickness(j);
    }
    return w + this.getThickness(i) / 2;
  }
}
