import { E05BLayerOptions } from "src/app/config/e05b.options";
import { BridgeDTO } from "src/app/junctions/common/bridge.dto";
import { JnDTO } from "src/app/junctions/common/jn.dto";
import { LayerDTO } from "src/app/junctions/common/layer.dto";
import { E05BDTO } from "src/app/junctions/e05b/e05b.dto";
import { E05BJunction } from "src/app/junctions/e05b/e05b.junction.model";

export function getAllPossibleE05BInputs(): any {
  const output = {};
  const templateBridges: BridgeDTO[] = [];
  createLayerOptions(0).forEach((lyr0) => {
    createLayerOptions(1).forEach((lyr1) => {
      createLayerOptions(2).forEach((lyr2) => {
        createLayerOptions(3).forEach((lyr3) => {
          createLayerOptions(4).forEach((lyr4) => {
            createLayerOptions(5).forEach((lyr5) => {
              createLayerOptions(6).forEach((lyr6) => {
                const e05b: E05BJunction = createNewE05BJunction(
                  lyr0,
                  lyr1,
                  lyr2,
                  lyr3,
                  lyr4,
                  lyr5,
                  lyr6,
                  templateBridges
                );
                output[e05b.calcId] = e05b.calcInput;
              });
            });
          });
        });
      });
    });
  });
  return output;
}
function createLayerOptions(lyrIndex: number): LayerDTO[] {
  const lyrOpts = [];
  E05BLayerOptions[lyrIndex].forEach((matOpt, i) => {
    const matIndex = i;
    matOpt.kvalue_options.forEach((kvalOpt, j) => {
      const kvlIndex = j;
      matOpt.thickness_options.forEach((thkOpt, k) => {
        const thkIndex = k;
        lyrOpts.push({ matIndex, thkIndex, kvlIndex } as LayerDTO);
      });
    });
  });
  return lyrOpts;
}
function createNewE05BJunction(
  lyr0: LayerDTO,
  lyr1: LayerDTO,
  lyr2: LayerDTO,
  lyr3: LayerDTO,
  lyr4: LayerDTO,
  lyr5: LayerDTO,
  lyr6: LayerDTO,
  bridges: BridgeDTO[]
): E05BJunction {
  const jnDTO = {
    _id: "dummy",
    name: "E05B (dummy)",
    jntype: "E05B",
    jndata: {
      layers: [lyr0, lyr1, lyr2, lyr3, lyr4, lyr5, lyr6],
      bridges,
      extras: [],
    } as E05BDTO,
  } as JnDTO;
  return new E05BJunction(jnDTO);
}
