import { JnDTO } from "../common/jn.dto";
import { Junction } from "../common/base.junction.model";
import { E03DTO } from "./e03.dto";
import { Point } from "src/app/interfaces/point.interface";
import { PolyLayerPart } from "../common/poly.layer.part.model";
import { E03Bridges, E03LayerOptions } from "src/app/config/e03.options";
import {
  convertPointsToString,
  getReqId,
  mmToM,
} from "src/app/helper/app.util";
import { BridgeDTO } from "../common/bridge.dto";
import { Bridge } from "../common/bridge.model";
import { ExtraDTO } from "../common/extra.dto";
import { Extra } from "../common/extra.model";
import { BackGround } from "src/app/config/app.const";
import { Label } from "src/app/interfaces/label.interface";
import { LayerDTO } from "../common/layer.dto";
import { MaterialInfo } from "src/app/interfaces/mat.info.interface";

export class E03Junction extends Junction {
  pvcThickness = 2; // mm
  extra1 = { thickness: 50, extn: 75 };
  top = 100;
  constructor(private dto: JnDTO) {
    super();
    this.generateShapes();
  }
  get jnWidth(): number {
    return 750; // max possible thickness
  }
  get jnHeight(): number {
    return 550;
  }
  get tmWidthFactor(): number {
    return this.totalThickness / 700;
  }
  get tmHeightFactor(): number {
    return this.jnHeight / 900;
  }
  get id(): string {
    return this.dto._id;
  }
  get name(): string {
    return this.dto.name;
  }
  set name(s: string) {
    this.dto.name = s;
  }
  get jntype(): string {
    return this.dto.jntype;
  }
  get jnData(): E03DTO {
    return this.dto.jndata as E03DTO;
  }
  get jnDto(): JnDTO {
    return this.dto;
  }
  get owner(): string {
    return this.jnDto.owner;
  }
  get calcId(): string {
    let cid = "E03@";
    this.jnData.layers.forEach((lyr: LayerDTO, index: number) => {
      if (this.isLayerHidden(index)) {
        cid += "@_@_@_@";
      } else {
        const m: MaterialInfo = E03LayerOptions[index][lyr.matIndex];
        const code = m.code;
        const thk = m.thickness_options[lyr.thkIndex].val;
        const kval = m.kvalue_options[lyr.kvlIndex];
        cid += `@${code}@${thk}@${kval}@`;
      }
    });
    return cid + "@";
  }
  get laminateLayerIsOn(): boolean {
    return this.jnData.layers[1].matIndex !== 0; // 0 : Plasterboard
  }
  get blockworkIsOn(): boolean {
    return [0, 1, 2, 3, 4].includes(this.jnData.layers[5].matIndex); // Blockworks
  }
  get totalThickness(): number {
    return this.jnData.layers.reduce((x, lyrDTO, i) => {
      const m: MaterialInfo = E03LayerOptions[i][lyrDTO.matIndex];
      return this.isLayerHidden(i)
        ? x
        : x + m.thickness_options[lyrDTO.thkIndex].val;
    }, 0);
  }
  get layerHeight(): number {
    return (
      this.jnHeight + this.top - E03Bridges[0].height - this.extra1.thickness
    );
  }
  get left(): number {
    return (this.jnWidth - this.totalThickness) / 2;
  }
  get extra1Base(): Point {
    return {
      x: this.left - this.extra1.extn,
      y: this.jnHeight - this.extra1.thickness,
    } as Point;
  }
  get extra1Points(): Point[] {
    return [
      { x: 0, y: 0 } as Point, // Top-Left
      { x: this.totalThickness + this.extra1.extn * 2, y: 0 } as Point,
      {
        x: this.totalThickness + this.extra1.extn * 2,
        y: this.extra1.thickness,
      } as Point,
      {
        x: 0,
        y: this.extra1.thickness,
      } as Point,
    ];
  }
  get calcInput(): any {
    const layers = [];
    this.jnData.layers.forEach((lyr: LayerDTO, index: number) => {
      if (!this.isLayerHidden(index)) {
        const m: MaterialInfo = E03LayerOptions[index][lyr.matIndex];
        const layer: any = {
          material: m.material,
          kvalue: m.kvalue_options[lyr.kvlIndex],
          thicknessx: mmToM(m.thickness_options[lyr.thkIndex].val),
        };
        layers.push(layer);
      }
    });
    // add rse and rsi
    layers[layers.length - 1].rse = 0.04;
    layers[0].rsi = 0.13;
    // format bridges data
    // Tarik uses his predefined bridge values. Bridge is sent to server to avoid error but not used
    const bridges = [];
    this.bridges.forEach((bridge: Bridge) => {
      bridges.push({
        material: bridge.material,
        kvalue: bridge.kvalue,
        xstart: mmToM(bridge.base.x - this.left),
        xend: mmToM(bridge.base.x - this.left + bridge.width),
        ystart: mmToM(bridge.base.y - this.top),
        yend: mmToM(bridge.base.y - this.top + bridge.height),
      });
    });
    // create input
    return {
      _id: this.calcId,
      reqid: getReqId(),
      jntype: "E03",
      frame: "timber",
      rotate: 90,
      size: [600, 600],
      layers: layers.reverse(),
      bridges,
      intemp: 20,
      extemp: 0,
    };
  }
  getLayerWidth(lyrIndex: number): number {
    const layerData = this.jnData.layers[lyrIndex];
    const m: MaterialInfo = E03LayerOptions[lyrIndex][layerData.matIndex];
    return m.thickness_options[layerData.thkIndex].val;
  }
  isLayerHidden(i: number): boolean {
    return (
      (i === 0 && !this.laminateLayerIsOn) || (i === 6 && !this.blockworkIsOn)
    );
  }
  public generateShapes(): void {
    this.generateLayerParts();
    this.generateBridges();
    this.generateExtras();
  }
  public generateLayerParts(): void {
    this.layerParts = [];
    let bp = {
      x: this.left + this.totalThickness,
      y: this.top + E03Bridges[0].height,
    };
    this.jnData.layers.forEach((lyrDTO: LayerDTO, i: number) => {
      if (!this.isLayerHidden(i)) {
        const m: MaterialInfo = E03LayerOptions[i][lyrDTO.matIndex];
        const thk = m.thickness_options[lyrDTO.thkIndex].val;
        bp.x = bp.x - thk;
        const points = [
          { x: bp.x, y: bp.y } as Point,
          { x: bp.x + thk, y: bp.y } as Point,
          { x: bp.x + thk, y: this.layerHeight } as Point,
          { x: bp.x, y: this.layerHeight } as Point,
        ];
        this.layerParts.push(new PolyLayerPart(i, points, m.background));
      }
    });
  }
  private generateBridges(): void {
    this.bridges = [];
    // Bridge [1]
    const b1: BridgeDTO = E03Bridges[0];
    b1.width =
      30 +
      this.getLayerWidth(0) +
      (this.laminateLayerIsOn ? this.getLayerWidth(1) : 0) +
      this.getLayerWidth(2) +
      this.getLayerWidth(3) +
      this.getLayerWidth(4) * 0.7;
    b1.base.x =
      this.left +
      (this.blockworkIsOn ? this.getLayerWidth(6) : 0) +
      this.getLayerWidth(5) +
      this.getLayerWidth(4) * 0.3;
    b1.base.y = this.top;
    this.bridges.push(new Bridge(0, b1.background, b1));
    // Bridge [2]
    const b2: BridgeDTO = E03Bridges[1];
    b2.width = this.getLayerWidth(4);
    b2.base.x =
      this.left +
      (this.blockworkIsOn ? this.getLayerWidth(6) : 0) +
      this.getLayerWidth(5);
    b2.base.y = this.top + E03Bridges[0].height;
    this.bridges.push(new Bridge(1, b2.background, b2));
    // Bridge [3]
    const b3: BridgeDTO = E03Bridges[2];
    b3.width = this.getLayerWidth(4) - this.pvcThickness * 2;
    b3.base.x =
      this.left +
      (this.blockworkIsOn ? this.getLayerWidth(6) : 0) +
      this.getLayerWidth(5) +
      this.pvcThickness;
    b3.base.y = this.top + E03Bridges[0].height + this.pvcThickness;
    this.bridges.push(new Bridge(2, b3.background, b3));
  }
  private generateExtras(): void {
    this.extras = [];
    const e1 = {
      type: "e03-extra-01",
      base: this.extra1Base,
      points: convertPointsToString(this.extra1Points),
      background: BackGround.darkGray,
    } as ExtraDTO;
    this.extras.push(new Extra(0, e1));
  }
  public generateLabels(): void {
    this.labels = [];
    // Fixed label
    const l =
      this.left +
      (this.blockworkIsOn ? this.getLayerWidth(6) : 0) +
      this.getLayerWidth(5) +
      20;
    this.labels[1] = {
      text: "Close the cavity with insulation less than or equal to 0.026W/mK",
      start: { x: l, y: 120 } as Point,
      mid: { x: l, y: 80 } as Point,
      end: { x: this.jnWidth, y: 80 } as Point,
    } as Label;

    let start = 4;
    // layer [0]
    let i = 0;
    let m = this.getMaterial(i);
    if (m) {
      this.labels[start + i] = {
        text: m,
        start: { x: this.calculateLeft(i), y: 183 } as Point,
        mid: { x: this.calculateLeft(i), y: 183 } as Point,
        end: { x: this.jnWidth, y: 183 } as Point,
      } as Label;
    }
    // layer [1]
    i = 1;
    m = this.getMaterial(i);
    if (m) {
      this.labels[start + i] = {
        text: m,
        start: { x: this.calculateLeft(i), y: 230 } as Point,
        mid: { x: this.calculateLeft(i), y: 230 } as Point,
        end: { x: this.jnWidth, y: 230 } as Point,
      } as Label;
    }
    // layer [2]
    i = 2;
    m = this.getMaterial(i);
    if (m) {
      this.labels[start + i] = {
        text: m,
        start: { x: this.calculateLeft(i), y: 272 } as Point,
        mid: { x: this.calculateLeft(i), y: 272 } as Point,
        end: { x: this.jnWidth, y: 272 } as Point,
      } as Label;
    }
    // layer [3]
    i = 3;
    m = this.getMaterial(i);
    if (m) {
      this.labels[start + i] = {
        text: m,
        start: { x: this.calculateLeft(i), y: 315 } as Point,
        mid: { x: this.calculateLeft(i), y: 315 } as Point,
        end: { x: this.jnWidth, y: 315 } as Point,
      } as Label;
    }
    // layer [4]
    i = 4;
    m = this.getMaterial(i);
    if (m) {
      this.labels[start + i] = {
        text: m,
        start: { x: this.calculateLeft(i), y: 360 } as Point,
        mid: { x: this.calculateLeft(i), y: 360 } as Point,
        end: { x: this.jnWidth, y: 360 } as Point,
      } as Label;
    }
    // layer [5]
    i = 5;
    m = this.getMaterial(i);
    if (m) {
      this.labels[start + i] = {
        text: m,
        start: { x: this.calculateLeft(i), y: 403 } as Point,
        mid: { x: this.calculateLeft(i), y: 403 } as Point,
        end: { x: this.jnWidth, y: 403 } as Point,
      } as Label;
    }
    // layer [6]
    i = 6;
    m = this.getMaterial(i);
    if (m) {
      this.labels[start + i] = {
        text: m,
        start: { x: this.calculateLeft(i), y: 447 } as Point,
        mid: { x: this.calculateLeft(i), y: 447 } as Point,
        end: { x: this.jnWidth, y: 447 } as Point,
      } as Label;
    }
    // Fixed label
    this.labels[11] = {
      text: "Minimum frame overlap to be 30mm",
      start: { x: 450, y: 500 } as Point,
      mid: { x: 450, y: 490 } as Point,
      end: { x: this.jnWidth, y: 490 } as Point,
    } as Label;
  }
  private getMaterial(i: number): string {
    if (this.isLayerHidden(i)) return null;
    const layerDTO: LayerDTO = this.jnData.layers[i];
    const m: MaterialInfo = E03LayerOptions[i][layerDTO.matIndex];
    return m.material;
  }
  private getThickness(i: number): number {
    if (this.isLayerHidden(i)) return 0;
    const layerDTO: LayerDTO = this.jnData.layers[i];
    const m: MaterialInfo = E03LayerOptions[i][layerDTO.matIndex];
    return m.thickness_options[layerDTO.thkIndex].val;
  }
  private calculateLeft(i: number): number {
    let w = this.left;
    for (let j = 6; j > i; j--) {
      w += this.getThickness(j);
    }
    return w + this.getThickness(i) / 2;
  }
}
