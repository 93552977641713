import { createNewJnId } from "src/app/helper/app.util";
import { JnDTO } from "../common/jn.dto";
import { LayerDTO } from "../common/layer.dto";
import { E05BDTO } from "./e05b.dto";
import { E05BJunction } from "./e05b.junction.model";

export function getNewE05BJunction(): E05BJunction {
  const jnDTO = {
    _id: createNewJnId(),
    name: "E-05B Beam and Block Floor",
    jntype: "E05B",
    jndata: {
      layers: [
        {
          matIndex: 0,
          thkIndex: 0,
          kvlIndex: 0,
        } as LayerDTO,
        {
          matIndex: 0,
          thkIndex: 0,
          kvlIndex: 0,
        } as LayerDTO,
        {
          matIndex: 0,
          thkIndex: 0,
          kvlIndex: 0,
        } as LayerDTO,
        {
          matIndex: 0,
          thkIndex: 0,
          kvlIndex: 0,
        } as LayerDTO,
        {
          matIndex: 2,
          thkIndex: 2,
          kvlIndex: 0,
        } as LayerDTO,
        {
          matIndex: 5,
          thkIndex: 0,
          kvlIndex: 0,
        } as LayerDTO,
        {
          matIndex: 0,
          thkIndex: 0,
          kvlIndex: 0,
        } as LayerDTO,
      ],
    } as E05BDTO,
  } as JnDTO;
  return new E05BJunction(jnDTO);
}
