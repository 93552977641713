import { JnDTO } from "../common/jn.dto";
import { Junction } from "../common/base.junction.model";
import { E11DTO } from "./e11.dto";
import { Point } from "src/app/interfaces/point.interface";
import { PolyLayerPart } from "../common/poly.layer.part.model";
import {
  E11Bridges,
  E11FloorLayerOptions,
  E11LayerOptions,
} from "src/app/config/e11.options";
import { getReqId, mmToM } from "src/app/helper/app.util";
import { BridgeDTO } from "../common/bridge.dto";
import { Bridge } from "../common/bridge.model";
import { Label } from "src/app/interfaces/label.interface";
import { LayerDTO } from "../common/layer.dto";
import { MaterialInfo } from "src/app/interfaces/mat.info.interface";
import { BackGround, LayerOrient } from "src/app/config/app.const";

export class E11Junction extends Junction {
  lyrTop = 600;
  lyrLeft = 2;
  constructor(private dto: JnDTO) {
    super();
    this.generateShapes();
  }
  get jnWidth(): number {
    return 1000; // max possible thickness
  }
  get jnHeight(): number {
    return 1000;
  }
  get tmWidthFactor(): number {
    return 1;
  }
  get tmHeightFactor(): number {
    return 1;
  }
  get id(): string {
    return this.dto._id;
  }
  get name(): string {
    return this.dto.name;
  }
  set name(s: string) {
    this.dto.name = s;
  }
  get jntype(): string {
    return this.dto.jntype;
  }
  get jnData(): E11DTO {
    return this.dto.jndata as E11DTO;
  }
  get jnDto(): JnDTO {
    return this.dto;
  }
  get owner(): string {
    return this.jnDto.owner;
  }
  get calcId(): string {
    let cid = "E11@";
    this.jnData.layers.forEach((lyr: LayerDTO, index: number) => {
      if (this.isLayerHidden(index)) cid += "@_@_@";
      else {
        const m: MaterialInfo = E11LayerOptions[index][lyr.matIndex];
        const thk = m.thickness_options[lyr.thkIndex].val;
        const kval = m.kvalue_options[lyr.kvlIndex];
        cid += `@${thk}@${kval}@`;
      }
    });
    cid += "@@";
    this.jnData.flrLayers.forEach((lyr: LayerDTO, index: number) => {
      const m: MaterialInfo = E11FloorLayerOptions[index][lyr.matIndex];
      const thk = m.thickness_options[lyr.thkIndex].val;
      const kval = m.kvalue_options[lyr.kvlIndex];
      cid += `@${thk}@${kval}@`;
    });
    return cid + "@";
  }
  get laminateLayerIsOn(): boolean {
    return this.jnData.layers[1].matIndex !== 0; // 0 : Plasterboard
  }
  get blockworkIsOn(): boolean {
    return [0, 1, 2, 3, 4].includes(this.jnData.layers[5].matIndex); // Blockworks
  }
  private get totalThickness(): number {
    return this.jnData.layers.reduce((x, lyrDTO, i) => {
      const m: MaterialInfo = E11LayerOptions[i][lyrDTO.matIndex];
      return this.isLayerHidden(i)
        ? x
        : x + m.thickness_options[lyrDTO.thkIndex].val;
    }, 0);
  }
  private get auxLyrWidth(): number {
    return this.jnData.layers.reduce((x, lyrDTO, i) => {
      const m: MaterialInfo = E11LayerOptions[i][lyrDTO.matIndex];
      return this.isLayerHidden(i) || i < 4
        ? x
        : x + m.thickness_options[lyrDTO.thkIndex].val;
    }, 0);
  }
  get calcInput(): any {
    const layersy = [];
    this.jnData.layers.forEach((lyr: LayerDTO, index: number) => {
      if (!this.isLayerHidden(index)) {
        const m: MaterialInfo = E11LayerOptions[index][lyr.matIndex];
        const layer: any = {
          material: m.material,
          kvalue: m.kvalue_options[lyr.kvlIndex],
          thickness: mmToM(m.thickness_options[lyr.thkIndex].val),
        };
        layersy.push(layer);
      }
    });
    // add rse and rsi
    layersy[layersy.length - 1].rse = 0.04;
    layersy[0].rsi = 0.13;

    const layersx = [];
    this.jnData.flrLayers.forEach((flyr: LayerDTO, index: number) => {
      const m: MaterialInfo = E11FloorLayerOptions[index][flyr.matIndex];
      const layer: any = {
        material: m.material,
        kvalue: m.kvalue_options[flyr.kvlIndex],
        thickness: mmToM(m.thickness_options[flyr.thkIndex].val),
      };
      layersx.push(layer);
    });
    // add rse and rsi
    layersx[0].rse = 0.04;
    layersx[layersx.length - 1].rsi = 0.1;

    // format bridges data
    const bridges = [];
    // Tarik uses his predefined bridge values. Only one bridge is sent to server to avoid error
    // k = 0.12 xs= 0.3475, xe= 0.3625, ys= 1.3175, ye= 1.4175
    bridges.push({
      material: "Timber",
      kvalue: 0.12,
      xstart: 0.3475,
      xend: 0.3625,
      ystart: 1.3175,
      yend: 1.4175,
    });

    // create input
    return {
      _id: this.calcId,
      reqid: getReqId(),
      jntype: "E11",
      frame: "timber",
      rotate: 90,
      size: [600, 600],
      layersy: layersy.reverse(),
      layersx: layersx,
      bridges,
      intemp: 20,
      extemp: 0,
    };
  }
  private getLyrsTop(index: number): number {
    if ([6, 5].includes(index)) return this.lyrTop + 250;
    else if ([4, 3].includes(index)) return this.lyrTop + 50;
    else return this.lyrTop;
  }
  private getLyrThk(lyrIndex: number): number {
    const layerData = this.jnData.layers[lyrIndex];
    const m: MaterialInfo = E11LayerOptions[lyrIndex][layerData.matIndex];
    return m.thickness_options[layerData.thkIndex].val;
  }
  private isLayerHidden(i: number): boolean {
    return (
      (i === 0 && !this.laminateLayerIsOn) || (i === 6 && !this.blockworkIsOn)
    );
  }
  public generateShapes(): void {
    this.layerParts = [];
    this.generateFlrLayerParts();
    this.generateLayerParts();
    this.generateBridges();
  }
  public generateLayerParts(): void {
    // first add the aux layer
    const auxLyrPts = [
      { x: this.lyrLeft, y: this.lyrTop } as Point,
      { x: this.lyrLeft + this.auxLyrWidth, y: this.lyrTop } as Point,
      { x: this.lyrLeft + this.auxLyrWidth, y: this.lyrTop + 250 } as Point,
      { x: this.lyrLeft, y: this.lyrTop + 250 } as Point,
    ];
    this.layerParts.push(
      new PolyLayerPart(
        999,
        auxLyrPts,
        BackGround.insulation_044,
        LayerOrient.Y
      )
    );
    let bpX = this.totalThickness + this.lyrLeft;
    this.jnData.layers.forEach((lyrDTO: LayerDTO, i: number) => {
      if (!this.isLayerHidden(i)) {
        const m: MaterialInfo = E11LayerOptions[i][lyrDTO.matIndex];
        const thk = m.thickness_options[lyrDTO.thkIndex].val;
        bpX = bpX - thk;
        let bpY = this.getLyrsTop(i);
        const points = [
          { x: bpX, y: bpY } as Point,
          { x: bpX + thk, y: bpY } as Point,
          { x: bpX + thk, y: this.jnHeight - 1 } as Point,
          { x: bpX, y: this.jnHeight - 1 } as Point,
        ];
        this.layerParts.push(
          new PolyLayerPart(i, points, m.background, LayerOrient.Y)
        );
      }
    });
  }
  private generateFlrLayerParts(): void {
    let [bpX, bpY] = [this.lyrLeft, this.lyrTop];
    this.jnData.flrLayers.forEach((lyrDTO: LayerDTO, i: number) => {
      const m: MaterialInfo = E11FloorLayerOptions[i][lyrDTO.matIndex];
      const thk = m.thickness_options[lyrDTO.thkIndex].val;
      const actualX = Math.floor(Math.sqrt(2) * thk);
      const points = [
        { x: bpX, y: bpY } as Point,
        { x: bpX + 1000, y: -125 } as Point,
        { x: bpX + 1000 + actualX, y: -125 } as Point,
        { x: bpX + actualX, y: bpY } as Point,
      ];
      this.layerParts.push(
        new PolyLayerPart(i, points, m.background, LayerOrient.X)
      );
      bpX = bpX + actualX;
    });
  }
  private generateBridges(): void {
    this.bridges = [];
    // Bridge [1]
    const b1: BridgeDTO = E11Bridges[0];
    [b1.base.x, b1.base.y] = [this.lyrLeft + this.auxLyrWidth, this.lyrTop];
    b1.width = this.getLyrThk(3);
    this.bridges.push(new Bridge(0, b1.background, b1));
  }
  public generateLabels(): void {
    this.labels = [];
    // Fixed label
    const flyr2: LayerDTO = this.jnData.flrLayers[2];
    const mx2: MaterialInfo = E11FloorLayerOptions[2][flyr2.matIndex];
    const thkx2 = mx2.thickness_options[flyr2.thkIndex].val;
    const thkT = Math.floor(Math.sqrt(2) * thkx2);
    this.labels[1] = {
      text: `${thkx2}mm insulation k=0.035 between rafters`,
      start: { x: 825, y: 100 } as Point,
      mid: { x: 825, y: 100 } as Point,
      end: { x: this.jnWidth, y: 100 } as Point,
    } as Label;
    // Fixed label
    const flyr3: LayerDTO = this.jnData.flrLayers[3];
    const mx3: MaterialInfo = E11FloorLayerOptions[3][flyr3.matIndex];
    const thkx3 = mx3.thickness_options[flyr3.thkIndex].val;
    this.labels[3] = {
      text: `${thkx3}mm insulation k=0.022 below rafter`,
      start: { x: 590 + thkT, y: 260 } as Point,
      mid: { x: 590 + thkT, y: 260 } as Point,
      end: { x: this.jnWidth, y: 260 } as Point,
    } as Label;
    // Fixed label
    this.labels[5] = {
      text: "Mineral wool k=0.035",
      start: { x: 150, y: 625 } as Point,
      mid: { x: 850, y: 420 } as Point,
      end: { x: this.jnWidth, y: 420 } as Point,
    } as Label;

    let start = 6;
    // layer [0]
    let i = 0;
    let m = this.getMaterial(i);
    if (m) {
      this.labels[start + i] = {
        text: m,
        start: { x: this.calculateLeft(i), y: 605 } as Point,
        mid: { x: 850, y: 500 } as Point,
        end: { x: this.jnWidth, y: 500 } as Point,
      } as Label;
    }
    // layer [1]
    i = 1;
    m = this.getMaterial(i);
    if (m) {
      this.labels[start + i] = {
        text: m,
        start: { x: this.calculateLeft(i), y: 630 } as Point,
        mid: { x: 850, y: 578 } as Point,
        end: { x: this.jnWidth, y: 578 } as Point,
      } as Label;
    }
    // layer [2]
    i = 2;
    m = this.getMaterial(i);
    if (m) {
      this.labels[start + i] = {
        text: m,
        start: { x: this.calculateLeft(i), y: 655 } as Point,
        mid: { x: this.calculateLeft(i), y: 655 } as Point,
        end: { x: this.jnWidth, y: 655 } as Point,
      } as Label;
    }
    // layer [3]
    i = 3;
    m = this.getMaterial(i);
    if (m) {
      this.labels[start + i] = {
        text: m,
        start: { x: this.calculateLeft(i), y: 737 } as Point,
        mid: { x: this.calculateLeft(i), y: 737 } as Point,
        end: { x: this.jnWidth, y: 737 } as Point,
      } as Label;
    }
    // layer [4]
    i = 4;
    m = this.getMaterial(i);
    if (m) {
      this.labels[start + i] = {
        text: m,
        start: { x: this.calculateLeft(i), y: 818 } as Point,
        mid: { x: this.calculateLeft(i), y: 818 } as Point,
        end: { x: this.jnWidth, y: 818 } as Point,
      } as Label;
    }
    // layer [5]
    i = 5;
    m = this.getMaterial(i);
    if (m) {
      this.labels[start + i] = {
        text: m,
        start: { x: this.calculateLeft(i), y: 895 } as Point,
        mid: { x: this.calculateLeft(i), y: 895 } as Point,
        end: { x: this.jnWidth, y: 895 } as Point,
      } as Label;
    }
    // layer [6]
    i = 6;
    m = this.getMaterial(i);
    if (m) {
      this.labels[start + i] = {
        text: m,
        start: { x: this.calculateLeft(i), y: 973 } as Point,
        mid: { x: this.calculateLeft(i), y: 973 } as Point,
        end: { x: this.jnWidth, y: 973 } as Point,
      } as Label;
    }
  }
  private getMaterial(i: number): string {
    if (this.isLayerHidden(i)) return null;
    const layerDTO: LayerDTO = this.jnData.layers[i];
    const m: MaterialInfo = E11LayerOptions[i][layerDTO.matIndex];
    return m.material;
  }
  private getThickness(i: number): number {
    if (this.isLayerHidden(i)) return 0;
    const layerDTO: LayerDTO = this.jnData.layers[i];
    const m: MaterialInfo = E11LayerOptions[i][layerDTO.matIndex];
    return m.thickness_options[layerDTO.thkIndex].val;
  }
  private calculateLeft(i: number): number {
    let w = 0;
    for (let j = 6; j > i; j--) {
      w += this.getThickness(j);
    }
    return w + this.getThickness(i) / 2;
  }
}
