export const junction_list = [
  {
    name: "Ceiling",
    imageURL: "../../../assets/images/ceiling.jpg",
    color: "var(--color-blue)",
    junctions: [
      { title: "E10 Eaves Insulation ceiling", code: "E10" },
      { title: "E11 Eaves Rafter", code: "E11" },
      { title: "E12 Gable Ceiling", code: "E12" },
      { title: "E13 Gable Rafter", code: "E13" },
    ],
  },
  {
    name: "Walls",
    imageURL: "../../../assets/images/walls.png",
    color: "var(--color-yellow)",
    junctions: [
      { title: "E1 - Lintel", code: "E01" },
      { title: "E2A - Lintel", code: "E02A" },
      { title: "E16 Corner - normal", code: "E16" },
      { title: "E17 Inverted Corner - normal", code: "E17" },
    ],
  },
  {
    name: "Windows",
    imageURL: "../../../assets/images/windows.png",
    color: "var(--color-navy)",
    junctions: [
      { title: "E1 - Lintel", code: "E01" },
      { title: "E2A - Lintel", code: "E02A" },
      { title: "E3 Window Sill", code: "E03" },
      { title: "E4 Window Jamb", code: "E04" },
    ],
  },
  {
    name: "Floor",
    imageURL: "../../../assets/images/floor.png",
    color: "var(--color-green)",
    junctions: [
      { title: "E5A Slab on Ground Floor", code: "E05A" },
      { title: "E5B Beam and block Floor", code: "E05B" },
      { title: "E06 Interim Floor", code: "E06" },
      { title: "E07 Sep Floor", code: "E07" },
    ],
  },
];
