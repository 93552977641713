import {
  E06FloorLayerOptions,
  E06LayerOptions,
} from "src/app/config/e06.options";
import { BridgeDTO } from "src/app/junctions/common/bridge.dto";
import { Bridge } from "src/app/junctions/common/bridge.model";
import { JnDTO } from "src/app/junctions/common/jn.dto";
import { LayerDTO } from "src/app/junctions/common/layer.dto";
import { E06DTO } from "src/app/junctions/e06/e06.dto";
import { E06Junction } from "src/app/junctions/e06/e06.junction.model";
import * as template from "src/app/junctions/e06/e06.templates";

export function genE06Inputs(
  start: number,
  count: number
): { opts: any; total: number } {
  const output: any = {};
  const allIds: Set<string> = new Set<string>();
  let n = 0;
  const templateBridges: BridgeDTO[] = getTemplateBridges();
  createLayerOptions(0).forEach((lyr0) => {
    createLayerOptions(1).forEach((lyr1) => {
      createLayerOptions(2).forEach((lyr2) => {
        createLayerOptions(3).forEach((lyr3) => {
          createLayerOptions(4).forEach((lyr4) => {
            createLayerOptions(5).forEach((lyr5) => {
              createLayerOptions(6).forEach((lyr6) => {
                createFlrLayerOptions(0).forEach((flyr0) => {
                  createFlrLayerOptions(1).forEach((flyr1) => {
                    createFlrLayerOptions(2).forEach((flyr2) => {
                      const e06: E06Junction = createNewE06Junction(
                        [lyr0, lyr1, lyr2, lyr3, lyr4, lyr5, lyr6],
                        [flyr0, flyr1, flyr2],
                        templateBridges
                      );
                      if (
                        n >= start &&
                        n < start + count &&
                        !allIds.has(e06.calcId)
                      ) {
                        output[e06.calcId] = e06.calcInput;
                      }
                      allIds.add(e06.calcId);
                      n++;
                    });
                  });
                });
              });
            });
          });
        });
      });
    });
  });
  console.log(`Input generated: start: ${start}, end: ${start + count - 1}`);
  console.log(`count: ${Object.keys(output).length}, total: ${allIds.size}`);
  return { opts: output, total: allIds.size };
}
function createLayerOptions(lyrIndex: number): LayerDTO[] {
  const lyrOpts = [];
  E06LayerOptions[lyrIndex].forEach((matOpt, i) => {
    const matIndex = i;
    matOpt.kvalue_options.forEach((kvalOpt, j) => {
      const kvlIndex = j;
      matOpt.thickness_options.forEach((thkOpt, k) => {
        const thkIndex = k;
        lyrOpts.push({ matIndex, thkIndex, kvlIndex } as LayerDTO);
      });
    });
  });
  return lyrOpts;
}
function createFlrLayerOptions(lyrIndex: number): LayerDTO[] {
  const lyrOpts = [];
  E06FloorLayerOptions[lyrIndex].forEach((matOpt, i) => {
    const matIndex = i;
    matOpt.kvalue_options.forEach((kvalOpt, j) => {
      const kvlIndex = j;
      matOpt.thickness_options.forEach((thkOpt, k) => {
        const thkIndex = k;
        lyrOpts.push({ matIndex, thkIndex, kvlIndex } as LayerDTO);
      });
    });
  });
  return lyrOpts;
}
function createNewE06Junction(
  layers: LayerDTO[],
  flrLayers: LayerDTO[],
  bridges: BridgeDTO[]
): E06Junction {
  const jnDTO = {
    _id: "dummy",
    name: "E06 (dummy)",
    jntype: "E06",
    jndata: { layers, flrLayers, bridges, extras: [] } as E06DTO,
  } as JnDTO;
  return new E06Junction(jnDTO);
}
function getTemplateBridges(): BridgeDTO[] {
  const bdgDTOs: BridgeDTO[] = [];
  const bgs: Bridge[] = template.getNewE06Junction().bridges;
  bgs.forEach((b) => bdgDTOs.push(b.dto_copy));
  return bdgDTOs;
}
