import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { LoginComponent } from "./components/authentication/login/login.component";
import { PasswordResetComponent } from "./components/authentication/password-reset/password-reset.component";
import { RegisterComponent } from "./components/authentication/register/register.component";
import { CalculatorComponent } from "./components/calculator/calculator.component";
import { HomeComponent } from "./components/home/home.component";
import { WelcomeComponent } from "./components/welcome/welcome.component";
import { InputGeneratorComponent } from "./components/input-generator/input-generator.component";
import { AuthGuard } from "./guards/auth.guard";
import { SavedJunctionsComponent } from "./components/saved-junctions/saved-junctions.component";
import { AnalyticsPanelComponent } from "./components/admin/analytics-panel/analytics-panel.component";
import { IsAdminGuard } from "./guards/isAdmin.guard";

const routes: Routes = [
  { path: "", redirectTo: "home", pathMatch: "full" },
  {
    path: "login",
    component: LoginComponent,
    canActivate: [AuthGuard],
  },
  { path: "analytics", component: AnalyticsPanelComponent, canActivate: [IsAdminGuard] },
  { path: "register", component: RegisterComponent, canActivate: [AuthGuard] },
  { path: "resetPassword", component: PasswordResetComponent },
  { path: "calculator", component: CalculatorComponent },
  { path: "home", component: WelcomeComponent },
  { path: "select", component: HomeComponent },
  { path: "saved-junctions", component: SavedJunctionsComponent },
  { path: "calc-input-generator", component: InputGeneratorComponent },
  { path: "**", redirectTo: "home", pathMatch: "full" },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule { }
