import { Component, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { globals } from "src/app/store/global.store";
import { junction_list } from "../../junctions/junction.list";

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.css"],
})
export class HomeComponent implements OnInit {
  public tabsActive = [false, false, false, false];
  public elements = junction_list;
  public displayedJunctions = [];
  public currentColor = "";

  constructor(private title: Title) {}
  ngOnInit() {
    this.title.setTitle(
      "Junction Selection | Thermal Modelling Calculator | PSI Values | Knauf Insulation"
    );
  }

  toggleClass(tabIndex: number) {
    for (let i = 0; i < this.tabsActive.length; i++) {
      this.tabsActive[i] = tabIndex === i;
      if (tabIndex === i) {
        this.tabsActive[i] = true;
      }
    }
  }

  getSubCat(type: string) {
    const findings = junction_list.filter((item) => item.name === type);
    if (findings.length > 0) {
      this.displayedJunctions = findings[0].junctions;
      this.currentColor = findings[0].color;
    }
    globals.selectedFolder = type;
  }
}
