import { BridgeDTO } from "../common/bridge.dto";
import { Bridge } from "../common/bridge.model";
import { JnDTO } from "../common/jn.dto";
import { Junction } from "../common/base.junction.model";
import { E05BDTO } from "./e05b.dto";
import { Point } from "src/app/interfaces/point.interface";
import { PolyLayerPart } from "../common/poly.layer.part.model";
import {
  E05BBridges,
  E05BFloorLayers,
  E05BGround,
  E05BGroundLayers,
  E05BLayerOptions,
} from "src/app/config/e05b.options";
import {
  convertPointsToString,
  getReqId,
  mmToM,
} from "src/app/helper/app.util";

import { ExtraDTO } from "../common/extra.dto";
import { Extra } from "../common/extra.model";
import { BackGround } from "src/app/config/app.const";
import { GroundDTO } from "../common/ground.dto";
import { Ground } from "../common/ground.model";
import { Label } from "src/app/interfaces/label.interface";
import { LayerDTO } from "../common/layer.dto";
import { MaterialInfo } from "src/app/interfaces/mat.info.interface";

export class E05BJunction extends Junction {
  extra1 = { height: 250, out: 200 };
  left = 500;
  floorTop = 725;
  spaceForLabel = 150;
  constructor(private dto: JnDTO) {
    super();
    this.generateShapes();
  }
  get jnWidth(): number {
    return 2000;
  }
  get jnHeight(): number {
    return 2000;
  }
  get tmWidthFactor(): number {
    return 1;
  }
  get tmHeightFactor(): number {
    return 1;
  }
  get id(): string {
    return this.dto._id;
  }
  get name(): string {
    return this.dto.name;
  }
  set name(s: string) {
    this.dto.name = s;
  }
  get jntype(): string {
    return this.dto.jntype;
  }
  get jnData(): E05BDTO {
    return this.dto.jndata as E05BDTO;
  }
  get jnDto(): JnDTO {
    return this.dto;
  }
  get owner(): string {
    return this.jnDto.owner;
  }
  get calcId(): string {
    let cid = "E05B@";
    this.jnData.layers.forEach((lyr: LayerDTO, index: number) => {
      if (this.isLayerHidden(index)) {
        cid += "@_@_@_@";
      } else {
        const m: MaterialInfo = E05BLayerOptions[index][lyr.matIndex];
        const code = m.code;
        const thk = m.thickness_options[lyr.thkIndex].val;
        const kval = m.kvalue_options[lyr.kvlIndex];
        cid += `@${code}@${thk}@${kval}@`;
      }
    });
    return cid + "@";
  }
  get laminateLayerIsOn(): boolean {
    return this.jnData.layers[1].matIndex !== 0; // 0 : Plasterboard
  }
  get blockworkIsOn(): boolean {
    return [0, 1, 2, 3, 4].includes(this.jnData.layers[5].matIndex); // Blockworks
  }
  get totalThickness(): number {
    return this.jnData.layers.reduce((x, lyrDTO, i) => {
      const m: MaterialInfo = E05BLayerOptions[i][lyrDTO.matIndex];
      return this.isLayerHidden(i)
        ? x
        : x + m.thickness_options[lyrDTO.thkIndex].val;
    }, 0);
  }

  get calcInput(): any {
    const layers = [];
    // Wall layers
    this.jnData.layers.forEach((lyr: LayerDTO, index: number) => {
      if (!this.isLayerHidden(index)) {
        const m: MaterialInfo = E05BLayerOptions[index][lyr.matIndex];
        const layer: any = {
          material: m.material,
          kvalue: m.kvalue_options[lyr.kvlIndex],
          thicknessx: mmToM(m.thickness_options[lyr.thkIndex].val),
        };
        layers.push(layer);
      }
    });
    // add rse and rsi
    layers[layers.length - 1].rse = 0.04;
    layers[0].rsi = 0.13;
    // format bridges data
    // Tarik uses his predefined bridge values. Bridge is sent to server to avoid error but not used
    const bridges = [];
    // create input
    return {
      _id: this.calcId,
      reqid: getReqId(),
      jntype: "E05B",
      frame: "timber",
      rotate: 90,
      size: [600, 600],
      layersx: [],
      layersy: layers.reverse(),
      layersg: [],
      bridges,
      intemp: 20,
      extemp: 0,
    };
  }
  get extra1Base(): Point {
    return {
      x: this.left - this.extra1.out,
      y: this.getLayerHeight(3),
    } as Point;
  }
  get extra1Points(): Point[] {
    const width =
      (this.blockworkIsOn ? this.getLayerWidth(6) : 0) +
      this.getLayerWidth(5) +
      this.getLayerWidth(4) +
      this.getLayerWidth(3) +
      2 * this.extra1.out;
    return [
      { x: 0, y: 0 } as Point, // Top-Left
      { x: width, y: 0 } as Point,
      { x: width, y: this.extra1.height } as Point,
      { x: 0, y: this.extra1.height } as Point,
    ];
  }
  getLayerHeight(lyrIndex: number): number {
    if ([6, 5, 4].includes(lyrIndex)) return 900;
    if (lyrIndex === 3) return 1550;
    if ([2, 1, 0].includes(lyrIndex)) return 800;
  }
  getLayerWidth(lyrIndex: number): number {
    const layerData = this.jnData.layers[lyrIndex];
    const m: MaterialInfo = E05BLayerOptions[lyrIndex][layerData.matIndex];
    return m.thickness_options[layerData.thkIndex].val;
  }
  isLayerHidden(i: number): boolean {
    return (
      (i === 0 && !this.laminateLayerIsOn) || (i === 6 && !this.blockworkIsOn)
    );
  }
  public generateShapes(): void {
    this.generateLayerParts();
    this.generateBridges();
    this.generateExtras();
    this.generateGrounds();
  }
  public generateLayerParts(): void {
    let points: Point[];
    let thk: number;
    let lyr: any;
    this.layerParts = [];
    // Wall layers (dynamic)
    let bp = this.left + this.totalThickness;
    this.jnData.layers.forEach((lyrDTO: LayerDTO, i: number) => {
      if (!this.isLayerHidden(i)) {
        const m: MaterialInfo = E05BLayerOptions[i][lyrDTO.matIndex];
        const thk = m.thickness_options[lyrDTO.thkIndex].val;
        bp = bp - thk;
        const h = this.getLayerHeight(i);
        points = [
          { x: bp, y: 0 } as Point,
          { x: bp + thk, y: 0 } as Point,
          { x: bp + thk, y: h } as Point,
          { x: bp, y: h } as Point,
        ];
        this.layerParts.push(new PolyLayerPart(i, points, m.background));
      }
    });
    // Floor layers
    let index = this.jnData.layers.length;
    let floorLeft = this.left;
    this.jnData.layers.forEach((lyrDTO: LayerDTO, i: number) => {
      if (!this.isLayerHidden(i) && i > 2) {
        const m: MaterialInfo = E05BLayerOptions[i][lyrDTO.matIndex];
        floorLeft += m.thickness_options[lyrDTO.thkIndex].val;
      }
    });
    bp = this.floorTop;
    // floor layer 0
    lyr = E05BFloorLayers[0];
    thk = lyr.thickness;
    points = [
      { x: floorLeft, y: bp } as Point,
      { x: this.jnWidth - this.spaceForLabel, y: bp } as Point,
      { x: this.jnWidth - this.spaceForLabel, y: bp + thk } as Point,
      { x: floorLeft, y: bp + thk } as Point,
    ];
    this.layerParts.push(new PolyLayerPart(index, points, lyr.background));
    bp += thk;
    ++index;
    // floor layer 1
    lyr = E05BFloorLayers[1];
    thk = lyr.thickness;
    points = [
      { x: floorLeft, y: bp } as Point,
      { x: this.jnWidth - this.spaceForLabel, y: bp } as Point,
      { x: this.jnWidth - this.spaceForLabel, y: bp + thk } as Point,
      { x: floorLeft, y: bp + thk } as Point,
    ];
    this.layerParts.push(new PolyLayerPart(index, points, lyr.background));
    bp += thk;
    ++index;
    // floor layer 2
    lyr = E05BFloorLayers[2];
    thk = lyr.thickness;
    points = [
      { x: floorLeft, y: bp } as Point,
      { x: floorLeft + 120, y: bp } as Point,
      { x: floorLeft + 120, y: bp + thk } as Point,
      { x: floorLeft, y: bp + thk } as Point,
    ];
    this.layerParts.push(new PolyLayerPart(index, points, lyr.background));
    ++index;
    // floor layer 3
    lyr = E05BFloorLayers[3];
    thk = lyr.thickness;
    const lyrDTO: LayerDTO = this.jnData.layers[3];
    const m: MaterialInfo = E05BLayerOptions[3][lyrDTO.matIndex];
    const lft = m.thickness_options[lyrDTO.thkIndex].val;
    points = [
      { x: floorLeft - lft, y: bp } as Point,
      { x: floorLeft + 20, y: bp } as Point,
      { x: floorLeft + 20, y: bp + thk } as Point,
      { x: floorLeft - lft, y: bp + thk } as Point,
    ];
    this.layerParts.push(new PolyLayerPart(index, points, lyr.background));
    ++index;
    // floor layer 4
    lyr = E05BFloorLayers[4];
    thk = lyr.thickness;
    points = [
      { x: floorLeft + 100, y: bp } as Point,
      { x: this.jnWidth - this.spaceForLabel, y: bp } as Point,
      { x: this.jnWidth - this.spaceForLabel, y: bp + thk } as Point,
      { x: floorLeft + 100, y: bp + thk } as Point,
    ];
    this.layerParts.push(new PolyLayerPart(index, points, lyr.background));
    ++index;

    // Ground layer [1]
    let layer = E05BGroundLayers[0];
    bp = this.getLayerHeight(6);
    const g1Width =
      (this.blockworkIsOn ? this.getLayerWidth(6) : 0) + this.getLayerWidth(5);
    points = [
      { x: this.left, y: bp } as Point,
      { x: this.left + g1Width, y: bp } as Point,
      { x: this.left + g1Width, y: bp + layer.height } as Point,
      { x: this.left, y: bp + layer.height } as Point,
    ];
    this.layerParts.push(new PolyLayerPart(index, points, layer.background));
    ++index;
    // Ground layer [2]
    layer = E05BGroundLayers[1];
    bp = this.getLayerHeight(4);
    const width = this.getLayerWidth(4);
    points = [
      { x: this.left + g1Width, y: bp } as Point,
      { x: this.left + g1Width + width, y: bp } as Point,
      { x: this.left + g1Width + width, y: bp + layer.height } as Point,
      { x: this.left + g1Width, y: bp + layer.height } as Point,
    ];
    this.layerParts.push(new PolyLayerPart(index, points, layer.background));
    ++index;
    // Ground layer [3]
    layer = E05BGroundLayers[2];
    bp = this.getLayerHeight(4) + E05BGroundLayers[1].height;
    points = [
      { x: this.left + g1Width, y: bp } as Point,
      { x: this.left + g1Width + width, y: bp } as Point,
      { x: this.left + g1Width + width, y: bp + layer.height } as Point,
      { x: this.left + g1Width, y: bp + layer.height } as Point,
    ];
    this.layerParts.push(new PolyLayerPart(index, points, layer.background));
    ++index;
    // Ground layer [4]
    layer = E05BGroundLayers[3];
    bp =
      this.getLayerHeight(4) +
      E05BGroundLayers[1].height +
      E05BGroundLayers[2].height;
    points = [
      { x: this.left + g1Width, y: bp } as Point,
      { x: this.left + g1Width + width, y: bp } as Point,
      { x: this.left + g1Width + width, y: bp + layer.height } as Point,
      { x: this.left + g1Width, y: bp + layer.height } as Point,
    ];
    this.layerParts.push(new PolyLayerPart(index, points, layer.background));
    ++index;
  }
  private generateBridges(): void {
    this.bridges = [];
    // Bridge [1]
    const b1: BridgeDTO = E05BBridges[0];
    b1.base.x = this.left + this.totalThickness;
    b1.base.y = this.floorTop - b1.height;
    this.bridges.push(new Bridge(0, b1.background, b1));
    // // Bridge [2]
    const b2: BridgeDTO = E05BBridges[1];
    b2.height = E05BFloorLayers[0].thickness;
    b2.base.x =
      this.left +
      (this.blockworkIsOn ? this.getLayerWidth(6) : 0) +
      this.getLayerWidth(5) +
      this.getLayerWidth(4) +
      this.getLayerWidth(3);
    b2.base.y = this.floorTop;
    this.bridges.push(new Bridge(1, b2.background, b2));
  }
  private generateExtras(): void {
    this.extras = [];
    const e1 = {
      type: "e05b-extra-01",
      base: this.extra1Base,
      points: convertPointsToString(this.extra1Points),
      background: BackGround.concrete_k2,
    } as ExtraDTO;
    this.extras.push(new Extra(0, e1));
  }
  private generateGrounds(): void {
    this.grounds = [];
    E05BGround.forEach((ground: GroundDTO, i: number) => {
      this.grounds.push(new Ground(i, ground, "E05B"));
    });
  }
  public generateLabels(): void {
    this.labels = [];
    let start = 0;
    // layer [0]
    let i = 0;
    let m = this.getMaterial(i);
    if (m) {
      this.labels[start + i] = {
        text: m,
        start: { x: this.calculateLeft(i), y: 15 } as Point,
        mid: { x: this.calculateLeft(i), y: 15 } as Point,
        end: { x: this.jnWidth, y: 15 } as Point,
      } as Label;
    }
    // layer [1]
    i = 1;
    m = this.getMaterial(i);
    if (m) {
      this.labels[start + i] = {
        text: m,
        start: { x: this.calculateLeft(i), y: 200 } as Point,
        mid: { x: this.calculateLeft(i), y: 200 } as Point,
        end: { x: this.jnWidth, y: 200 } as Point,
      } as Label;
    }
    // layer [2]
    i = 2;
    m = this.getMaterial(i);
    if (m) {
      this.labels[start + i] = {
        text: m,
        start: { x: this.calculateLeft(i), y: 340 } as Point,
        mid: { x: this.calculateLeft(i), y: 340 } as Point,
        end: { x: this.jnWidth, y: 340 } as Point,
      } as Label;
    }
    // layer [3]
    i = 3;
    m = this.getMaterial(i);
    if (m) {
      this.labels[start + i] = {
        text: m,
        start: { x: this.calculateLeft(i), y: 500 } as Point,
        mid: { x: this.calculateLeft(i), y: 500 } as Point,
        end: { x: this.jnWidth, y: 500 } as Point,
      } as Label;
    }
    // layer [4]
    i = 4;
    m = this.getMaterial(i);
    if (m) {
      this.labels[start + i] = {
        text: m,
        start: { x: this.calculateLeft(i) + 20, y: 850 } as Point,
        mid: { x: this.calculateLeft(i) + 20, y: 1010 } as Point,
        end: { x: this.jnWidth, y: 1010 } as Point,
      } as Label;
    }
    // layer [5]
    i = 5;
    m = this.getMaterial(i);
    if (m) {
      this.labels[start + i] = {
        text: m,
        start: { x: this.calculateLeft(i), y: 820 } as Point,
        mid: { x: this.calculateLeft(i), y: 820 } as Point,
        end: { x: this.jnWidth, y: 820 } as Point,
      } as Label;
    }
    // layer [6]
    i = 6;
    m = this.getMaterial(i);
    if (m) {
      this.labels[start + i] = {
        text: m,
        start: { x: this.calculateLeft(i), y: 670 } as Point,
        mid: { x: this.calculateLeft(i), y: 670 } as Point,
        end: { x: this.jnWidth, y: 670 } as Point,
      } as Label;
    }
    // Special manipulation: swap places 4 and 6 to handle the line display properly
    [this.labels[4], this.labels[6]] = [this.labels[6], this.labels[4]];
    // Fixed label
    this.labels[7] = {
      text: "Ensure floor insulation is lightly butted against the external wall",
      start: { x: this.calculateLeft(2), y: 850 } as Point,
      mid: { x: 1200, y: 1200 } as Point,
      end: { x: this.jnWidth, y: 1200 } as Point,
    } as Label;
    // Fixed label
    this.labels[9] = {
      text: "100mm floor insulation with thermal conductivity of 0.022W/mK",
      start: { x: 1500, y: 850 } as Point,
      mid: { x: 1500, y: 1500 } as Point,
      end: { x: this.jnWidth, y: 1500 } as Point,
    } as Label;
    // Fixed label
    this.labels[11] = {
      text: "Continue cavity insulation at least 225mm below the top of the concrete",
      start: { x: this.calculateLeft(4), y: 1050 } as Point,
      mid: { x: this.calculateLeft(4), y: 1775 } as Point,
      end: { x: this.jnWidth, y: 1775 } as Point,
    } as Label;
  }
  private getMaterial(i: number): string {
    if (this.isLayerHidden(i)) return null;
    const layerDTO: LayerDTO = this.jnData.layers[i];
    const m: MaterialInfo = E05BLayerOptions[i][layerDTO.matIndex];
    return m.material;
  }
  private getThickness(i: number): number {
    if (this.isLayerHidden(i)) return 0;
    const layerDTO: LayerDTO = this.jnData.layers[i];
    const m: MaterialInfo = E05BLayerOptions[i][layerDTO.matIndex];
    return m.thickness_options[layerDTO.thkIndex].val;
  }
  private calculateLeft(i: number): number {
    let w = this.left;
    for (let j = 6; j > i; j--) {
      w += this.getThickness(j);
    }
    return w + this.getThickness(i) / 2;
  }
}
