import { E16LayerOptions } from "src/app/config/e16.options";
import { JnDTO } from "src/app/junctions/common/jn.dto";
import { LayerDTO } from "src/app/junctions/common/layer.dto";
import { E16DTO } from "src/app/junctions/e16/e16.dto";
import { E16Junction } from "src/app/junctions/e16/e16.junction.model";

export function getAllPossibleE16Inputs(): any {
  const output = {};
  createLayerOptions(0).forEach((lyr0) => {
    createLayerOptions(1).forEach((lyr1) => {
      createLayerOptions(2).forEach((lyr2) => {
        createLayerOptions(3).forEach((lyr3) => {
          createLayerOptions(4).forEach((lyr4) => {
            createLayerOptions(5).forEach((lyr5) => {
              createLayerOptions(6).forEach((lyr6) => {
                const e16: E16Junction = createNewE16Junction(
                  lyr0,
                  lyr1,
                  lyr2,
                  lyr3,
                  lyr4,
                  lyr5,
                  lyr6
                );
                output[e16.calcId] = e16.calcInput;
              });
            });
          });
        });
      });
    });
  });
  return output;
}
function createLayerOptions(lyrIndex: number): LayerDTO[] {
  const lyrOpts = [];
  E16LayerOptions[lyrIndex].forEach((matOpt, i) => {
    const matIndex = i;
    matOpt.kvalue_options.forEach((kvalOpt, j) => {
      const kvlIndex = j;
      matOpt.thickness_options.forEach((thkOpt, k) => {
        const thkIndex = k;
        lyrOpts.push({ matIndex, thkIndex, kvlIndex } as LayerDTO);
      });
    });
  });
  return lyrOpts;
}
function createNewE16Junction(
  lyr0: LayerDTO,
  lyr1: LayerDTO,
  lyr2: LayerDTO,
  lyr3: LayerDTO,
  lyr4: LayerDTO,
  lyr5: LayerDTO,
  lyr6: LayerDTO
): E16Junction {
  const jnDTO = {
    _id: "dummy",
    name: "E16 (dummy)",
    jntype: "E16",
    jndata: {
      layers: [lyr0, lyr1, lyr2, lyr3, lyr4, lyr5, lyr6],
      bridges: [],
      extras: [],
    } as E16DTO,
  } as JnDTO;
  return new E16Junction(jnDTO);
}
