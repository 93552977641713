import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { User } from "src/app/interfaces/user/user.interface";
import { AuthenticationService } from "src/app/services/authentication/authentication.service";
import { JunctionService } from "src/app/services/junction.service";
import { AnalyticsPanelComponent } from "../admin/analytics-panel/analytics-panel.component";

@Component({
  selector: "app-saved-junctions",
  templateUrl: "./saved-junctions.component.html",
  styleUrls: ["./saved-junctions.component.css"],
})
export class SavedJunctionsComponent implements OnInit {
  savedJns = [];
  displayedColumns: string[] = [
    "position",
    "name",
    "type",
    "createdOn",
    "lastSaved",
    "actions",
  ];

  constructor(
    private jnService: JunctionService,
    public authService: AuthenticationService,
    private router: Router,
    public dialog: MatDialog,
  ) {
    this.authService.getCurrentUser$().subscribe((user: User) => {
      if (user) {
        this.fetchSavedJunctions();
      }
    });
  }

  ngOnInit(): void { }
  async fetchSavedJunctions(): Promise<void> {
    this.savedJns = await this.jnService.fetchSavedJunctions();
  }
  openJunction(jnId: string): void {
    this.router.navigate(["calculator"], {
      queryParams: { id: jnId },
    });
  }
  async deleteJunction(jnId: string): Promise<void> {
    try {
      await this.jnService.deleteJn(jnId);
      this.savedJns = await this.jnService.fetchSavedJunctions();
    } catch (er) {
      console.log(er);
    }
  }

  // Open Dialog box to request
  // the number of calculations in specific time frame
  openAnalyticsModal(): void {
    this.dialog.open(
      AnalyticsPanelComponent, { width: '100%', });
  }
}
