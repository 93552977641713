import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { CalcOutputDTO } from "../interfaces/dto/calc.output.dto";
import { savedTmData } from "../store/global.store";

@Injectable({
  providedIn: "root",
})
export class CalcService {
  constructor(private http: HttpClient) {}
  public async fetchCalcData(calcId: string): Promise<CalcOutputDTO> {
    if (savedTmData[calcId]) {
      console.log(`data for junction spec [${calcId}] available locally`);
      return savedTmData[calcId];
    } else {
      console.log(`fetching data for junction spec [${calcId}] from server`);
      const calcData = await this.fetchCalcDataFromServer(calcId);
      if (!calcData) return undefined;
      savedTmData[calcId] = calcData;
      return calcData;
    }
  }
  private fetchCalcDataFromServer(calcId: string): Promise<CalcOutputDTO> {
    const jnGetUrl = `${environment.calcDataUrl}/updated/${calcId}.json`;
    const headers = { "Content-Type": "application/json" };
    return this.http.get<CalcOutputDTO>(jnGetUrl, { headers }).toPromise();
  }
}
