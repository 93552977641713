import {
  E07FloorLayerOptions,
  E07LayerOptions,
} from "src/app/config/e07.options";
import { BridgeDTO } from "src/app/junctions/common/bridge.dto";
import { Bridge } from "src/app/junctions/common/bridge.model";
import { JnDTO } from "src/app/junctions/common/jn.dto";
import { LayerDTO } from "src/app/junctions/common/layer.dto";
import { E07DTO } from "src/app/junctions/e07/e07.dto";
import { E07Junction } from "src/app/junctions/e07/e07.junction.model";
import * as template from "src/app/junctions/e07/e07.templates";

export function genE07Inputs(
  start: number,
  count: number
): { opts: any; total: number } {
  const output: any = {};
  const allIds: Set<string> = new Set<string>();
  let n = 0;
  const templateBridges: BridgeDTO[] = getTemplateBridges();
  createLayerOptions(0).forEach((lyr0) => {
    createLayerOptions(1).forEach((lyr1) => {
      createLayerOptions(2).forEach((lyr2) => {
        createLayerOptions(3).forEach((lyr3) => {
          createLayerOptions(4).forEach((lyr4) => {
            createLayerOptions(5).forEach((lyr5) => {
              createLayerOptions(6).forEach((lyr6) => {
                createFlrLayerOptions(0).forEach((flyr0) => {
                  createFlrLayerOptions(1).forEach((flyr1) => {
                    createFlrLayerOptions(2).forEach((flyr2) => {
                      createFlrLayerOptions(3).forEach((flyr3) => {
                        const e07: E07Junction = createNewE07Junction(
                          [lyr0, lyr1, lyr2, lyr3, lyr4, lyr5, lyr6],
                          [flyr0, flyr1, flyr2, flyr3],
                          templateBridges
                        );
                        if (
                          n >= start &&
                          n < start + count &&
                          !allIds.has(e07.calcId)
                        ) {
                          output[e07.calcId] = e07.calcInput;
                        }
                        allIds.add(e07.calcId);
                        n++;
                      });
                    });
                  });
                });
              });
            });
          });
        });
      });
    });
  });
  console.log(`Input generated: start: ${start}, end: ${start + count - 1}`);
  console.log(`count: ${Object.keys(output).length}, total: ${allIds.size}`);
  return { opts: output, total: allIds.size };
}
function createLayerOptions(lyrIndex: number): LayerDTO[] {
  const lyrOpts = [];
  E07LayerOptions[lyrIndex].forEach((matOpt, i) => {
    const matIndex = i;
    matOpt.kvalue_options.forEach((kvalOpt, j) => {
      const kvlIndex = j;
      matOpt.thickness_options.forEach((thkOpt, k) => {
        const thkIndex = k;
        lyrOpts.push({ matIndex, thkIndex, kvlIndex } as LayerDTO);
      });
    });
  });
  return lyrOpts;
}
function createFlrLayerOptions(lyrIndex: number): LayerDTO[] {
  const lyrOpts = [];
  E07FloorLayerOptions[lyrIndex].forEach((matOpt, i) => {
    const matIndex = i;
    matOpt.kvalue_options.forEach((kvalOpt, j) => {
      const kvlIndex = j;
      matOpt.thickness_options.forEach((thkOpt, k) => {
        const thkIndex = k;
        lyrOpts.push({ matIndex, thkIndex, kvlIndex } as LayerDTO);
      });
    });
  });
  return lyrOpts;
}
function createNewE07Junction(
  layers: LayerDTO[],
  flrLayers: LayerDTO[],
  bridges: BridgeDTO[]
): E07Junction {
  const jnDTO = {
    _id: "dummy",
    name: "E07 (dummy)",
    jntype: "E07",
    jndata: { layers, flrLayers, bridges, extras: [] } as E07DTO,
  } as JnDTO;
  return new E07Junction(jnDTO);
}
function getTemplateBridges(): BridgeDTO[] {
  const bdgDTOs: BridgeDTO[] = [];
  const bgs: Bridge[] = template.getNewE07Junction().bridges;
  bgs.forEach((b) => bdgDTOs.push(b.dto_copy));
  return bdgDTOs;
}
