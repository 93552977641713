import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";

@Injectable()
export class RequestInterceptor implements HttpInterceptor {
  constructor() {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    if (request.url.includes(environment.calcDataUrl)) {
      // Local assets lazy fetch
      const req = request.clone({
        url: request.url,
        withCredentials: false,
      });
      return next.handle(req);
    } else {
      const req = request.clone({
        url: `${environment.apiUrl}/${request.url}`,
        withCredentials: true,
      });
      return next.handle(req);
    }
  }
}
