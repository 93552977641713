import { JnDTO } from "../common/jn.dto";
import { Junction } from "../common/base.junction.model";
import { E13DTO } from "./e13.dto";
import { Point } from "src/app/interfaces/point.interface";
import { PolyLayerPart } from "../common/poly.layer.part.model";
import {
  E13Bridges,
  E13FloorLayerOptions,
  E13LayerOptions,
} from "src/app/config/e13.options";
import { getReqId, mmToM } from "src/app/helper/app.util";
import { BridgeDTO } from "../common/bridge.dto";
import { Bridge } from "../common/bridge.model";
import { Label } from "src/app/interfaces/label.interface";
import { LayerDTO } from "../common/layer.dto";
import { MaterialInfo } from "src/app/interfaces/mat.info.interface";
import { LayerOrient } from "src/app/config/app.const";

export class E13Junction extends Junction {
  floorTop = 100;
  lyrLeft = 150;
  constructor(private dto: JnDTO) {
    super();
    this.generateShapes();
  }
  get jnWidth(): number {
    return 1000; // max possible thickness
  }
  get jnHeight(): number {
    return 1000;
  }
  get tmWidthFactor(): number {
    return 1;
  }
  get tmHeightFactor(): number {
    return 1;
  }
  get id(): string {
    return this.dto._id;
  }
  get name(): string {
    return this.dto.name;
  }
  set name(s: string) {
    this.dto.name = s;
  }
  get jntype(): string {
    return this.dto.jntype;
  }
  get jnData(): E13DTO {
    return this.dto.jndata as E13DTO;
  }
  get jnDto(): JnDTO {
    return this.dto;
  }
  get owner(): string {
    return this.jnDto.owner;
  }
  get calcId(): string {
    let cid = "E13@";
    this.jnData.layers.forEach((lyr: LayerDTO, index: number) => {
      if (this.isLayerHidden(index)) cid += "@_@_@";
      else {
        const m: MaterialInfo = E13LayerOptions[index][lyr.matIndex];
        const thk = m.thickness_options[lyr.thkIndex].val;
        const kval = m.kvalue_options[lyr.kvlIndex];
        cid += `@${thk}@${kval}@`;
      }
    });
    cid += "@@";
    this.jnData.flrLayers.forEach((lyr: LayerDTO, index: number) => {
      const m: MaterialInfo = E13FloorLayerOptions[index][lyr.matIndex];
      const thk = m.thickness_options[lyr.thkIndex].val;
      const kval = m.kvalue_options[lyr.kvlIndex];
      cid += `@${thk}@${kval}@`;
    });
    return cid + "@";
  }
  get laminateLayerIsOn(): boolean {
    return this.jnData.layers[1].matIndex !== 0; // 0 : Plasterboard
  }
  get blockworkIsOn(): boolean {
    return [0, 1, 2, 3, 4].includes(this.jnData.layers[5].matIndex); // Blockworks
  }
  private get totalThickness(): number {
    return this.jnData.layers.reduce((x, lyrDTO, i) => {
      const m: MaterialInfo = E13LayerOptions[i][lyrDTO.matIndex];
      return this.isLayerHidden(i)
        ? x
        : x + m.thickness_options[lyrDTO.thkIndex].val;
    }, 0);
  }
  get calcInput(): any {
    const layersy = [];
    this.jnData.layers.forEach((lyr: LayerDTO, index: number) => {
      if (!this.isLayerHidden(index)) {
        const m: MaterialInfo = E13LayerOptions[index][lyr.matIndex];
        const layer: any = {
          material: m.material,
          kvalue: m.kvalue_options[lyr.kvlIndex],
          thickness: mmToM(m.thickness_options[lyr.thkIndex].val),
        };
        layersy.push(layer);
      }
    });
    // add rse and rsi
    layersy[layersy.length - 1].rse = 0.04;
    layersy[0].rsi = 0.13;

    const layersx = [];
    this.jnData.flrLayers.forEach((flyr: LayerDTO, index: number) => {
      const m: MaterialInfo = E13FloorLayerOptions[index][flyr.matIndex];
      const layer: any = {
        material: m.material,
        kvalue: m.kvalue_options[flyr.kvlIndex],
        thickness: mmToM(m.thickness_options[flyr.thkIndex].val),
      };
      layersx.push(layer);
    });
    // add rse and rsi
    layersx[0].rse = 0.04;
    layersx[layersx.length - 1].rsi = 0.1;

    // format bridges data
    const bridges = [];
    // Tarik uses his predefined bridge values. Only one bridge is sent to server to avoid error
    // k = 0.12 xs= 0.3475, xe= 0.3625, ys= 1.3175, ye= 1.4175
    bridges.push({
      material: "Timber",
      kvalue: 0.12,
      xstart: 0.3475,
      xend: 0.3625,
      ystart: 1.3175,
      yend: 1.4175,
    });

    // create input
    return {
      _id: this.calcId,
      reqid: getReqId(),
      jntype: "E13",
      frame: "timber",
      rotate: 90,
      size: [600, 600],
      layersy: layersy.reverse(),
      layersx: layersx,
      bridges,
      intemp: 20,
      extemp: 0,
    };
  }
  private getLyrsTop(index: number): number {
    if ([6, 5, 4, 3].includes(index)) return this.floorTop + 115;
    else if ([2, 1].includes(index)) {
      const totalThk = [0, 1, 2, 3].reduce((n, i) => {
        const lyrDTO = this.jnData.flrLayers[i];
        const m: MaterialInfo = E13FloorLayerOptions[i][lyrDTO.matIndex];
        const thk = m.thickness_options[lyrDTO.thkIndex].val;
        return n + thk;
      }, 0);
      return this.floorTop + totalThk;
    } else {
      const totalThk = [0, 1, 2, 3, 4].reduce((n, i) => {
        const lyrDTO = this.jnData.flrLayers[i];
        const m: MaterialInfo = E13FloorLayerOptions[i][lyrDTO.matIndex];
        const thk = m.thickness_options[lyrDTO.thkIndex].val;
        return n + thk;
      }, 0);
      return this.floorTop + totalThk;
    }
  }
  private getFlrLyrsLeft(index: number): number {
    if ([0, 1, 2].includes(index)) return 10;
    else return this.lyrLeft + 50;
  }
  private getFlrLyrThk(lyrIndex: number): number {
    const layerData = this.jnData.flrLayers[lyrIndex];
    const m: MaterialInfo = E13FloorLayerOptions[lyrIndex][layerData.matIndex];
    return m.thickness_options[layerData.thkIndex].val;
  }
  private isLayerHidden(i: number): boolean {
    return (
      (i === 0 && !this.laminateLayerIsOn) || (i === 6 && !this.blockworkIsOn)
    );
  }
  public generateShapes(): void {
    this.layerParts = [];
    this.generateFlrLayerParts();
    this.generateLayerParts();
    this.generateBridges();
  }
  public generateLayerParts(): void {
    let bpX = this.totalThickness + this.lyrLeft;
    this.jnData.layers.forEach((lyrDTO: LayerDTO, i: number) => {
      if (!this.isLayerHidden(i)) {
        const m: MaterialInfo = E13LayerOptions[i][lyrDTO.matIndex];
        const thk = m.thickness_options[lyrDTO.thkIndex].val;
        bpX = bpX - thk;
        let bpY = this.getLyrsTop(i);
        const points = [
          { x: bpX, y: bpY } as Point,
          { x: bpX + thk, y: bpY } as Point,
          { x: bpX + thk, y: this.jnHeight - 1 } as Point,
          { x: bpX, y: this.jnHeight - 1 } as Point,
        ];
        this.layerParts.push(
          new PolyLayerPart(i, points, m.background, LayerOrient.Y)
        );
      }
    });
  }
  private generateFlrLayerParts(): void {
    let bp = this.floorTop;
    this.jnData.flrLayers.forEach((lyrDTO: LayerDTO, i: number) => {
      const m: MaterialInfo = E13FloorLayerOptions[i][lyrDTO.matIndex];
      const thk = m.thickness_options[lyrDTO.thkIndex].val;
      const left = this.getFlrLyrsLeft(i);
      const points = [
        { x: left, y: bp } as Point,
        { x: this.jnWidth - 1, y: bp } as Point,
        { x: this.jnWidth - 1, y: bp + thk } as Point,
        { x: left, y: bp + thk } as Point,
      ];
      this.layerParts.push(
        new PolyLayerPart(i, points, m.background, LayerOrient.X)
      );
      bp = bp + thk;
    });
  }
  private generateBridges(): void {
    this.bridges = [];
    // Bridge [1] Part of the U shaped block
    const b1: BridgeDTO = E13Bridges[0];
    [b1.base.x, b1.base.y] = [10, this.floorTop + 15];
    b1.height = this.getFlrLyrThk(2) + 50;
    this.bridges.push(new Bridge(0, b1.background, b1));
    // Bridge [2] Part of the U shaped block
    const b2: BridgeDTO = E13Bridges[1];
    b2.base.y = this.floorTop + this.getFlrLyrThk(2) + 65;
    b2.width = this.lyrLeft - 10;
    this.bridges.push(new Bridge(0, b2.background, b2));
    // Bridge [3] Part of the U shaped block
    const b3: BridgeDTO = E13Bridges[2];
    b3.base.y = this.floorTop + 115;
    b3.height = this.getFlrLyrThk(2) - 50;
    this.bridges.push(new Bridge(0, b3.background, b3));
    // Bridge [4]
    const b4: BridgeDTO = E13Bridges[3];
    b4.base.x = this.lyrLeft + this.totalThickness + 25;
    b4.base.y = this.floorTop + 15;
    b4.height = this.getFlrLyrThk(2) + 50;
    this.bridges.push(new Bridge(1, b4.background, b4));
  }
  public generateLabels(): void {
    this.labels = [];
    // Fixed label
    this.labels[1] = {
      text: "50mm Cavity",
      start: { x: 920, y: 150 } as Point,
      mid: { x: 920, y: 150 } as Point,
      end: { x: this.jnWidth, y: 120 } as Point,
    } as Label;
    // Fixed label
    this.labels[2] = {
      text: "50mm Insulation k=0.035",
      start: { x: 300, y: 185 } as Point,
      mid: { x: 300, y: 185 } as Point,
      end: { x: this.jnWidth, y: 185 } as Point,
    } as Label;
    // Fixed label
    const layerDTO: LayerDTO = this.jnData.flrLayers[2];
    const mx: MaterialInfo = E13FloorLayerOptions[2][layerDTO.matIndex];
    const thkx = mx.thickness_options[layerDTO.thkIndex].val;
    this.labels[3] = {
      text: `${thkx}mm Insulation k=0.035`,
      start: { x: 800, y: 220 } as Point,
      mid: { x: 850, y: 260 } as Point,
      end: { x: this.jnWidth, y: 260 } as Point,
    } as Label;
    // Fixed label
    this.labels[5] = {
      text: "40.5mm Insulation k=0.022",
      start: { x: 800, y: 190 + thkx } as Point,
      mid: { x: 850, y: 435 } as Point,
      end: { x: this.jnWidth, y: 435 } as Point,
    } as Label;

    let start = 6;
    // layer [0]
    let i = 0;
    let m = this.getMaterial(i);
    if (m) {
      this.labels[start + i] = {
        text: m,
        start: { x: this.calculateLeft(i), y: 500 } as Point,
        mid: { x: this.calculateLeft(i), y: 500 } as Point,
        end: { x: this.jnWidth, y: 500 } as Point,
      } as Label;
    }
    // layer [1]
    i = 1;
    m = this.getMaterial(i);
    if (m) {
      this.labels[start + i] = {
        text: m,
        start: { x: this.calculateLeft(i), y: 578 } as Point,
        mid: { x: this.calculateLeft(i), y: 578 } as Point,
        end: { x: this.jnWidth, y: 578 } as Point,
      } as Label;
    }
    // layer [2]
    i = 2;
    m = this.getMaterial(i);
    if (m) {
      this.labels[start + i] = {
        text: m,
        start: { x: this.calculateLeft(i), y: 655 } as Point,
        mid: { x: this.calculateLeft(i), y: 655 } as Point,
        end: { x: this.jnWidth, y: 655 } as Point,
      } as Label;
    }
    // layer [3]
    i = 3;
    m = this.getMaterial(i);
    if (m) {
      this.labels[start + i] = {
        text: m,
        start: { x: this.calculateLeft(i), y: 737 } as Point,
        mid: { x: this.calculateLeft(i), y: 737 } as Point,
        end: { x: this.jnWidth, y: 737 } as Point,
      } as Label;
    }
    // layer [4]
    i = 4;
    m = this.getMaterial(i);
    if (m) {
      this.labels[start + i] = {
        text: m,
        start: { x: this.calculateLeft(i), y: 818 } as Point,
        mid: { x: this.calculateLeft(i), y: 818 } as Point,
        end: { x: this.jnWidth, y: 818 } as Point,
      } as Label;
    }
    // layer [5]
    i = 5;
    m = this.getMaterial(i);
    if (m) {
      this.labels[start + i] = {
        text: m,
        start: { x: this.calculateLeft(i), y: 895 } as Point,
        mid: { x: this.calculateLeft(i), y: 895 } as Point,
        end: { x: this.jnWidth, y: 895 } as Point,
      } as Label;
    }
    // layer [6]
    i = 6;
    m = this.getMaterial(i);
    if (m) {
      this.labels[start + i] = {
        text: m,
        start: { x: this.calculateLeft(i), y: 973 } as Point,
        mid: { x: this.calculateLeft(i), y: 973 } as Point,
        end: { x: this.jnWidth, y: 973 } as Point,
      } as Label;
    }
  }
  private getMaterial(i: number): string {
    if (this.isLayerHidden(i)) return null;
    const layerDTO: LayerDTO = this.jnData.layers[i];
    const m: MaterialInfo = E13LayerOptions[i][layerDTO.matIndex];
    return m.material;
  }
  private getThickness(i: number): number {
    if (this.isLayerHidden(i)) return 0;
    const layerDTO: LayerDTO = this.jnData.layers[i];
    const m: MaterialInfo = E13LayerOptions[i][layerDTO.matIndex];
    return m.thickness_options[layerDTO.thkIndex].val;
  }
  private calculateLeft(i: number): number {
    let w = this.lyrLeft;
    for (let j = 6; j > i; j--) {
      w += this.getThickness(j);
    }
    return w + this.getThickness(i) / 2;
  }
}
