import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Junction } from "../junctions/common/base.junction.model";
import { getNewE01Junction } from "../junctions/e01/e01.templates";
import { getNewE02AJunction } from "../junctions/e02a/e02a.templates";
import { getNewE03Junction } from "../junctions/e03/e03.templates";
import { getNewE04Junction } from "../junctions/e04/e04.templates";
import { getNewE05AJunction } from "../junctions/e05a/e05a.templates";
import { getNewE05BJunction } from "../junctions/e05b/e05b.templates";
import { getNewE06Junction } from "../junctions/e06/e06.templates";
import { getNewE07Junction } from "../junctions/e07/e07.templates";
import { getNewE10Junction } from "../junctions/e10/e10.templates";
import { getNewE12Junction } from "../junctions/e12/e12.templates";
import { getNewE13Junction } from "../junctions/e13/e13.templates";
import { getNewE16Junction } from "../junctions/e16/e16.templates";
import { getNewE17Junction } from "../junctions/e17/e17.templates";
import { JnDTO } from "../junctions/common/jn.dto";
import { AuthenticationService } from "./authentication/authentication.service";
import { E01Junction } from "../junctions/e01/e01.junction.model";
import { E02AJunction } from "../junctions/e02a/e02a.junction.model";
import { E03Junction } from "../junctions/e03/e03.junction.model";
import { E04Junction } from "../junctions/e04/e04.junction.model";
import { E05AJunction } from "../junctions/e05a/e05a.junction.model";
import { E05BJunction } from "../junctions/e05b/e05b.junction.model";
import { E06Junction } from "../junctions/e06/e06.junction.model";
import { E07Junction } from "../junctions/e07/e07.junction.model";
import { E10Junction } from "../junctions/e10/e10.junction.model";
import { E12Junction } from "../junctions/e12/e12.junction.model";
import { E13Junction } from "../junctions/e13/e13.junction.model";
import { E16Junction } from "../junctions/e16/e16.junction.model";
import { E17Junction } from "../junctions/e17/e17.junction.model";
import { AnalyticsResults } from "../interfaces/analyticsResults.interface";
import { Observable } from "rxjs";
import { JnAnalyticsDTO } from "../junctions/common/jnAnalytics.dto";
import { getNewE11Junction } from "../junctions/e11/e11.templates";
import { E11Junction } from "../junctions/e11/e11.junction.model";

@Injectable({
  providedIn: "root",
})
export class JunctionService {
  constructor(
    private http: HttpClient,
    private authService: AuthenticationService
  ) {}

  public createNewJunction(template: string): Junction {
    if (template === "E01") return getNewE01Junction();
    if (template === "E02A") return getNewE02AJunction();
    if (template === "E03") return getNewE03Junction();
    if (template === "E04") return getNewE04Junction();
    if (template === "E05A") return getNewE05AJunction();
    if (template === "E05B") return getNewE05BJunction();
    if (template === "E06") return getNewE06Junction();
    if (template === "E07") return getNewE07Junction();
    if (template === "E10") return getNewE10Junction();
    if (template === "E11") return getNewE11Junction();
    if (template === "E12") return getNewE12Junction();
    if (template === "E13") return getNewE13Junction();
    if (template === "E16") return getNewE16Junction();
    if (template === "E17") return getNewE17Junction();
    console.error(`ERROR: invalid junction type [${template}]`);
  }
  public async fetchJunctionById(jnId: string): Promise<Junction> {
    try {
      const jnDTO = await this.fetchJunctionFromServer(jnId);
      if (jnDTO.jntype === "E01") return new E01Junction(jnDTO);
      if (jnDTO.jntype === "E02A") return new E02AJunction(jnDTO);
      if (jnDTO.jntype === "E03") return new E03Junction(jnDTO);
      if (jnDTO.jntype === "E04") return new E04Junction(jnDTO);
      if (jnDTO.jntype === "E05A") return new E05AJunction(jnDTO);
      if (jnDTO.jntype === "E05B") return new E05BJunction(jnDTO);
      if (jnDTO.jntype === "E06") return new E06Junction(jnDTO);
      if (jnDTO.jntype === "E07") return new E07Junction(jnDTO);
      if (jnDTO.jntype === "E10") return new E10Junction(jnDTO);
      if (jnDTO.jntype === "E11") return new E11Junction(jnDTO);
      if (jnDTO.jntype === "E12") return new E12Junction(jnDTO);
      if (jnDTO.jntype === "E13") return new E13Junction(jnDTO);
      if (jnDTO.jntype === "E16") return new E16Junction(jnDTO);
      if (jnDTO.jntype === "E17") return new E17Junction(jnDTO);
      throw new Error("Invalid junction type");
    } catch (er) {
      console.error("There was an error!", er);
      throw er;
    }
  }
  public async saveJunctionDTO(jnDTO: JnDTO): Promise<void> {
    try {
      if (!jnDTO.created) jnDTO.created = new Date();
      if (!jnDTO.owner)
        jnDTO.owner = this.authService.userBeahaviourSubject._id;
      jnDTO.saved = new Date();
      return await this.saveJunctionToServer(jnDTO);
    } catch (er) {
      console.error("There was an error!", er);
      throw er;
    }
  }
  public async deleteJn(jnId: string): Promise<any> {
    try {
      return await this.deleteJnInServer(jnId);
    } catch (er) {
      console.error("There was an error!", er);
      throw er;
    }
  }
  public async fetchSavedJunctions(): Promise<[]> {
    try {
      const ownerId = this.authService.userBeahaviourSubject._id;
      return await this.fetchJunctionsByOwner(ownerId);
    } catch (er) {
      console.error("There was an error!", er);
      throw er;
    }
  }

  public getAllJunctionsInCurrentMonth(): Observable<AnalyticsResults> {
    return this.http.request<AnalyticsResults>("GET", "analytics/monthly");
  }

  public getAllJunctionsInPastYear(): Observable<AnalyticsResults[]> {
    return this.http.request<AnalyticsResults[]>("GET", "analytics/yearly");
  }

  public saveJnToAnalytics(jn: Junction): Observable<JnAnalyticsDTO> {
    const ownerId = this.authService.userBeahaviourSubject?._id;

    return this.http.request<JnAnalyticsDTO>(
      "POST",
      ownerId
        ? "analytics/saveJnToAnalytics-authed"
        : "analytics/saveJnToAnalytics",
      { body: jn.jnDto }
    );
  }

  private async fetchJunctionFromServer(jnId: string): Promise<JnDTO> {
    const jnGetUrl = `junction/id/${jnId}`;
    return await this.http.get<JnDTO>(jnGetUrl).toPromise();
  }
  private async saveJunctionToServer(jnDTO: JnDTO): Promise<any> {
    console.log(`Saving Junction:`, JSON.stringify(jnDTO));
    return await this.http.post<any>(`junction`, jnDTO).toPromise();
  }
  private deleteJnInServer(jnId: string): Promise<any> {
    const jnDelUrl = `junction/delete/id/${jnId}`;
    return this.http.delete<any>(jnDelUrl).toPromise();
  }
  private fetchJunctionsByOwner(owner: string): Promise<[]> {
    const jnGetUrl = `junction/owner/${owner}`;
    return this.http.get<[]>(jnGetUrl).toPromise();
  }
}
