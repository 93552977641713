<div id="pdf-html-container-1" class="pdf-container-A4" [ngStyle]="{ 'width.mm': a4.width, 'height.mm': a4.height }">
  <div class="row">
    <div class="col-md-6">
      <app-pdf-header [jn]="jn"></app-pdf-header>
    </div>
    <div class="col-md-6"></div>
  </div>
  <div class="row">
    <div class="col-md-12">
      <div class="pdf-body">
        <div class="row">
          <div class="col-md-6">
            <h1 class="report-title">
              Linear Thermal Transmittance (PSI-value) & temperature Factor
              (F-value)
            </h1>
            <div class="jn-title-block">
              <h2>Junction reference:</h2>
              <h3>{{ jn.name }}</h3>
            </div>
            <table class="report-title-table">
              <tr>
                <td width="40%">Ref:</td>
                <td width="30%"></td>
                <td>Dated:</td>
                <td>{{ today | date: "longDate" }}</td>
              </tr>
              <tr>
                <td>Issued by:</td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
            </table>
            <div class="row img-container">
              <img id="pdf-jn-img" alt="Junction Image" class="pdf-jn-img" />
              <div class="pdf-labels">
                <div *ngFor="let item of jn.labels" class="label">
                  {{ item ? item.text : "" }}
                </div>
              </div>
            </div>

            <ul class="notes">
              <ng-container *ngIf="detailsItems.length > 0">
                <ng-container *ngIf="jn.jntype.startsWith('E05')">
                  <li>
                    <b>Floor</b>
                    <ul>
                      <li *ngFor="let item of detailsItems">{{ item }}</li>
                    </ul>
                  </li>
                </ng-container>
                <ng-container *ngIf="!jn.jntype.startsWith('E05')">
                  <li *ngFor="let item of detailsItems">{{ item }}</li>
                </ng-container>
              </ng-container>
              <li>
                The materials are as in the wall shown in Table 1 with the
                additional materials shown in the Figure. To deal with the
                diagonal element of the lintel, the Trisco model was developed
                with a second Physibel package, Bisco, this means that the
                Trisco model has non standard scaling: 1 grid unit = 0.395mm.
              </li>
              <li>
                Calculations completed using methodology outlined in BS EN ISO
                10211: 2007, and in accordance with BR 497: Conventions for
                calculating linear thermal transmittance and temperature factors
                (2nd edition).
              </li>
              <li>
                All rights reserved, including those of photomechanical
                reproduction and storage in electronic media. Extreme caution
                was observed when putting together and processing the
                information, texts and illustrations in this document.<br />
                Nevertheless, errors cannot quite be ruled out. The publisher
                and editors cannot assume legal responsibility or any liability
                whatever for incorrect information and the consequences thereof.
                The publisher and editors will be grateful for improvement
                suggestions and details of possible errors pointed out.
              </li>
            </ul>
          </div>
          <div class="col-md-6">
            <div class="row">
              <div class="col-md-3">
                <h2 class="side-title">Results</h2>
              </div>
              <div class="col-md-9">
                <div class="results-box">
                  <ul>
                    <li>
                      PSI value:
                      <span class="result-psi">{{ psiValue }}</span>
                      <span class="units">W/mK</span>
                    </li>
                    <li>
                      F factor:<span class="result-ff">{{ fValue }}</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <h1 class="table-title">
              Linear thermal transmittance (PSI values) & Temperature factors
              (F-factors) based on various block types & insulation with varying
              thicknesses
            </h1>
            <div class="report-results-container">
              <table class="report-results-table">
                <tr>
                  <th>Block</th>
                  <th>Thermal conductivity (W/mK)</th>
                  <th>Thickness (mm)</th>
                </tr>
                <tr *ngFor="let layer of layers">
                  <td>{{ layer.material }}</td>
                  <td>{{ layer.kvalue }}</td>
                  <td>{{ layer.thicknessx * 1000 }}</td>
                </tr>
              </table>
            </div>

            <h2 class="side-title">On-site checklist</h2>
            <ul class="checklist-boxes">
              <li>Knauf Insulation {{ cavityMaterial }} installed in cavity</li>
              <li *ngFor="let item of checkListItems">{{ item }}</li>
            </ul>

            <ul class="signature">
              <li>Site manager/supervisor:</li>
              <li>Site name:</li>
              <li>Plot number:</li>
              <li>Date:</li>
            </ul>


            <!-- disclaimer  -->
            <p class="ki-disclaimer">Knauf Insulation is constantly seeking ways to improve its products and services.
              Whilst every effort is made to ensure the accuracy of the above calculation, please note that the
              specifications, design and production of products are subject to change. The calculation provided is a
              recommendation only and Knauf Insulation does not give any warranty or representation whatsoever in
              connection with or assume liability for the accuracy, timeliness, reliability or completeness of the
              calculation. The calculation is based on the information provided to Knauf Insulation and Knauf Insulation
              accepts no liability for errors or omissions in the input data or errors resulting from any inaccuracy of
              input data. In no event shall Knauf Insulation be liable for any losses or damages arising out of or in
              connection with use of the calculation.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <a class="autopsi-signature" href="https://autopsi.co.uk/" target="_blank" rel="noopener noreferrer">
    Powered by AutoPSI.co.uk
  </a>
  <app-pdf-footer></app-pdf-footer>
</div>