<div id="content">
  <div class="container">
    <div class="row">
      <div class="main-box">
        <div *ngIf="!showPasswordResetForm" class="form-container">
          <h2>USER LOGIN</h2>

          <form [formGroup]="loginForm" id="loginForm" (ngSubmit)="login()">
            <div class="form-group">
              <mat-form-field appearance="fill" autofilled="true">
                <mat-label>Email</mat-label>
                <input type="email" matInput formControlName="email" placeholder="Ex. pat@example.com" required>
                <mat-error
                  *ngIf="loginFormControls.email.hasError('email') && !loginFormControls.email.hasError('required')">
                  Please enter a valid email address
                </mat-error>
                <mat-error *ngIf="loginFormControls.email.hasError('required')">
                  Email is <strong>required</strong>
                </mat-error>
              </mat-form-field>
            </div>
            <div class="form-group">
              <mat-form-field appearance="fill" autofilled="true">
                <mat-label>Password</mat-label>
                <input type="password" matInput formControlName="password" required>
                <mat-error *ngIf="loginFormControls.password.hasError('pattern')">
                  Your password must contain at least one lower case character, uppercase character
                  and a
                  number!
                </mat-error>
                <mat-error *ngIf="loginFormControls.password.hasError('minlength')">
                  Your password must be at least 8 characters long!
                </mat-error>
                <mat-error *ngIf="loginFormControls.password.hasError('maxlength')">
                  Your password must be max 25 characters long!
                </mat-error>
                <mat-error *ngIf="loginFormControls.password.hasError('required')">
                  Password is <strong>required</strong>
                </mat-error>
              </mat-form-field>
            </div>
            <div class="row justify-content-center">
              <div class="col-md-6">
                <p class="form-text">Forgot Password? <br> <a (click)="togglePasswordResetForm()">Click here to
                    reset your password</a>
                </p>
              </div>
              <div class="col-md-6">
                <p class="form-text">Don't have an account? <br> <a routerLink="/register">Click here to
                    register</a>
                </p>
              </div>
              <div class="col-md-8">
                <p *ngIf="errorMessage" class="error-message">{{ errorMessage }}</p>
              </div>
            </div>
            <div class="cta-section">
              <button class="btn btn-main green" [class.active]="!loginForm.invalid" [disabled]="loginForm.invalid"
                type="submit">
                <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                Login<i class="fa fa-arrow-right al-right" aria-hidden="true"></i></button>
            </div>
          </form>
        </div>

        <div *ngIf="showPasswordResetForm" class="form-container">
          <h2>Reset Password</h2>
          <p class="form-text">Please enter the email address you registered with when you created your account in the
            field below:</p>

          <form [formGroup]="passwordResetForm" (ngSubmit)="resetPassword()">
            <div class="form-group">
              <mat-form-field appearance="fill" autofilled="true">
                <mat-label>Email</mat-label>
                <input type="email" matInput formControlName="email" placeholder="Ex. pat@example.com" required>
                <mat-error
                  *ngIf="passwordResetFormControls.email.hasError('email') && !passwordResetFormControls.email.hasError('required')">
                  Please enter a valid email address
                </mat-error>
                <mat-error *ngIf="passwordResetFormControls.email.hasError('required')">
                  Email is <strong>required</strong>
                </mat-error>
              </mat-form-field>
            </div>
            <p class="form-text"><a (click)="showPasswordResetForm = !showPasswordResetForm">Back to the user login
                form</a></p>
            <div class="cta-section">
              <button class="btn btn-main" [class.active]="!passwordResetForm.invalid"
                [disabled]="passwordResetForm.invalid" type="submit">Submit<i class="fa fa-arrow-right al-right"
                  aria-hidden="true"></i></button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>