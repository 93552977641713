import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { BrowserModule, Title } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { LayoutModule } from "@angular/cdk/layout";
import { MatTabsModule } from "@angular/material/tabs";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatButtonModule } from "@angular/material/button";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatIconModule } from "@angular/material/icon";
import { MatListModule } from "@angular/material/list";
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { MatInputModule } from "@angular/material/input";
import { MatTableModule } from "@angular/material/table";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatDividerModule } from "@angular/material/divider";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatDialogModule } from "@angular/material/dialog";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { JunctionService } from "./services/junction.service";
import { HomeComponent } from "./components/home/home.component";
import { CalculatorComponent } from "./components/calculator/calculator.component";
import { JunctionComponent } from "./components/calculator/junctions/junction/junction.component";
import { SvgClassComponent } from "./components/calculator/junctions/svg-class/svg-class.component";
import { JnSvgComponent } from "./components/calculator/junctions/jn-svg/jn-svg.component";
import { JnSvgContainerComponent } from "./components/calculator/junctions/jn-svg-container/jn-svg-container.component";
import { JnResultComponent } from "./components/calculator/jn-result/jn-result.component";
import { NavBarComponent } from "./components/common/nav-bar/nav-bar.component";
import { E01EditorComponent } from "./components/calculator/junctions/e01/e01-editor/e01-editor.component";
import { E02AEditorComponent } from "./components/calculator/junctions/e02a/e02a-editor/e02a-editor.component";
import { E03EditorComponent } from "./components/calculator/junctions/e03/e03-editor/e03-editor.component";
import { E04EditorComponent } from "./components/calculator/junctions/e04/e04-editor/e04-editor.component";
import { E05AEditorComponent } from "./components/calculator/junctions/e05a/e05a-editor/e05a-editor.component";
import { E05BEditorComponent } from "./components/calculator/junctions/e05b/e05b-editor/e05b-editor.component";
import { E06EditorComponent } from "./components/calculator/junctions/e06/e06-editor/e06-editor.component";
import { E07EditorComponent } from "./components/calculator/junctions/e07/e07-editor/e07-editor.component";
import { E10EditorComponent } from "./components/calculator/junctions/e10/e10-editor/e10-editor.component";
import { E11EditorComponent } from "./components/calculator/junctions/e11/e11-editor/e11-editor.component";
import { E12EditorComponent } from "./components/calculator/junctions/e12/e12-editor/e12-editor.component";
import { E13EditorComponent } from "./components/calculator/junctions/e13/e13-editor/e13-editor.component";
import { E16EditorComponent } from "./components/calculator/junctions/e16/e16-editor/e16-editor.component";
import { E17EditorComponent } from "./components/calculator/junctions/e17/e17-editor/e17-editor.component";
import { RequestInterceptor } from "./services/authentication/request.interceptor";
import { LoginComponent } from "./components/authentication/login/login.component";
import { PasswordResetComponent } from "./components/authentication/password-reset/password-reset.component";
import { RegisterComponent } from "./components/authentication/register/register.component";
import { ThermalModelComponent } from "./components/calculator/junctions/thermal-model/thermal-model.component";
import { CalcService } from "./services/calc.service";
import { PdfFirstPageComponent } from "./components/common/pdf-page/pdf-first-page/pdf-first-page.component";
import { PdfFooterComponent } from "./components/common/pdf-page/pdf-footer/pdf-footer.component";
import { PdfHeaderComponent } from "./components/common/pdf-page/pdf-header/pdf-header.component";
import { PdfPageComponent } from "./components/common/pdf-page/pdf-page.component";
import { InputGeneratorComponent } from "./components/input-generator/input-generator.component";
import { WelcomeComponent } from "./components/welcome/welcome.component";
import { SavedJunctionsComponent } from "./components/saved-junctions/saved-junctions.component";
import { AnalyticsPanelComponent } from "./components/admin/analytics-panel/analytics-panel.component";

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    CalculatorComponent,
    JunctionComponent,
    JnSvgContainerComponent,
    JnSvgComponent,
    E01EditorComponent,
    E02AEditorComponent,
    E03EditorComponent,
    E04EditorComponent,
    E05AEditorComponent,
    E05BEditorComponent,
    E06EditorComponent,
    E07EditorComponent,
    E10EditorComponent,
    E11EditorComponent,
    E12EditorComponent,
    E13EditorComponent,
    E16EditorComponent,
    E17EditorComponent,
    SvgClassComponent,
    JnResultComponent,
    NavBarComponent,
    LoginComponent,
    PasswordResetComponent,
    RegisterComponent,
    ThermalModelComponent,
    PdfPageComponent,
    PdfFirstPageComponent,
    PdfHeaderComponent,
    PdfFooterComponent,
    InputGeneratorComponent,
    WelcomeComponent,
    SavedJunctionsComponent,
    AnalyticsPanelComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    CommonModule,
    BrowserAnimationsModule,
    MatTabsModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    LayoutModule,
    MatToolbarModule,
    MatButtonModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    MatButtonToggleModule,
    MatInputModule,
    MatTableModule,
    MatPaginatorModule,
    MatDatepickerModule,
    MatDividerModule,
    MatSnackBarModule,
    MatProgressSpinnerModule,
    MatDialogModule,
  ],
  providers: [
    Title,
    JunctionService,
    CalcService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RequestInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
