import { JnDTO } from "../common/jn.dto";
import { Junction } from "../common/base.junction.model";
import { E12DTO } from "./e12.dto";
import { Point } from "src/app/interfaces/point.interface";
import { PolyLayerPart } from "../common/poly.layer.part.model";
import {
  E12Bridges,
  E12FloorLayerOptions,
  E12LayerOptions,
} from "src/app/config/e12.options";
import { getReqId, mmToM } from "src/app/helper/app.util";
import { BridgeDTO } from "../common/bridge.dto";
import { Bridge } from "../common/bridge.model";
import { Label } from "src/app/interfaces/label.interface";
import { LayerDTO } from "../common/layer.dto";
import { MaterialInfo } from "src/app/interfaces/mat.info.interface";
import { LayerOrient } from "src/app/config/app.const";

export class E12Junction extends Junction {
  floorTop = 450;
  constructor(private dto: JnDTO) {
    super();
    this.generateShapes();
  }
  get jnWidth(): number {
    return 1250; // max possible thickness
  }
  get jnHeight(): number {
    return 1250;
  }
  get tmWidthFactor(): number {
    return 1;
  }
  get tmHeightFactor(): number {
    return 1;
  }
  get id(): string {
    return this.dto._id;
  }
  get name(): string {
    return this.dto.name;
  }
  set name(s: string) {
    this.dto.name = s;
  }
  get jntype(): string {
    return this.dto.jntype;
  }
  get jnData(): E12DTO {
    return this.dto.jndata as E12DTO;
  }
  get jnDto(): JnDTO {
    return this.dto;
  }
  get owner(): string {
    return this.jnDto.owner;
  }
  get calcId(): string {
    let cid = "E12@";
    this.jnData.layers.forEach((lyr: LayerDTO, index: number) => {
      if (this.isLayerHidden(index)) cid += "@_@_@";
      else {
        const m: MaterialInfo = E12LayerOptions[index][lyr.matIndex];
        const thk = m.thickness_options[lyr.thkIndex].val;
        const kval = m.kvalue_options[lyr.kvlIndex];
        cid += `@${thk}@${kval}@`;
      }
    });
    cid += "@@";
    // Floor layer 1 - Air Space not considered
    // Floor layer 2 - Insulation between joists
    const FL1 = this.jnData.flrLayers[0];
    const m1: MaterialInfo = E12FloorLayerOptions[0][FL1.matIndex];
    const thk1 = m1.thickness_options[FL1.thkIndex].val;
    const kval1 = m1.kvalue_options[FL1.kvlIndex];
    // Floor layer 3 - Insulation top layer - cross laid
    const FL2 = this.jnData.flrLayers[1];
    const m2: MaterialInfo = E12FloorLayerOptions[1][FL2.matIndex];
    const thk2 = m2.thickness_options[FL2.thkIndex].val;
    const kval2 = m2.kvalue_options[FL2.kvlIndex];
    // Floor layer 4 - Insulation top layer 2 - cross laid
    const FL3 = this.jnData.flrLayers[2];
    const m3: MaterialInfo = E12FloorLayerOptions[2][FL3.matIndex];
    const thk3 = m3.thickness_options[FL3.thkIndex].val;
    const kval3 = m3.kvalue_options[FL3.kvlIndex];
    if (kval1 === kval2 && kval2 === kval3)
      cid += `@${thk1 + thk2 + thk3}@${kval1}@`;
    else cid += `@INVALID@INVALID@`;
    // Floor layer 5 - Air Space not considered
    const FL4 = this.jnData.flrLayers[3];
    const m4: MaterialInfo = E12FloorLayerOptions[3][FL4.matIndex];
    const thk4 = m4.thickness_options[FL4.thkIndex].val;
    const kval4 = m4.kvalue_options[FL4.kvlIndex];
    cid += `@${thk4}@${kval4}@`;
    return cid + "@";
  }
  get laminateLayerIsOn(): boolean {
    return this.jnData.layers[1].matIndex !== 0; // 0 : Plasterboard
  }
  get blockworkIsOn(): boolean {
    return [0, 1, 2, 3, 4].includes(this.jnData.layers[5].matIndex); // Blockworks
  }
  get totalThickness(): number {
    return this.jnData.layers.reduce((x, lyrDTO, i) => {
      const m: MaterialInfo = E12LayerOptions[i][lyrDTO.matIndex];
      return this.isLayerHidden(i)
        ? x
        : x + m.thickness_options[lyrDTO.thkIndex].val;
    }, 0);
  }
  get totalFlrThickness(): number {
    return this.jnData.flrLayers.reduce((x, lyrDTO, i) => {
      const m: MaterialInfo = E12FloorLayerOptions[i][lyrDTO.matIndex];
      return i > 2 ? x : x + m.thickness_options[lyrDTO.thkIndex].val;
    }, 0);
  }
  get lyr0Thickness(): number {
    const i = this.laminateLayerIsOn ? 0 : 1;
    const lyrDTO: LayerDTO = this.jnData.layers[i];
    const m: MaterialInfo = E12LayerOptions[i][lyrDTO.matIndex];
    return m.thickness_options[lyrDTO.thkIndex].val;
  }
  get flrLayerLeft(): number {
    return this.jnData.layers.reduce((x, lyrDTO, i) => {
      const m: MaterialInfo = E12LayerOptions[i][lyrDTO.matIndex];
      return this.isLayerHidden(i) || i < 3
        ? x
        : x + m.thickness_options[lyrDTO.thkIndex].val;
    }, 0);
  }
  get bridge1Top(): number {
    const m1 = E12FloorLayerOptions[0][this.jnData.flrLayers[0].matIndex];
    const h1 = m1.thickness_options[this.jnData.flrLayers[0].thkIndex].val;
    const m2 = E12FloorLayerOptions[1][this.jnData.flrLayers[1].matIndex];
    const h2 = m2.thickness_options[this.jnData.flrLayers[1].thkIndex].val;
    return this.floorTop + h1 + h2;
  }
  get bridge1Height(): number {
    const m = E12FloorLayerOptions[2][this.jnData.flrLayers[2].matIndex];
    return m.thickness_options[this.jnData.flrLayers[2].thkIndex].val;
  }
  get totalThkOfCombFlrLyrs(): number {
    const flyr0: LayerDTO = this.jnData.flrLayers[0];
    const m0: MaterialInfo = E12FloorLayerOptions[0][flyr0.matIndex];
    const thk0 = m0.thickness_options[flyr0.thkIndex].val;
    const flyr1: LayerDTO = this.jnData.flrLayers[1];
    const m1: MaterialInfo = E12FloorLayerOptions[1][flyr1.matIndex];
    const thk1 = m1.thickness_options[flyr1.thkIndex].val;
    const flyr2: LayerDTO = this.jnData.flrLayers[2];
    const m2: MaterialInfo = E12FloorLayerOptions[2][flyr2.matIndex];
    const thk2 = m2.thickness_options[flyr2.thkIndex].val;
    return thk0 + thk1 + thk2;
  }
  get kvalOfCombFlrLyrs(): number {
    const flyr0: LayerDTO = this.jnData.flrLayers[0];
    const m0: MaterialInfo = E12FloorLayerOptions[0][flyr0.matIndex];
    return m0.kvalue_options[flyr0.kvlIndex];
  }
  get calcInput(): any {
    const layersy = [];
    this.jnData.layers.forEach((lyr: LayerDTO, index: number) => {
      if (!this.isLayerHidden(index)) {
        const m: MaterialInfo = E12LayerOptions[index][lyr.matIndex];
        const layer: any = {
          material: m.material,
          kvalue: m.kvalue_options[lyr.kvlIndex],
          thickness: mmToM(m.thickness_options[lyr.thkIndex].val),
        };
        layersy.push(layer);
      }
    });
    // add rse and rsi
    layersy[layersy.length - 1].rse = 0.04;
    layersy[0].rsi = 0.13;

    const layersx = [];
    this.jnData.flrLayers.forEach((flyr: LayerDTO, index: number) => {
      const m: MaterialInfo = E12FloorLayerOptions[index][flyr.matIndex];
      const layer: any = {
        material: m.material,
        kvalue: m.kvalue_options[flyr.kvlIndex],
        thickness: mmToM(m.thickness_options[flyr.thkIndex].val),
      };
      layersx.push(layer);
    });
    // add rse and rsi
    layersx[layersx.length - 1].rse = 0.1;
    layersx[0].rsi = 0.1;

    // format bridges data
    // Tarik uses his predefined bridge values. Bridge is sent to server to avoid error but not used
    const bridges = [];
    this.bridges.forEach((bridge: Bridge) => {
      bridges.push({
        material: bridge.material,
        kvalue: bridge.kvalue,
        xstart: mmToM(bridge.base.x),
        xend: mmToM(bridge.base.x + bridge.width),
        ystart: mmToM(this.jnHeight - bridge.base.y - bridge.height),
        yend: mmToM(this.jnHeight - bridge.base.y),
      });
    });
    // create input
    return {
      _id: this.calcId,
      reqid: getReqId(),
      jntype: "E12",
      frame: "timber",
      rotate: 90,
      size: [600, 600],
      layersy: layersy.reverse(),
      layersx: layersx,
      bridges,
      intemp: 20,
      extemp: 0,
    };
  }
  getLayerWidth(lyrIndex: number): number {
    const layerData = this.jnData.layers[lyrIndex];
    const m: MaterialInfo = E12LayerOptions[lyrIndex][layerData.matIndex];
    return m.thickness_options[layerData.thkIndex].val;
  }
  isLayerHidden(i: number): boolean {
    return (
      (i === 0 && !this.laminateLayerIsOn) || (i === 6 && !this.blockworkIsOn)
    );
  }
  public generateShapes(): void {
    this.layerParts = [];
    this.generateLayerParts();
    this.generateFlrLayerParts();
    this.generateBridges();
  }
  public generateLayerParts(): void {
    let bp = { x: this.totalThickness, y: undefined };
    this.jnData.layers.forEach((lyrDTO: LayerDTO, i: number) => {
      if (!this.isLayerHidden(i)) {
        const m: MaterialInfo = E12LayerOptions[i][lyrDTO.matIndex];
        const thk = m.thickness_options[lyrDTO.thkIndex].val;
        bp.x = bp.x - thk;
        bp.y = i > 2 ? 1 : this.floorTop + this.totalFlrThickness + 1;
        const points = [
          { x: bp.x, y: bp.y } as Point,
          { x: bp.x + thk, y: bp.y } as Point,
          { x: bp.x + thk, y: this.jnHeight - 1 } as Point,
          { x: bp.x, y: this.jnHeight - 1 } as Point,
        ];
        this.layerParts.push(
          new PolyLayerPart(i, points, m.background, LayerOrient.Y)
        );
      }
    });
  }
  private generateFlrLayerParts(): void {
    let bp = this.floorTop;
    this.jnData.flrLayers.forEach((lyrDTO: LayerDTO, i: number) => {
      const m: MaterialInfo = E12FloorLayerOptions[i][lyrDTO.matIndex];
      const thk = m.thickness_options[lyrDTO.thkIndex].val;
      const left =
        i > 2 ? this.totalThickness - this.lyr0Thickness : this.flrLayerLeft;
      const points = [
        { x: left, y: bp } as Point,
        { x: this.jnWidth - 1, y: bp } as Point,
        { x: this.jnWidth - 1, y: bp + thk } as Point,
        { x: left, y: bp + thk } as Point,
      ];
      this.layerParts.push(
        new PolyLayerPart(i, points, m.background, LayerOrient.X)
      );
      bp = bp + thk;
    });
  }
  private generateBridges(): void {
    this.bridges = [];
    // Bridge [1]
    const b1: BridgeDTO = E12Bridges[0];
    b1.height = this.bridge1Height;
    [b1.base.x, b1.base.y] = [this.totalThickness + 75, this.bridge1Top];
    this.bridges.push(new Bridge(0, b1.background, b1));
  }
  public generateLabels(): void {
    this.labels = [];
    // layer [3]
    let i = 3;
    let m = this.getMaterial(i);
    if (m) {
      this.labels[0] = {
        text: m,
        start: { x: this.calculateLeft(i), y: 45 } as Point,
        mid: { x: this.calculateLeft(i), y: 45 } as Point,
        end: { x: this.jnWidth, y: 45 } as Point,
      } as Label;
    }
    // layer [4]
    i = 4;
    m = this.getMaterial(i);
    if (m) {
      this.labels[1] = {
        text: m,
        start: { x: this.calculateLeft(i), y: 120 } as Point,
        mid: { x: this.calculateLeft(i), y: 120 } as Point,
        end: { x: this.jnWidth, y: 120 } as Point,
      } as Label;
    }
    // layer [5]
    i = 5;
    m = this.getMaterial(i);
    if (m) {
      this.labels[2] = {
        text: m,
        start: { x: this.calculateLeft(i), y: 230 } as Point,
        mid: { x: this.calculateLeft(i), y: 230 } as Point,
        end: { x: this.jnWidth, y: 230 } as Point,
      } as Label;
    }
    // layer [6]
    i = 6;
    m = this.getMaterial(i);
    if (m) {
      this.labels[3] = {
        text: m,
        start: { x: this.calculateLeft(i), y: 320 } as Point,
        mid: { x: this.calculateLeft(i), y: 320 } as Point,
        end: { x: this.jnWidth, y: 320 } as Point,
      } as Label;
    }
    // Fixed label
    this.labels[7] = {
      text: `${this.totalThkOfCombFlrLyrs}mm Insulation k=${this.kvalOfCombFlrLyrs}`,
      start: { x: 830, y: 740 } as Point,
      mid: { x: 830, y: 740 } as Point,
      end: { x: this.jnWidth, y: 740 } as Point,
    } as Label;
    // layer [0]
    i = 0;
    m = this.getMaterial(i);
    if (m) {
      this.labels[10] = {
        text: m,
        start: { x: this.calculateLeft(i), y: 1045 } as Point,
        mid: { x: this.calculateLeft(i), y: 1045 } as Point,
        end: { x: this.jnWidth, y: 1045 } as Point,
      } as Label;
    }
    // layer [1]
    i = 1;
    m = this.getMaterial(i);
    if (m) {
      this.labels[11] = {
        text: m,
        start: { x: this.calculateLeft(i), y: 1130 } as Point,
        mid: { x: this.calculateLeft(i), y: 1130 } as Point,
        end: { x: this.jnWidth, y: 1130 } as Point,
      } as Label;
    }
    // layer [2]
    i = 2;
    m = this.getMaterial(i);
    if (m) {
      this.labels[12] = {
        text: m,
        start: { x: this.calculateLeft(i), y: 1220 } as Point,
        mid: { x: this.calculateLeft(i), y: 1220 } as Point,
        end: { x: this.jnWidth, y: 1220 } as Point,
      } as Label;
    }
  }
  private getMaterial(i: number): string {
    if (this.isLayerHidden(i)) return null;
    const layerDTO: LayerDTO = this.jnData.layers[i];
    const m: MaterialInfo = E12LayerOptions[i][layerDTO.matIndex];
    return m.material;
  }
  private getThickness(i: number): number {
    if (this.isLayerHidden(i)) return 0;
    const layerDTO: LayerDTO = this.jnData.layers[i];
    const m: MaterialInfo = E12LayerOptions[i][layerDTO.matIndex];
    return m.thickness_options[layerDTO.thkIndex].val;
  }
  private calculateLeft(i: number): number {
    let w = 1;
    for (let j = 6; j > i; j--) {
      w += this.getThickness(j);
    }
    return w + this.getThickness(i) / 2;
  }
}
