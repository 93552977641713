import { JnDTO } from "../common/jn.dto";
import { Junction } from "../common/base.junction.model";
import { E07DTO } from "./e07.dto";
import { Point } from "src/app/interfaces/point.interface";
import { PolyLayerPart } from "../common/poly.layer.part.model";
import {
  E07Bridges,
  E07FloorLayerOptions,
  E07LayerOptions,
} from "src/app/config/e07.options";
import { getReqId, mmToM } from "src/app/helper/app.util";
import { BridgeDTO } from "../common/bridge.dto";
import { Bridge } from "../common/bridge.model";
import { Label } from "src/app/interfaces/label.interface";
import { LayerDTO } from "../common/layer.dto";
import { MaterialInfo } from "src/app/interfaces/mat.info.interface";
import { LayerOrient } from "src/app/config/app.const";

export class E07Junction extends Junction {
  floorTop = 475;
  constructor(private dto: JnDTO) {
    super();
    this.generateShapes();
  }
  get jnWidth(): number {
    return 1250; // max possible thickness
  }
  get jnHeight(): number {
    return 1250;
  }
  get tmWidthFactor(): number {
    return 1;
  }
  get tmHeightFactor(): number {
    return 1;
  }
  get id(): string {
    return this.dto._id;
  }
  get name(): string {
    return this.dto.name;
  }
  set name(s: string) {
    this.dto.name = s;
  }
  get jntype(): string {
    return this.dto.jntype;
  }
  get jnData(): E07DTO {
    return this.dto.jndata as E07DTO;
  }
  get jnDto(): JnDTO {
    return this.dto;
  }
  get owner(): string {
    return this.jnDto.owner;
  }
  get calcId(): string {
    let cid = "E07@";
    this.jnData.layers.forEach((lyr: LayerDTO, index: number) => {
      if (this.isLayerHidden(index)) {
        // cid += "@_@_@_@";
        cid += "@_@_@";
      } else {
        const m: MaterialInfo = E07LayerOptions[index][lyr.matIndex];
        const code = m.code;
        const thk = m.thickness_options[lyr.thkIndex].val;
        const kval = m.kvalue_options[lyr.kvlIndex];
        // cid += `@${code}@${thk}@${kval}@`;
        cid += `@${thk}@${kval}@`;
      }
    });
    cid += "@@";
    this.jnData.flrLayers.forEach((lyr: LayerDTO, index: number) => {
      const m: MaterialInfo = E07FloorLayerOptions[index][lyr.matIndex];
      const code = m.code;
      const thk = m.thickness_options[lyr.thkIndex].val;
      const kval = m.kvalue_options[lyr.kvlIndex];
      // cid += `@${code}@${thk}@${kval}@`;
      cid += `@${thk}@${kval}@`;
    });
    return cid + "@";
  }
  get laminateLayerIsOn(): boolean {
    return this.jnData.layers[1].matIndex !== 0; // 0 : Plasterboard
  }
  get blockworkIsOn(): boolean {
    return [0, 1, 2, 3, 4].includes(this.jnData.layers[5].matIndex); // Blockworks
  }
  get totalThickness(): number {
    return this.jnData.layers.reduce((x, lyrDTO, i) => {
      const m: MaterialInfo = E07LayerOptions[i][lyrDTO.matIndex];
      return this.isLayerHidden(i)
        ? x
        : x + m.thickness_options[lyrDTO.thkIndex].val;
    }, 0);
  }
  get flrLyr2Left(): number {
    return this.jnData.layers.reduce((x, lyrDTO, i) => {
      const m: MaterialInfo = E07LayerOptions[i][lyrDTO.matIndex];
      return this.isLayerHidden(i) || i < 4
        ? x
        : x + m.thickness_options[lyrDTO.thkIndex].val;
    }, 0);
  }
  get flrLyr2Top(): number {
    const lyrDTO = this.jnData.flrLayers[0];
    const m: MaterialInfo = E07FloorLayerOptions[0][lyrDTO.matIndex];
    const thk = m.thickness_options[lyrDTO.thkIndex].val;
    return this.floorTop + thk;
  }
  get flrLayerLeft(): number {
    return this.jnData.layers.reduce((x, lyrDTO, i) => {
      const m: MaterialInfo = E07LayerOptions[i][lyrDTO.matIndex];
      return this.isLayerHidden(i) || i < 3
        ? x
        : x + m.thickness_options[lyrDTO.thkIndex].val;
    }, 0);
  }
  get calcInput(): any {
    const layersy = [];
    this.jnData.layers.forEach((lyr: LayerDTO, index: number) => {
      if (!this.isLayerHidden(index)) {
        const m: MaterialInfo = E07LayerOptions[index][lyr.matIndex];
        const layer: any = {
          material: m.material,
          kvalue: m.kvalue_options[lyr.kvlIndex],
          thickness: mmToM(m.thickness_options[lyr.thkIndex].val),
        };
        layersy.push(layer);
      }
    });
    // add rse and rsi
    layersy[layersy.length - 1].rse = 0.04;
    layersy[0].rsi = 0.13;

    const layersx = [];
    this.jnData.flrLayers.forEach((flyr: LayerDTO, index: number) => {
      const m: MaterialInfo = E07FloorLayerOptions[index][flyr.matIndex];
      const layer: any = {
        material: m.material,
        kvalue: m.kvalue_options[flyr.kvlIndex],
        thickness: mmToM(m.thickness_options[flyr.thkIndex].val),
      };
      layersx.push(layer);
    });
    // add rse and rsi
    layersx[layersx.length - 1].rse = 0.1;
    layersx[0].rsi = 0.17;

    // format bridges data
    const bridges = [];
    // Tarik uses his predefined bridge values. Only one bridge is sent to server to avoid error
    // k = 0.12 xs= 0.3475, xe= 0.3625, ys= 1.3175, ye= 1.4175
    bridges.push({
      material: "Timber",
      kvalue: 0.12,
      xstart: 0.3475,
      xend: 0.3625,
      ystart: 1.3175,
      yend: 1.4175,
    });

    // create input
    return {
      _id: this.calcId,
      reqid: getReqId(),
      jntype: "E07",
      frame: "timber",
      rotate: 90,
      size: [600, 600],
      layersy: layersy.reverse(),
      layersx: layersx,
      bridges,
      intemp: 20,
      extemp: 0,
    };
  }
  getLayerWidth(lyrIndex: number): number {
    const layerData = this.jnData.layers[lyrIndex];
    const m: MaterialInfo = E07LayerOptions[lyrIndex][layerData.matIndex];
    return m.thickness_options[layerData.thkIndex].val;
  }
  isLayerHidden(i: number): boolean {
    return (
      (i === 0 && !this.laminateLayerIsOn) || (i === 6 && !this.blockworkIsOn)
    );
  }
  public generateShapes(): void {
    this.layerParts = [];
    this.generateLayerParts();
    this.generateFlrLayerParts();
    this.generateBridges();
  }
  public generateLayerParts(): void {
    let bp = this.totalThickness;
    this.jnData.layers.forEach((lyrDTO: LayerDTO, i: number) => {
      if (!this.isLayerHidden(i)) {
        const m: MaterialInfo = E07LayerOptions[i][lyrDTO.matIndex];
        const thk = m.thickness_options[lyrDTO.thkIndex].val;
        bp = bp - thk;
        const points = [
          { x: bp, y: 1 } as Point,
          { x: bp + thk, y: 1 } as Point,
          { x: bp + thk, y: this.jnHeight - 1 } as Point,
          { x: bp, y: this.jnHeight - 1 } as Point,
        ];
        this.layerParts.push(
          new PolyLayerPart(i, points, m.background, LayerOrient.Y)
        );
      }
    });
  }
  private generateFlrLayerParts(): void {
    let bp = this.floorTop;
    this.jnData.flrLayers.forEach((lyrDTO: LayerDTO, i: number) => {
      const m: MaterialInfo = E07FloorLayerOptions[i][lyrDTO.matIndex];
      const thk = m.thickness_options[lyrDTO.thkIndex].val;
      const left = i === 1 ? this.flrLyr2Left : this.flrLayerLeft;
      const points = [
        { x: left, y: bp } as Point,
        { x: this.jnWidth - 1, y: bp } as Point,
        { x: this.jnWidth - 1, y: bp + thk } as Point,
        { x: left, y: bp + thk } as Point,
      ];
      this.layerParts.push(
        new PolyLayerPart(i, points, m.background, LayerOrient.X)
      );
      bp = bp + thk;
    });
  }
  private generateBridges(): void {
    this.bridges = [];
    // Bridge [1]
    const b1: BridgeDTO = E07Bridges[0];
    [b1.base.x, b1.base.y] = [this.totalThickness, this.floorTop - b1.height];
    this.bridges.push(new Bridge(0, b1.background, b1));
    // Bridge [2 - 11]
    [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].forEach((i) => {
      const b: BridgeDTO = E07Bridges[i];
      b.base.x = this.flrLyr2Left + 100 * i - 50;
      b.base.y = this.flrLyr2Top + 45;
      this.bridges.push(new Bridge(i, b.background, b));
    });
  }
  public generateLabels(): void {
    this.labels = [];
    let start = 0;
    // layer [0]
    let i = 0;
    let m = this.getMaterial(i);
    if (m) {
      this.labels[start + i] = {
        text: m,
        start: { x: this.calculateLeft(i), y: 45 } as Point,
        mid: { x: this.calculateLeft(i), y: 45 } as Point,
        end: { x: this.jnWidth, y: 45 } as Point,
      } as Label;
    }
    // layer [1]
    i = 1;
    m = this.getMaterial(i);
    if (m) {
      this.labels[start + i] = {
        text: m,
        start: { x: this.calculateLeft(i), y: 120 } as Point,
        mid: { x: this.calculateLeft(i), y: 120 } as Point,
        end: { x: this.jnWidth, y: 120 } as Point,
      } as Label;
    }
    // layer [2]
    start = 6;
    i = 2;
    m = this.getMaterial(i);
    if (m) {
      this.labels[start + i] = {
        text: m,
        start: { x: this.calculateLeft(i), y: 875 } as Point,
        mid: { x: this.calculateLeft(i) + 200, y: 820 } as Point,
        end: { x: this.jnWidth, y: 820 } as Point,
      } as Label;
    }
    // layer [3]
    i = 3;
    m = this.getMaterial(i);
    if (m) {
      this.labels[start + i] = {
        text: m,
        start: { x: this.calculateLeft(i), y: 925 } as Point,
        mid: { x: this.calculateLeft(i), y: 925 } as Point,
        end: { x: this.jnWidth, y: 925 } as Point,
      } as Label;
    }
    // layer [4]
    i = 4;
    m = this.getMaterial(i);
    if (m) {
      this.labels[start + i] = {
        text: m,
        start: { x: this.calculateLeft(i), y: 1030 } as Point,
        mid: { x: this.calculateLeft(i), y: 1030 } as Point,
        end: { x: this.jnWidth, y: 1030 } as Point,
      } as Label;
    }
    // layer [5]
    i = 5;
    m = this.getMaterial(i);
    if (m) {
      this.labels[start + i] = {
        text: m,
        start: { x: this.calculateLeft(i), y: 1120 } as Point,
        mid: { x: this.calculateLeft(i), y: 1120 } as Point,
        end: { x: this.jnWidth, y: 1120 } as Point,
      } as Label;
    }
    // layer [6]
    i = 6;
    m = this.getMaterial(i);
    if (m) {
      this.labels[start + i] = {
        text: m,
        start: { x: this.calculateLeft(i), y: 1230 } as Point,
        mid: { x: this.calculateLeft(i), y: 1230 } as Point,
        end: { x: this.jnWidth, y: 1230 } as Point,
      } as Label;
    }
    // Fixed label
    this.labels[2] = {
      text: "12.5mm softwood k=0.12",
      start: { x: this.totalThickness + 5, y: 410 } as Point,
      mid: { x: 800, y: 225 } as Point,
      end: { x: this.jnWidth, y: 225 } as Point,
    } as Label;
    // Fixed label
    const flyr0: LayerDTO = this.jnData.flrLayers[0];
    const mx0: MaterialInfo = E07FloorLayerOptions[0][flyr0.matIndex];
    const thkx0 = mx0.thickness_options[flyr0.thkIndex].val;
    this.labels[3] = {
      text: "140mm Slab k=2.0 W/mK",
      start: { x: 800, y: 490 + thkx0 } as Point,
      mid: { x: 900, y: 320 } as Point,
      end: { x: this.jnWidth, y: 320 } as Point,
    } as Label;
    // Fixed label
    this.labels[7] = {
      text: "115mm Cavity k=0.658",
      start: { x: 1050, y: 725 } as Point,
      mid: { x: 1050, y: 725 } as Point,
      end: { x: this.jnWidth, y: 725 } as Point,
    } as Label;
  }
  private getMaterial(i: number): string {
    if (this.isLayerHidden(i)) return null;
    const layerDTO: LayerDTO = this.jnData.layers[i];
    const m: MaterialInfo = E07LayerOptions[i][layerDTO.matIndex];
    return m.material;
  }
  private getThickness(i: number): number {
    if (this.isLayerHidden(i)) return 0;
    const layerDTO: LayerDTO = this.jnData.layers[i];
    const m: MaterialInfo = E07LayerOptions[i][layerDTO.matIndex];
    return m.thickness_options[layerDTO.thkIndex].val;
  }
  private calculateLeft(i: number): number {
    let w = 1;
    for (let j = 6; j > i; j--) {
      w += this.getThickness(j);
    }
    return w + this.getThickness(i) / 2;
  }
}
