import { Component, Input, OnInit } from "@angular/core";
import { a4LandscapeSizeMM } from "src/app/config/app.const";
import { round } from "src/app/helper/app.util";
import { Junction } from "src/app/junctions/common/base.junction.model";
import { savedTmData } from "src/app/store/global.store";
import {
  E01Checklist,
  E01Details,
  E01LayerOptions,
} from "../../../../config/e01.options";
import {
  E03Checklist,
  E03Details,
  E03LayerOptions,
} from "../../../../config/e03.options";
import {
  E04Checklist,
  E04Details,
  E04LayerOptions,
} from "../../../../config/e04.options";
import {
  E05AChecklist,
  E05ADetails,
  E05ALayerOptions,
} from "../../../../config/e05a.options";
import {
  E05BChecklist,
  E05BDetails,
  E05BLayerOptions,
} from "../../../../config/e05b.options";
import {
  E10Checklist,
  E10Details,
  E10LayerOptions,
} from "../../../../config/e10.options";
import { E16Checklist, E16LayerOptions } from "../../../../config/e16.options";

@Component({
  selector: "app-pdf-first-page",
  templateUrl: "./pdf-first-page.component.html",
  styleUrls: ["./pdf-first-page.component.css"],
})
export class PdfFirstPageComponent implements OnInit {
  a4 = a4LandscapeSizeMM;
  checkListItems = [];
  detailsItems = [];

  @Input() jn: Junction;
  today: number = Date.now();

  constructor() {}

  ngOnInit(): void {
    this.getChecklist(this.jn.jntype);
  }

  get layers(): [] {
    return this.jn.jntype.startsWith("E05")
      ? this.jn.calcInput.layersy
      : this.jn.calcInput.layers;
  }
  get cavityMaterial(): string {
    const i = this.jn.jnDto.jndata.layers[4].matIndex;
    if (this.jn.jntype === "E01") return E01LayerOptions[4][i].material;
    if (this.jn.jntype === "E03") return E03LayerOptions[4][i].material;
    if (this.jn.jntype === "E04") return E04LayerOptions[4][i].material;
    if (this.jn.jntype === "E05A") return E05ALayerOptions[4][i].material;
    if (this.jn.jntype === "E05B") return E05BLayerOptions[4][i].material;
    if (this.jn.jntype === "E10") return E10LayerOptions[4][i].material;
    if (this.jn.jntype === "E16") return E16LayerOptions[4][i].material;
    return "Unknown";
  }
  get calcOutput(): any {
    return savedTmData[this.jn.calcId];
  }
  get psiValue() {
    return this.calcOutput ? round(this.calcOutput.psivalue) : "-";
  }
  get fValue() {
    return this.calcOutput ? round(this.calcOutput.fvalue) : "-";
  }
  private getChecklist(code: string) {
    switch (code) {
      case "E01":
        this.checkListItems = E01Checklist;
        this.detailsItems = E01Details;
        break;
      case "E03":
        this.checkListItems = E03Checklist;
        this.detailsItems = E03Details;
        break;
      case "E04":
        this.checkListItems = E04Checklist;
        this.detailsItems = E04Details;
        break;
      case "E05A":
        this.checkListItems = E05AChecklist;
        this.detailsItems = E05ADetails;
        break;
      case "E05B":
        this.checkListItems = E05BChecklist;
        this.detailsItems = E05BDetails;
        break;
      case "E10":
        this.checkListItems = E10Checklist;
        this.detailsItems = E10Details;
        break;
      case "E16":
        this.checkListItems = E16Checklist;
        break;

      default:
        console.log("Not found!");
        break;
    }
  }
}
