<h2>Generates and Download the input combinations</h2>
<button mat-stroked-button color="primary" (click)="printE01Inputs()">
  E01
</button>
<button mat-stroked-button color="primary" (click)="printE02AInputs()">
  E02A
</button>
<button mat-stroked-button color="primary" (click)="printE03Inputs()">
  E03
</button>
<button mat-stroked-button color="primary" (click)="printE04Inputs()">
  E04
</button>
<button mat-stroked-button color="primary" (click)="printE05AInputs()">
  E05A
</button>
<button mat-stroked-button color="primary" (click)="printE05BInputs()">
  E05B
</button>
<button mat-stroked-button color="primary" (click)="printE06Inputs()">
  E06
</button>
<button mat-stroked-button color="primary" (click)="printE07Inputs()">
  E07
</button>
<button mat-stroked-button color="primary" (click)="printE10Inputs()">
  E10
</button>
<button mat-stroked-button color="primary" (click)="printE11Inputs()">
  E11
</button>
<button mat-stroked-button color="primary" (click)="printE12Inputs()">
  E12
</button>
<button mat-stroked-button color="primary" (click)="printE13Inputs()">
  E13
</button>
<button mat-stroked-button color="primary" (click)="printE16Inputs()">
  E16
</button>
<button mat-stroked-button color="primary" (click)="printE17Inputs()">
  E17
</button>
