import { createNewJnId } from "src/app/helper/app.util";
import { JnDTO } from "../common/jn.dto";
import { LayerDTO } from "../common/layer.dto";
import { E03DTO } from "./e03.dto";
import { E03Junction } from "./e03.junction.model";

export function getNewE03Junction(): E03Junction {
  const jnDTO = {
    _id: createNewJnId(),
    name: "E-03 Window Still",
    jntype: "E03",
    jndata: {
      layers: [
        {
          matIndex: 0,
          thkIndex: 0,
          kvlIndex: 0,
        } as LayerDTO,
        {
          matIndex: 0,
          thkIndex: 0,
          kvlIndex: 0,
        } as LayerDTO,
        {
          matIndex: 0,
          thkIndex: 0,
          kvlIndex: 0,
        } as LayerDTO,
        {
          matIndex: 0,
          thkIndex: 0,
          kvlIndex: 0,
        } as LayerDTO,
        {
          matIndex: 2,
          thkIndex: 2,
          kvlIndex: 0,
        } as LayerDTO,
        {
          matIndex: 5,
          thkIndex: 0,
          kvlIndex: 0,
        } as LayerDTO,
        {
          matIndex: 0,
          thkIndex: 0,
          kvlIndex: 0,
        } as LayerDTO,
      ],
    } as E03DTO,
  } as JnDTO;
  return new E03Junction(jnDTO);
}
