import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';

import { catchError, map, tap } from 'rxjs/operators';
import { BehaviorSubject, Observable, of } from 'rxjs';

import { User } from '../../interfaces/user/user.interface';
import { UserLoginDetials } from '../../interfaces/user/userLoginDetials.interface';
import { Router } from '@angular/router';
import { RegisterUserDetails } from 'src/app/interfaces/user/registerUserDetails.interface';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  protected userSubject$: BehaviorSubject<User> = new BehaviorSubject<User>(null);

  constructor(
    private readonly httpService: HttpClient,
    private readonly router: Router,
  ) {
    this.getCurrentUser().subscribe();
  }

  /**
   * Function to return the User Behaviour Subject as Observable.
   * @returns {Observable} Observable of type User.
   */
  getCurrentUser$ = (): Observable<User> => this.userSubject$.asObservable();

  /**
  * Function to return the User Behaviour Subject value.
  * @returns User object.
  */
  get userBeahaviourSubject(): User { return this.userSubject$.getValue() };

  /**
  * Login function to send the user credentials to the BE API.
  * @param loginDetails the user credentials (email and password).
  * @returns Observable of type User.
  */
  login(loginDetails: UserLoginDetials): Observable<User> {
    return this.httpService.post<User>(`authentication/login`, loginDetails)
      .pipe(tap(
        {
          next: data => {
            this.userSubject$.next(data);
            this.router.navigate(['']);
          },
          error: (err: HttpErrorResponse) => console.error(err.error?.message ?? 'Ops, there is a problem with your request!', 3000, 'Ok')
        }
      ));
  }

  /**
   * Logout function to remove existing cookies if available
   * and send the user to the login page.
   * @returns Observable to subscribe to.
   */
  logout(): Observable<any> {
    return this.httpService.get<any>(`authentication/logout`)
      .pipe(tap({
        next: () => {
          this.userSubject$.next(undefined)
          // this.router.navigate(['/login']);
        },
        error: (err: HttpErrorResponse) => console.error(err.error?.message ?? 'Ops, there is a problem with your request!', 3000, 'Ok')
      }));
  }

  /**
   * Function to check if the user is currently logged in.
   * @returns Observable of type boolean.
   */
  isLoggedIn$ = (): Observable<boolean> => {
    if (!this.userSubject$.value) {
      return this.getCurrentUser().pipe(
        map(user => !!user),
        catchError(() => of(false))
      );
    } else {
      return of(true);
    }
  }

  isUserAdmin$ = (): Observable<boolean> => this.httpService.get<boolean>('authentication/admin-role');

  registerUser(userDetails: RegisterUserDetails): Observable<User> {
    return this.httpService.post<User>('authentication/register', userDetails);
  }

  requestPasswordReset(email: string): Observable<any> {
    return this.httpService.post('authentication/requestPasswordReset', email);
  }

  resetPassword(resetPasswordDetails: {
    token: string;
    password: string;
    confirmPassword: string;
  }) {
    return this.httpService.post('authentication/resetPassword', resetPasswordDetails);
  }

  private getCurrentUser(): Observable<User> {
    if (this.userSubject$.value) {
      return of(this.userSubject$.value);
    }
    else if (!this.userSubject$.value) {
      return this.httpService.get<User>(`authentication`)
        .pipe(tap({
          next: user => { this.userSubject$.next(user); },
          error: () => this.refresh().subscribe(),
        }));
    }
    else {
      return of(undefined);
    }
  }

  /**
   * Function to refresh the user credentials if there is a refresh token cookie available.
   * @returns Observable of type User to subscribe to.
   */
  private refresh = (): Observable<User> => this.httpService.get<User>(`authentication/refresh`)
    .pipe(
      tap({
        next: user => {
          this.userSubject$.next(user);
          // this.router.navigate(['']);
        },
        error: () => {
          this.userSubject$.next(undefined)
        }
      }),
    );
}
