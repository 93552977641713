<div class="container-fluid" (click)="clickedOutside()">
  <div class="row">
    <div id="left-area-of-jn-display" class="col-md-7">
      <div>
        <app-e01-editor *ngIf="select('E01')" [e01]="jn"></app-e01-editor>
        <app-e02a-editor *ngIf="select('E02A')" [e02a]="jn"></app-e02a-editor>
        <app-e03-editor *ngIf="select('E03')" [e03]="jn"></app-e03-editor>
        <app-e04-editor *ngIf="select('E04')" [e04]="jn"></app-e04-editor>
        <app-e05a-editor *ngIf="select('E05A')" [e05a]="jn"></app-e05a-editor>
        <app-e05b-editor *ngIf="select('E05B')" [e05b]="jn"></app-e05b-editor>
        <app-e06-editor *ngIf="select('E06')" [e06]="jn"></app-e06-editor>
        <app-e07-editor *ngIf="select('E07')" [e07]="jn"></app-e07-editor>
        <app-e10-editor *ngIf="select('E10')" [e10]="jn"></app-e10-editor>
        <app-e11-editor *ngIf="select('E11')" [e11]="jn"></app-e11-editor>
        <app-e12-editor *ngIf="select('E12')" [e12]="jn"></app-e12-editor>
        <app-e13-editor *ngIf="select('E13')" [e13]="jn"></app-e13-editor>
        <app-e16-editor *ngIf="select('E16')" [e16]="jn"></app-e16-editor>
        <app-e17-editor *ngIf="select('E17')" [e17]="jn"></app-e17-editor>
      </div>
    </div>
    <div id="right-area-of-jn-display" class="col-md-5">
      <ng-container>
        <div id="jn-container" class="jn-container">
          <app-jn-svg-container
            *ngIf="jn && view('junction')"
            [jn]="jn"
          ></app-jn-svg-container>
          <app-thermal-model
            *ngIf="jn && view('temperature')"
            [jn]="jn"
          ></app-thermal-model>
        </div>
      </ng-container>
      <app-jn-result [jn]="jn"></app-jn-result>
    </div>
  </div>
</div>
