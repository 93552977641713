import { JnDTO } from "../common/jn.dto";
import { Junction } from "../common/base.junction.model";
import { E04DTO } from "./e04.dto";
import { Point } from "src/app/interfaces/point.interface";
import { PolyLayerPart } from "../common/poly.layer.part.model";
import { E04Bridges, E04LayerOptions } from "src/app/config/e04.options";
import { getReqId, mmToM } from "src/app/helper/app.util";
import { BridgeDTO } from "../common/bridge.dto";
import { Bridge } from "../common/bridge.model";
import { Label } from "src/app/interfaces/label.interface";
import { LayerDTO } from "../common/layer.dto";
import { MaterialInfo } from "src/app/interfaces/mat.info.interface";

export class E04Junction extends Junction {
  pvcThickness = 2; // mm
  spaceForLabel = 150;
  constructor(private dto: JnDTO) {
    super();
    this.generateShapes();
  }
  get jnWidth(): number {
    return 750;
  }
  get jnHeight(): number {
    return 700; // max possible thickness
  }
  get tmWidthFactor(): number {
    return this.jnWidth / 950;
  }
  get tmHeightFactor(): number {
    return this.totalThickness / 750;
  }
  get id(): string {
    return this.dto._id;
  }
  get name(): string {
    return this.dto.name;
  }
  set name(s: string) {
    this.dto.name = s;
  }
  get jntype(): string {
    return this.dto.jntype;
  }
  get jnData(): E04DTO {
    return this.dto.jndata as E04DTO;
  }
  get jnDto(): JnDTO {
    return this.dto;
  }
  get owner(): string {
    return this.jnDto.owner;
  }
  get calcId(): string {
    let cid = "E04@";
    this.jnData.layers.forEach((lyr: LayerDTO, index: number) => {
      if (this.isLayerHidden(index)) {
        cid += "@_@_@_@";
      } else {
        const m: MaterialInfo = E04LayerOptions[index][lyr.matIndex];
        const code = m.code;
        const thk = m.thickness_options[lyr.thkIndex].val;
        const kval = m.kvalue_options[lyr.kvlIndex];
        cid += `@${code}@${thk}@${kval}@`;
      }
    });
    return cid + "@";
  }
  get laminateLayerIsOn(): boolean {
    return this.jnData.layers[1].matIndex !== 0; // 0 : Plasterboard
  }
  get blockworkIsOn(): boolean {
    return [0, 1, 2, 3, 4].includes(this.jnData.layers[5].matIndex); // Blockworks
  }
  get totalThickness(): number {
    return this.jnData.layers.reduce((x, lyrDTO, i) => {
      const m: MaterialInfo = E04LayerOptions[i][lyrDTO.matIndex];
      return this.isLayerHidden(i)
        ? x
        : x + m.thickness_options[lyrDTO.thkIndex].val;
    }, 0);
  }
  get calcInput(): any {
    const layers = [];
    this.jnData.layers.forEach((lyr: LayerDTO, index: number) => {
      if (!this.isLayerHidden(index)) {
        const m: MaterialInfo = E04LayerOptions[index][lyr.matIndex];
        const layer: any = {
          material: m.material,
          kvalue: m.kvalue_options[lyr.kvlIndex],
          thicknessx: mmToM(m.thickness_options[lyr.thkIndex].val),
        };
        layers.push(layer);
      }
    });
    // add rse and rsi
    layers[layers.length - 1].rse = 0.04;
    layers[0].rsi = 0.13;
    // format bridges data
    // Tarik uses his predefined bridge values. Bridge is sent to server to avoid error but not used
    const bridges = [];
    this.bridges.forEach((bridge: Bridge) => {
      bridges.push({
        material: bridge.material,
        kvalue: bridge.kvalue,
        xstart: mmToM(bridge.base.x),
        xend: mmToM(bridge.base.x + bridge.width),
        ystart: mmToM(this.jnHeight - bridge.base.y - bridge.height),
        yend: mmToM(this.jnHeight - bridge.base.y),
      });
    });
    // create input
    return {
      _id: this.calcId,
      reqid: getReqId(),
      jntype: "E04",
      frame: "timber",
      rotate: 90,
      size: [600, 600],
      layers: layers.reverse(),
      bridges,
      intemp: 20,
      extemp: 0,
    };
  }
  get top(): number {
    return (this.jnHeight - this.totalThickness) / 2;
  }
  getLayerHeight(lyrIndex: number): number {
    const layerData = this.jnData.layers[lyrIndex];
    const m: MaterialInfo = E04LayerOptions[lyrIndex][layerData.matIndex];
    return m.thickness_options[layerData.thkIndex].val;
  }
  isLayerHidden(i: number): boolean {
    return (
      (i === 0 && !this.laminateLayerIsOn) || (i === 6 && !this.blockworkIsOn)
    );
  }
  public generateShapes(): void {
    this.generateLayerParts();
    this.generateBridges();
  }
  public generateLayerParts(): void {
    this.layerParts = [];
    let bp: Point = {
      x: E04Bridges[0].width + E04Bridges[1].width,
      y: this.top + this.totalThickness,
    };
    this.jnData.layers.forEach((lyrDTO: LayerDTO, i: number) => {
      if (!this.isLayerHidden(i)) {
        const m: MaterialInfo = E04LayerOptions[i][lyrDTO.matIndex];
        const thk = m.thickness_options[lyrDTO.thkIndex].val;
        const x = [0, 1].includes(i) ? 0 : bp.x;
        const w = this.jnWidth - this.spaceForLabel - x;
        bp.y = bp.y - thk;
        const points = [
          { x: x, y: bp.y } as Point,
          { x: x + w, y: bp.y } as Point,
          { x: x + w, y: bp.y + thk } as Point,
          { x: x, y: bp.y + thk } as Point,
        ];
        this.layerParts.push(new PolyLayerPart(i, points, m.background));
      }
    });
  }
  private generateBridges(): void {
    this.bridges = [];
    // Bridge [1]
    const b1: BridgeDTO = E04Bridges[0];
    b1.height =
      this.getLayerHeight(1) +
      this.getLayerHeight(2) +
      this.getLayerHeight(3) +
      this.getLayerHeight(4) * 0.7;
    b1.base.x = 0;
    b1.base.y =
      this.top +
      (this.blockworkIsOn ? this.getLayerHeight(6) : 0) +
      this.getLayerHeight(5) +
      this.getLayerHeight(4) * 0.3;
    this.bridges.push(new Bridge(0, b1.background, b1));
    // Bridge [2]
    const b2: BridgeDTO = E04Bridges[1];
    b2.height =
      this.getLayerHeight(2) +
      this.getLayerHeight(3) +
      this.getLayerHeight(4) * 0.7;
    b2.base.x = E04Bridges[0].width;
    b2.base.y =
      this.top +
      (this.blockworkIsOn ? this.getLayerHeight(6) : 0) +
      this.getLayerHeight(5) +
      this.getLayerHeight(4) * 0.3;
    this.bridges.push(new Bridge(1, b2.background, b2));
    // Bridge [3]
    const b3: BridgeDTO = E04Bridges[2];
    b3.height = this.getLayerHeight(4);
    b3.base.x = E04Bridges[0].width + E04Bridges[1].width;
    b3.base.y =
      this.top +
      (this.blockworkIsOn ? this.getLayerHeight(6) : 0) +
      this.getLayerHeight(5);
    this.bridges.push(new Bridge(2, b3.background, b3));
    // Bridge [4]
    const b4: BridgeDTO = E04Bridges[3];
    b4.height = this.getLayerHeight(4) - this.pvcThickness * 2;
    b4.base.x = E04Bridges[0].width + E04Bridges[1].width + this.pvcThickness;
    b4.base.y =
      this.top +
      (this.blockworkIsOn ? this.getLayerHeight(6) : 0) +
      this.getLayerHeight(5) +
      this.pvcThickness;
    this.bridges.push(new Bridge(3, b4.background, b4));
  }
  public generateLabels(): void {
    this.labels = [];
    // Fixed label
    let t = this.top + this.getThickness(1) + this.getThickness(0);
    this.labels[0] = {
      text: "Close the cavity with insulation less than or equal to 0.026W/mK",
      start: { x: 50, y: t } as Point,
      mid: { x: 250, y: 10 } as Point,
      end: { x: this.jnWidth, y: 10 } as Point,
    } as Label;

    let start = 3;
    const w = this.jnWidth - this.spaceForLabel - 10;
    t = this.top;
    // layer [0]
    let i = 0;
    let m = this.getMaterial(i);
    let thk = this.getThickness(i);
    if (m) {
      this.labels[start + i] = {
        text: m,
        start: { x: w, y: t + thk / 2 } as Point,
        mid: { x: w, y: t + thk / 2 } as Point,
        end: { x: this.jnWidth, y: 175 } as Point,
      } as Label;
    }
    t += thk;
    // layer [1]
    i = 1;
    m = this.getMaterial(i);
    thk = this.getThickness(i);
    if (m) {
      this.labels[start + i] = {
        text: m,
        start: { x: w, y: t + thk / 2 } as Point,
        mid: { x: w, y: t + thk / 2 } as Point,
        end: { x: this.jnWidth, y: 235 } as Point,
      } as Label;
    }
    t += thk;
    // layer [2]
    i = 2;
    m = this.getMaterial(i);
    thk = this.getThickness(i);
    if (m) {
      this.labels[start + i] = {
        text: m,
        start: { x: w, y: t + thk / 2 } as Point,
        mid: { x: w, y: t + thk / 2 } as Point,
        end: { x: this.jnWidth, y: 285 } as Point,
      } as Label;
    }
    t += thk;
    // layer [3]
    i = 3;
    m = this.getMaterial(i);
    thk = this.getThickness(i);
    if (m) {
      this.labels[start + i] = {
        text: m,
        start: { x: w, y: t + thk / 2 } as Point,
        mid: { x: w, y: t + thk / 2 } as Point,
        end: { x: this.jnWidth, y: 345 } as Point,
      } as Label;
    }
    t += thk;
    // layer [4]
    i = 4;
    m = this.getMaterial(i);
    thk = this.getThickness(i);
    if (m) {
      this.labels[start + i] = {
        text: m,
        start: { x: w, y: t + thk / 2 } as Point,
        mid: { x: w, y: t + thk / 2 } as Point,
        end: { x: this.jnWidth, y: 405 } as Point,
      } as Label;
    }
    t += thk;
    // layer [5]
    i = 5;
    m = this.getMaterial(i);
    thk = this.getThickness(i);
    if (m) {
      this.labels[start + i] = {
        text: m,
        start: { x: w, y: t + thk / 2 } as Point,
        mid: { x: w, y: t + thk / 2 } as Point,
        end: { x: this.jnWidth, y: 445 } as Point,
      } as Label;
    }
    t += thk;
    // layer [6]
    i = 6;
    m = this.getMaterial(i);
    thk = this.getThickness(i);
    if (m) {
      this.labels[start + i] = {
        text: m,
        start: { x: w, y: t + thk / 2 } as Point,
        mid: { x: w, y: t + thk / 2 } as Point,
        end: { x: this.jnWidth, y: 510 } as Point,
      } as Label;
    }
  }
  private getMaterial(i: number): string {
    const r = 6 - i; // reverse index
    if (this.isLayerHidden(r)) return null;
    const layerDTO: LayerDTO = this.jnData.layers[r];
    const m: MaterialInfo = E04LayerOptions[r][layerDTO.matIndex];
    return m.material;
  }
  private getThickness(i: number): number {
    const r = 6 - i; // reverse index
    if (this.isLayerHidden(r)) return 0;
    const layerDTO: LayerDTO = this.jnData.layers[r];
    const m: MaterialInfo = E04LayerOptions[r][layerDTO.matIndex];
    return m.thickness_options[layerDTO.thkIndex].val;
  }
}
