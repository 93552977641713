import { Component, Input } from "@angular/core";
import { a4LandscapeSizeMM } from "src/app/config/app.const";
import { Junction } from "src/app/junctions/common/base.junction.model";

@Component({
  selector: "app-pdf-header",
  templateUrl: "./pdf-header.component.html",
  styleUrls: ["./pdf-header.component.css"],
})
export class PdfHeaderComponent {
  @Input() jn: Junction;

  a4 = a4LandscapeSizeMM;

  constructor() {}

  get time(): Date {
    return new Date();
  }
}
