import { HttpErrorResponse } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  UntypedFormControl,
  Validators,
} from "@angular/forms";
import { UserLoginDetials } from "src/app/interfaces/user/userLoginDetials.interface";

import { AuthenticationService } from "../../../services/authentication/authentication.service";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./../auth.css"],
})
export class LoginComponent implements OnInit {
  loginForm: UntypedFormGroup;
  passwordResetForm: UntypedFormGroup;
  loading = false;
  showPasswordResetForm = false;
  errorMessage: string;

  constructor(
    private readonly formBuilder: UntypedFormBuilder,
    readonly authService: AuthenticationService
  ) {}

  ngOnInit(): void {
    this.loginForm = this.formBuilder.group({
      email: new UntypedFormControl("", [Validators.email, Validators.required]),
      password: new UntypedFormControl("", [
        Validators.minLength(8),
        Validators.maxLength(25),
        Validators.required,
        //Validator is for the letters (both uppercase and lowercase) and numbers
        Validators.pattern("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])[a-zA-Z0-9]+$"),
      ]),
    });
  }

  get loginFormControls() {
    return this.loginForm.controls;
  }

  get passwordResetFormControls() {
    return this.passwordResetForm.controls;
  }

  login(): void {
    if (this.loginForm.invalid) {
      return;
    }

    this.authService
      .login(this.loginForm.value as UserLoginDetials)
      .subscribe();
  }

  togglePasswordResetForm = () => {
    this.showPasswordResetForm = true;

    if (!this.passwordResetForm) {
      this.passwordResetForm = this.formBuilder.group({
        email: new UntypedFormControl("", [Validators.email, Validators.required]),
      });
    }
  };

  resetPassword = () => {
    this.loading = true;

    if (this.passwordResetForm.invalid) {
      this.loading = false;
      return;
    }

    this.authService
      .requestPasswordReset(this.passwordResetForm.value)
      .subscribe({
        next: () => {
          setTimeout(() => {
            (this.loading = false), (this.showPasswordResetForm = false);
          }, 2000);
        },
        error: (error: HttpErrorResponse) => {
          this.loading = false;
          this.errorMessage = error.error.message
            ? error.error.message
            : "There was an error with your request!";
        },
      });
  };
}
