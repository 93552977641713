<h1 class="pt-5" style="text-align: center; color: var(--color-navy);">My Saved Junctions</h1>
<div class="row justify-content-center pt-3">
  <div class="col-md-9">
    <table mat-table [dataSource]="savedJns" class="mat-elevation-z8 brs-table">
      <ng-container matColumnDef="position">
        <th mat-header-cell *matHeaderCellDef> No. </th>
        <td mat-cell *matCellDef="let jn; let i = index"> {{i+1}} </td>
      </ng-container>
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef> Junction Name </th>
        <td mat-cell *matCellDef="let jn"> {{ jn.name }} </td>
      </ng-container>
      <ng-container matColumnDef="type">
        <th mat-header-cell *matHeaderCellDef> Junction Type </th>
        <td mat-cell *matCellDef="let jn"> {{ jn.jntype }} </td>
      </ng-container>
      <ng-container matColumnDef="createdOn">
        <th mat-header-cell *matHeaderCellDef> Created On </th>
        <td mat-cell *matCellDef="let jn"> {{ jn.created | date: "medium" }} </td>
      </ng-container>
      <ng-container matColumnDef="lastSaved">
        <th mat-header-cell *matHeaderCellDef> Last Saved </th>
        <td mat-cell *matCellDef="let jn"> {{ jn.saved | date: "medium" }} </td>
      </ng-container>
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef> Last Saved </th>
        <td mat-cell *matCellDef="let jn">
          <button mat-raised-button color="primary" (click)="openJunction(jn._id)" title="Edit Junction">
            <mat-icon aria-hidden="true" aria-label="edit icon">edit</mat-icon>
          </button>
          <button mat-raised-button color="warn" (click)="deleteJunction(jn._id)" title="Delete Junction">
            <mat-icon aria-hidden="true" aria-label="delete icon">delete</mat-icon>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons aria-label="Select page of periodic elements">
    </mat-paginator>
    <div class="action-button-container">
      <button *ngIf="this.authService.isUserAdmin$" (click)="openAnalyticsModal()" class="btn green-btn">Analytics Panel</button>
    </div>
  </div>
</div>