import { createNewJnId } from "src/app/helper/app.util";
import { JnDTO } from "../common/jn.dto";
import { LayerDTO } from "../common/layer.dto";
import { E17DTO } from "./e17.dto";
import { E17Junction } from "./e17.junction.model";

export function getNewE17Junction(): E17Junction {
  const jnDTO = {
    _id: createNewJnId(),
    name: "E-17 Inverted Corner - Normal",
    jntype: "E17",
    jndata: {
      layers: [
        {
          matIndex: 0,
          thkIndex: 0,
          kvlIndex: 0,
        } as LayerDTO,
        {
          matIndex: 0,
          thkIndex: 0,
          kvlIndex: 0,
        } as LayerDTO,
        {
          matIndex: 0,
          thkIndex: 0,
          kvlIndex: 0,
        } as LayerDTO,
        {
          matIndex: 0,
          thkIndex: 0,
          kvlIndex: 0,
        } as LayerDTO,
        {
          matIndex: 2,
          thkIndex: 2,
          kvlIndex: 0,
        } as LayerDTO,
        {
          matIndex: 5,
          thkIndex: 0,
          kvlIndex: 0,
        } as LayerDTO,
        {
          matIndex: 0,
          thkIndex: 0,
          kvlIndex: 0,
        } as LayerDTO,
      ],
    } as E17DTO,
  } as JnDTO;
  return new E17Junction(jnDTO);
}
