<app-svg-class
  *ngFor="let lyrPart of jn.layerParts"
  [className]="lyrPart.className"
  [patternName]="lyrPart.patternName"
  [background]="lyrPart.background"
  [jn]="jn"
></app-svg-class>
<app-svg-class
  *ngFor="let bdg of jn.bridges"
  [className]="bdg.className"
  [patternName]="bdg.patternName"
  [background]="bdg.background"
  [jn]="jn"
></app-svg-class>
<app-svg-class
  *ngFor="let ext of jn.extras"
  [className]="ext.className"
  [patternName]="ext.patternName"
  [background]="ext.background"
  [jn]="jn"
></app-svg-class>
<app-svg-class
  *ngFor="let gnd of jn.grounds"
  [className]="gnd.className"
  [patternName]="gnd.patternName"
  [background]="gnd.background"
  [jn]="jn"
></app-svg-class>

<div class="jn-div-area" [ngStyle]="{ 'height.px': height, 'width.px': width }">
  <app-jn-svg [jn]="jn"></app-jn-svg>
</div>
