import { Component, AfterViewChecked, HostListener } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Title } from "@angular/platform-browser";
import { isValidJnId } from "src/app/helper/app.util";
import { JunctionService } from "src/app/services/junction.service";
import { Junction } from "src/app/junctions/common/base.junction.model";
import { initGlobalData } from "src/app/store/global.store";

@Component({
  selector: "app-calculator",
  templateUrl: "./calculator.component.html",
  styleUrls: ["./../common/common.css"],
})
export class CalculatorComponent implements AfterViewChecked {
  jn: Junction = undefined;
  defaultJnIds = [
    "E01",
    "E02A",
    "E03",
    "E04",
    "E05A",
    "E05B",
    "E06",
    "E07",
    "E10",
    "E11",
    "E12",
    "E13",
    "E16",
    "E17",
  ];

  constructor(
    private title: Title,
    private jnService: JunctionService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    const jnIdQueryParam = this.route.snapshot.queryParamMap.get("id");
    if (jnIdQueryParam === undefined || jnIdQueryParam === null) {
      this.router.navigate(["home"]);
    } else {
      this.getJunction(jnIdQueryParam.trim());
    }
  }
  ngAfterViewChecked(): void {
    if (this.jn)
      this.title.setTitle(
        this.jn.name +
          " | Thermal Modelling Calculator | PSI Values | Knauf Insulation"
      );
  }
  async getJunction(jnId: string): Promise<void> {
    try {
      if (isValidJnId(jnId)) {
        this.jn = await this.jnService.fetchJunctionById(jnId);
      } else if (this.defaultJnIds.includes(jnId.toUpperCase())) {
        this.jn = this.jnService.createNewJunction(jnId);
      } else {
        this.jn = null;
      }
      initGlobalData();
    } catch (er) {
      console.log(er);
    }
  }
  // @HostListener("window:beforeunload", ["$event"])
  // public onPageUnload($event: BeforeUnloadEvent) {
  //   if (!this.jn.isJnSaved) {
  //     $event.returnValue = true;
  //   }
  // }
}
