import {
  Component,
  OnInit,
  AfterViewInit,
  Input,
  ChangeDetectorRef,
} from "@angular/core";
import { calculateViewWidth } from "src/app/helper/app.util";
import { tmCanvas } from "src/app/helper/tm.cnv.util";
import { Junction } from "src/app/junctions/common/base.junction.model";
import { CalcService } from "src/app/services/calc.service";

@Component({
  selector: "app-thermal-model",
  templateUrl: "./thermal-model.component.html",
  styleUrls: ["./thermal-model.component.css"],
})
export class ThermalModelComponent implements OnInit, AfterViewInit {
  @Input() jn: Junction;

  toolTip = {
    mouse: { x: 0, y: 0 },
    visibility: "hidden",
    text: "",
  };

  constructor(
    private calcService: CalcService,
    private changeDetector: ChangeDetectorRef
  ) {}
  ngOnInit(): void {
    this.jn.vw = calculateViewWidth();
  }
  ngAfterViewInit(): void {
    this.drawCanvas();
    this.changeDetector.detectChanges();
  }
  get height() {
    return this.jn.vw;
  }
  get width() {
    return this.jn.vw;
  }
  get displayLoader(): boolean {
    return tmCanvas.displayLoader;
  }
  get canvasClass(): string {
    if (this.jn.jntype.startsWith("E01")) return "th-canvas-e01";
    else if (this.jn.jntype.startsWith("E02A")) return "th-canvas-e02a";
    else if (this.jn.jntype.startsWith("E03")) return "th-canvas-e03";
    else if (this.jn.jntype.startsWith("E04")) return "th-canvas-e04";
    else if (this.jn.jntype.startsWith("E05")) return "th-canvas-e05";
    else if (this.jn.jntype.startsWith("E06")) return "th-canvas-e06";
    else if (this.jn.jntype.startsWith("E07")) return "th-canvas-e07";
    else if (this.jn.jntype.startsWith("E10")) return "th-canvas-e10";
    else if (this.jn.jntype.startsWith("E11")) return "th-canvas-e11";
    else if (this.jn.jntype.startsWith("E12")) return "th-canvas-e12";
    else if (this.jn.jntype.startsWith("E13")) return "th-canvas-e13";
    else if (this.jn.jntype.startsWith("E16")) return "th-canvas-e16";
    else if (this.jn.jntype.startsWith("E17")) return "th-canvas-e17";
    else {
      console.error(`invalid jnType [${this.jn.jntype}]`);
    }
  }
  async drawCanvas(): Promise<void> {
    tmCanvas.clearTmData();
    const p1 = this.calcService.fetchCalcData(this.jn.calcId);
    const p2 = tmCanvas.drawTMCanvasPlaceHolder(this.jn);
    Promise.all([p1, p2]).then(async (values) => {
      const calcData = values[0]; // return of p1
      if (calcData) {
        tmCanvas.setTmData(calcData.tMatrix);
        await tmCanvas.drawTMCanvas(this.jn);
      }
    });
  }
  mouseMove(event: MouseEvent): void {
    tmCanvas.calculateToolTip(this.jn, event, this.toolTip);
  }
  mouseLeave(event: MouseEvent): void {
    this.toolTip.visibility = "hidden";
  }
}
