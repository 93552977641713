import { Component, Input } from "@angular/core";
import {
  getAbsolutePointsSvgFormat,
  getPointsSvgFormat,
} from "src/app/helper/app.util";
import { Label } from "src/app/interfaces/label.interface";
import { Point } from "src/app/interfaces/point.interface";
import { Junction } from "src/app/junctions/common/base.junction.model";
import { LayerPart } from "src/app/junctions/common/base.layer.part.model";
import { Bridge } from "src/app/junctions/common/bridge.model";
import { globals } from "src/app/store/global.store";

@Component({
  selector: "app-jn-svg",
  templateUrl: "./jn-svg.component.svg",
  styleUrls: ["./jn-svg.component.css"],
})
export class JnSvgComponent {
  @Input() jn: Junction;

  constructor() {}

  get vef(): number {
    return (
      this.jn.vw /
      (this.jn.jnWidth > this.jn.jnHeight ? this.jn.jnWidth : this.jn.jnHeight)
    );
  }
  get width(): number {
    return this.jn.jnWidth * this.vef;
  }
  get height(): number {
    return this.jn.jnHeight * this.vef;
  }
  get lineThickness(): number {
    if (
      ["E06", "E07", "E10", "E11", "E12", "E13", "E16", "E17"].includes(
        this.jn.jntype
      )
    )
      return 2;
    else if (["E05A", "E05B"].includes(this.jn.jntype)) return 3;
    else return 1;
  }
  get circleRadius(): number {
    if (
      ["E06", "E07", "E10", "E11", "E12", "E13", "E16", "E17"].includes(
        this.jn.jntype
      )
    )
      return 6;
    else if (["E05A", "E05B"].includes(this.jn.jntype)) return 8;
    else return 3;
  }
  public layerClicked($event: any, layerPart: LayerPart): void {
    globals.selectedLayer.index = layerPart.layerIndex;
    globals.selectedLayer.orient = layerPart.orient;
    globals.selectedBridge = undefined;
    $event.stopPropagation();
  }
  public bridgeClicked($event: any, bridge: Bridge): void {
    globals.selectedLayer.index = undefined;
    globals.selectedLayer.orient = undefined;
    globals.selectedBridge = bridge.id;
    $event.stopPropagation();
  }
  public getSvg(points: Point[]): string {
    return getPointsSvgFormat(points, 1);
  }
  public getAbsoluteSvg(base: Point, points: Point[]): string {
    return getAbsolutePointsSvgFormat(base, points, 1);
  }
  public getLabelSvg(label: Label): string {
    return this.getSvg([label.start, label.mid, label.end]);
  }
}
