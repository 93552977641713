import { Component } from "@angular/core";
import { Location } from "@angular/common";
import { AuthenticationService } from "src/app/services/authentication/authentication.service";
@Component({
  selector: "app-nav-bar",
  templateUrl: "./nav-bar.component.html",
  styleUrls: ["./nav-bar.component.css"],
})
export class NavBarComponent {
  constructor(
    readonly authService: AuthenticationService,
    private location: Location
  ) {}

  logout = () => this.authService.logout().subscribe();
  back = () => this.location.back();
}
