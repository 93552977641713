import { Component } from "@angular/core";
import { getAllPossibleE01Inputs } from "src/app/helper/input-generator/e01-input-generator";
import { getAllPossibleE02AInputs } from "src/app/helper/input-generator/e02a-input-generator";
import { getAllPossibleE03Inputs } from "src/app/helper/input-generator/e03-input-generator";
import { getAllPossibleE04Inputs } from "src/app/helper/input-generator/e04-input-generator";
import { getAllPossibleE05AInputs } from "src/app/helper/input-generator/e05a-input-generator";
import { getAllPossibleE05BInputs } from "src/app/helper/input-generator/e05b-input-generator";
import { genE06Inputs } from "src/app/helper/input-generator/e06-input-generator";
import { genE07Inputs } from "src/app/helper/input-generator/e07-input-generator";
import { genE10Inputs } from "src/app/helper/input-generator/e10-input-generator";
import { genE11Inputs } from "src/app/helper/input-generator/e11-input-generator";
import { genE12Inputs } from "src/app/helper/input-generator/e12-input-generator";
import { genE13Inputs } from "src/app/helper/input-generator/e13-input-generator";
import { getAllPossibleE16Inputs } from "src/app/helper/input-generator/e16-input-generator";
import { getAllPossibleE17Inputs } from "src/app/helper/input-generator/e17-input-generator";

@Component({
  selector: "app-input-generator",
  templateUrl: "./input-generator.component.html",
  styleUrls: [],
})
export class InputGeneratorComponent {
  constructor() {}

  printE01Inputs(): void {
    const allOpts: any = getAllPossibleE01Inputs();
    this.download("E01_inputs.txt", this.getFileContent("E01", allOpts));
  }
  printE02AInputs(): void {
    const allOpts: any = getAllPossibleE02AInputs();
    this.download("E02A_inputs.txt", this.getFileContent("E02A", allOpts));
  }
  printE03Inputs(): void {
    const allOpts: any = getAllPossibleE03Inputs();
    this.download("E03_inputs.txt", this.getFileContent("E03", allOpts));
  }
  printE04Inputs(): void {
    const allOpts: any = getAllPossibleE04Inputs();
    this.download("E04_inputs.txt", this.getFileContent("E04", allOpts));
  }
  printE05AInputs(): void {
    const allOpts: any = getAllPossibleE05AInputs();
    this.download("E05A_inputs.txt", this.getFileContent("E05A", allOpts));
  }
  printE05BInputs(): void {
    const allOpts: any = getAllPossibleE05BInputs();
    this.download("E05B_inputs.txt", this.getFileContent("E05B", allOpts));
  }
  async printE06Inputs(): Promise<void> {
    const max = 250000; // max inputs in file
    const delay = (ms) => new Promise((res) => setTimeout(res, ms));
    /**
     * If browser crashes set the start value here,the start value can be found in the
     * filename of the last failed file
     */
    let start = 0;
    let opts: any;
    do {
      const O: { opts: any; total: number } = genE06Inputs(start, max);
      opts = O.opts;
      const F = this.getFileContent("E06", opts, O.total);
      this.download(`E06_inputs_${start}-${start + max - 1}.txt`, F);
      start += max;
      await delay(300000); // delay next execution to give browser time to garbage collect old objects
    } while (Object.keys(opts).length > 0);
  }
  async printE07Inputs(): Promise<void> {
    const max = 200000; // max inputs in file
    const delay = (ms) => new Promise((res) => setTimeout(res, ms));
    /**
     * If browser crashes set the start value here,the start value can be found in the
     * filename of the last failed file
     */
    let start = 0;
    let opts: any;
    do {
      const O: { opts: any; total: number } = genE07Inputs(start, max);
      opts = O.opts;
      const F = this.getFileContent("E07", opts, O.total);
      this.download(`E07_inputs_${start}-${start + max - 1}.txt`, F);
      start += max;
      await delay(300000); // delay next execution to give browser time to garbage collect old objects
    } while (Object.keys(opts).length > 0);
  }
  async printE10Inputs(): Promise<void> {
    const max = 1000000; // max inputs in file
    const delay = (ms) => new Promise((res) => setTimeout(res, ms));
    /**
     * If browser crashes set the start value here,the start value can be found in the
     * filename of the last failed file
     */
    let start = 0;
    let opts: any;
    do {
      console.log("started");
      const O: { opts: any; total: number } = genE10Inputs(start, max);
      console.log("input generated");
      opts = O.opts;
      const F = this.getFileContent("E10", opts, O.total);
      console.log("content created");
      this.download(`E10_inputs_${start}-${start + max - 1}.txt`, F);
      console.log("download initiated");
      start += max;
      await delay(3000); // delay next execution to give browser time to garbage collect old objects
    } while (Object.keys(opts).length > 0);
  }
  async printE11Inputs(): Promise<void> {
    const max = 200000; // max inputs in file
    const delay = (ms) => new Promise((res) => setTimeout(res, ms));
    /**
     * If browser crashes set the start value here,the start value can be found in the
     * filename of the last failed file
     */
    let start = 0;
    let opts: any;
    do {
      const O: { opts: any; total: number } = genE11Inputs(start, max);
      opts = O.opts;
      const F = this.getFileContent("E11", opts, O.total);
      this.download(`E11_inputs_${start}-${start + max - 1}.txt`, F);
      start += max;
      await delay(300000); // delay next execution to give browser time to garbage collect old objects
    } while (Object.keys(opts).length > 0);
  }
  async printE12Inputs(): Promise<void> {
    const max = 1000000; // max inputs in file
    const delay = (ms) => new Promise((res) => setTimeout(res, ms));
    /**
     * If browser crashes set the start value here,the start value can be found in the
     * filename of the last failed file
     */
    let start = 0;
    let opts: any;
    do {
      console.log("started");
      const O: { opts: any; total: number } = genE12Inputs(start, max);
      console.log("input generated");
      opts = O.opts;
      const F = this.getFileContent("E12", opts, O.total);
      console.log("content created");
      this.download(`E12_inputs_${start}-${start + max - 1}.txt`, F);
      console.log("download initiated");
      start += max;
      await delay(3000); // delay next execution to give browser time to garbage collect old objects
    } while (Object.keys(opts).length > 0);
  }
  async printE13Inputs(): Promise<void> {
    const max = 200000; // max inputs in file
    const delay = (ms) => new Promise((res) => setTimeout(res, ms));
    /**
     * If browser crashes set the start value here,the start value can be found in the
     * filename of the last failed file
     */
    let start = 0;
    let opts: any;
    do {
      const O: { opts: any; total: number } = genE13Inputs(start, max);
      opts = O.opts;
      const F = this.getFileContent("E13", opts, O.total);
      this.download(`E13_inputs_${start}-${start + max - 1}.txt`, F);
      start += max;
      await delay(300000); // delay next execution to give browser time to garbage collect old objects
    } while (Object.keys(opts).length > 0);
  }
  printE16Inputs(): void {
    const allOpts: any = getAllPossibleE16Inputs();
    this.download("E16_inputs.txt", this.getFileContent("E16", allOpts));
  }
  printE17Inputs(): void {
    const allOpts: any = getAllPossibleE17Inputs();
    this.download("E17_inputs.txt", this.getFileContent("E17", allOpts));
  }
  getFileContent(jnType: string, allOpts: any, total = -1): string {
    let str = "#";
    str += "#*************************************************\n";
    str += `#           ${jnType} all input combinations\n`;
    str += `#           Count: ${Object.keys(allOpts).length}\n`;
    if (total != -1) str += `#           Total: ${total}\n`;
    str += "#*************************************************\n\n";
    Object.keys(allOpts).forEach((key) => {
      const filename = key + ".json";
      const input = allOpts[key];
      str += "#========================================\n";
      str += JSON.stringify({ filename, input }) + "\n\n";
    });
    return str;
  }
  download(filename: string, text: string) {
    console.log(`Downloading: File name: ${filename}, Chars: ${text.length}`);
    var element = document.createElement("a");
    element.setAttribute(
      "href",
      "data:text/plain;charset=utf-8," + encodeURIComponent(text)
    );
    element.setAttribute("download", filename);
    element.style.display = "none";
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
    console.log(`Done: File name: ${filename}`);
  }
}
