import { JnDTO } from "../common/jn.dto";
import { Junction } from "../common/base.junction.model";
import { E06DTO } from "./e06.dto";
import { Point } from "src/app/interfaces/point.interface";
import { PolyLayerPart } from "../common/poly.layer.part.model";
import {
  E06Bridges,
  E06FloorLayerOptions,
  E06LayerOptions,
} from "src/app/config/e06.options";
import { getReqId, mmToM } from "src/app/helper/app.util";
import { BridgeDTO } from "../common/bridge.dto";
import { Bridge } from "../common/bridge.model";
import { Label } from "src/app/interfaces/label.interface";
import { LayerDTO } from "../common/layer.dto";
import { MaterialInfo } from "src/app/interfaces/mat.info.interface";
import { LayerOrient } from "src/app/config/app.const";

export class E06Junction extends Junction {
  floorTop = 475;
  constructor(private dto: JnDTO) {
    super();
    this.generateShapes();
  }
  get jnWidth(): number {
    return 1250; // max possible thickness
  }
  get jnHeight(): number {
    return 1250;
  }
  get tmWidthFactor(): number {
    return 1;
  }
  get tmHeightFactor(): number {
    return 1;
  }
  get id(): string {
    return this.dto._id;
  }
  get name(): string {
    return this.dto.name;
  }
  set name(s: string) {
    this.dto.name = s;
  }
  get jntype(): string {
    return this.dto.jntype;
  }
  get jnData(): E06DTO {
    return this.dto.jndata as E06DTO;
  }
  get jnDto(): JnDTO {
    return this.dto;
  }
  get owner(): string {
    return this.jnDto.owner;
  }
  get calcId(): string {
    let cid = "E06@";
    this.jnData.layers.forEach((lyr: LayerDTO, index: number) => {
      if (this.isLayerHidden(index)) {
        // cid += "@_@_@_@";
        cid += "@_@_@";
      } else {
        const m: MaterialInfo = E06LayerOptions[index][lyr.matIndex];
        const code = m.code;
        const thk = m.thickness_options[lyr.thkIndex].val;
        const kval = m.kvalue_options[lyr.kvlIndex];
        // cid += `@${code}@${thk}@${kval}@`;
        cid += `@${thk}@${kval}@`;
      }
    });
    cid += "@@";
    this.jnData.flrLayers.forEach((lyr: LayerDTO, index: number) => {
      const m: MaterialInfo = E06FloorLayerOptions[index][lyr.matIndex];
      const code = m.code;
      const thk = m.thickness_options[lyr.thkIndex].val;
      const kval = m.kvalue_options[lyr.kvlIndex];
      // cid += `@${code}@${thk}@${kval}@`;
      cid += `@${thk}@${kval}@`;
    });
    return cid + "@";
  }
  get laminateLayerIsOn(): boolean {
    return this.jnData.layers[1].matIndex !== 0; // 0 : Plasterboard
  }
  get blockworkIsOn(): boolean {
    return [0, 1, 2, 3, 4].includes(this.jnData.layers[5].matIndex); // Blockworks
  }
  get totalThickness(): number {
    return this.jnData.layers.reduce((x, lyrDTO, i) => {
      const m: MaterialInfo = E06LayerOptions[i][lyrDTO.matIndex];
      return this.isLayerHidden(i)
        ? x
        : x + m.thickness_options[lyrDTO.thkIndex].val;
    }, 0);
  }
  get flrLayerLeft(): number {
    return this.jnData.layers.reduce((x, lyrDTO, i) => {
      const m: MaterialInfo = E06LayerOptions[i][lyrDTO.matIndex];
      return this.isLayerHidden(i) || i < 3
        ? x
        : x + m.thickness_options[lyrDTO.thkIndex].val;
    }, 0);
  }
  get calcInput(): any {
    const layersy = [];
    this.jnData.layers.forEach((lyr: LayerDTO, index: number) => {
      if (!this.isLayerHidden(index)) {
        const m: MaterialInfo = E06LayerOptions[index][lyr.matIndex];
        const layer: any = {
          material: m.material,
          kvalue: m.kvalue_options[lyr.kvlIndex],
          thickness: mmToM(m.thickness_options[lyr.thkIndex].val),
        };
        layersy.push(layer);
      }
    });
    // add rse and rsi
    layersy[layersy.length - 1].rse = 0.04;
    layersy[0].rsi = 0.13;

    const layersx = [];
    this.jnData.flrLayers.forEach((flyr: LayerDTO, index: number) => {
      const m: MaterialInfo = E06FloorLayerOptions[index][flyr.matIndex];
      const layer: any = {
        material: m.material,
        kvalue: m.kvalue_options[flyr.kvlIndex],
        thickness: mmToM(m.thickness_options[flyr.thkIndex].val),
      };
      layersx.push(layer);
    });
    // add rse and rsi
    layersx[layersx.length - 1].rse = 0.04;
    layersx[0].rsi = 0.13;

    // format bridges data
    // Tarik uses his predefined bridge values. Bridge is sent to server to avoid error but not used
    const bridges = [];
    this.bridges.forEach((bridge: Bridge) => {
      bridges.push({
        material: bridge.material,
        kvalue: bridge.kvalue,
        xstart: mmToM(bridge.base.x),
        xend: mmToM(bridge.base.x + bridge.width),
        ystart: mmToM(this.jnHeight - bridge.base.y - bridge.height),
        yend: mmToM(this.jnHeight - bridge.base.y),
      });
    });
    // create input
    return {
      _id: this.calcId,
      reqid: getReqId(),
      jntype: "E06",
      frame: "timber",
      rotate: 90,
      size: [600, 600],
      layersy: layersy.reverse(),
      layersx: layersx,
      bridges,
      intemp: 20,
      extemp: 0,
    };
  }
  getLayerWidth(lyrIndex: number): number {
    const layerData = this.jnData.layers[lyrIndex];
    const m: MaterialInfo = E06LayerOptions[lyrIndex][layerData.matIndex];
    return m.thickness_options[layerData.thkIndex].val;
  }
  isLayerHidden(i: number): boolean {
    return (
      (i === 0 && !this.laminateLayerIsOn) || (i === 6 && !this.blockworkIsOn)
    );
  }
  public generateShapes(): void {
    this.layerParts = [];
    this.generateLayerParts();
    this.generateFlrLayerParts();
    this.generateBridges();
  }
  public generateLayerParts(): void {
    let bp = this.totalThickness;
    this.jnData.layers.forEach((lyrDTO: LayerDTO, i: number) => {
      if (!this.isLayerHidden(i)) {
        const m: MaterialInfo = E06LayerOptions[i][lyrDTO.matIndex];
        const thk = m.thickness_options[lyrDTO.thkIndex].val;
        bp = bp - thk;
        const points = [
          { x: bp, y: 1 } as Point,
          { x: bp + thk, y: 1 } as Point,
          { x: bp + thk, y: this.jnHeight - 1 } as Point,
          { x: bp, y: this.jnHeight - 1 } as Point,
        ];
        this.layerParts.push(
          new PolyLayerPart(i, points, m.background, LayerOrient.Y)
        );
      }
    });
  }
  private generateFlrLayerParts(): void {
    let bp = this.floorTop;
    this.jnData.flrLayers.forEach((lyrDTO: LayerDTO, i: number) => {
      const m: MaterialInfo = E06FloorLayerOptions[i][lyrDTO.matIndex];
      const thk = m.thickness_options[lyrDTO.thkIndex].val;
      const left = i === 2 ? this.totalThickness : this.flrLayerLeft;
      const points = [
        { x: left, y: bp } as Point,
        { x: this.jnWidth - 1, y: bp } as Point,
        { x: this.jnWidth - 1, y: bp + thk } as Point,
        { x: left, y: bp + thk } as Point,
      ];
      this.layerParts.push(
        new PolyLayerPart(i, points, m.background, LayerOrient.X)
      );
      bp = bp + thk;
    });
  }
  private generateBridges(): void {
    this.bridges = [];
    // Bridge [1]
    const b1: BridgeDTO = E06Bridges[0];
    [b1.base.x, b1.base.y] = [this.totalThickness, this.floorTop - b1.height];
    this.bridges.push(new Bridge(0, b1.background, b1));
  }
  public generateLabels(): void {
    this.labels = [];
    let start = 0;
    // layer [0]
    let i = 0;
    let m = this.getMaterial(i);
    if (m) {
      this.labels[start + i] = {
        text: m,
        start: { x: this.calculateLeft(i), y: 45 } as Point,
        mid: { x: this.calculateLeft(i), y: 45 } as Point,
        end: { x: this.jnWidth, y: 45 } as Point,
      } as Label;
    }
    // layer [1]
    i = 1;
    m = this.getMaterial(i);
    if (m) {
      this.labels[start + i] = {
        text: m,
        start: { x: this.calculateLeft(i), y: 120 } as Point,
        mid: { x: this.calculateLeft(i), y: 120 } as Point,
        end: { x: this.jnWidth, y: 120 } as Point,
      } as Label;
    }
    // layer [2]
    start = 6;
    i = 2;
    m = this.getMaterial(i);
    if (m) {
      this.labels[start + i] = {
        text: m,
        start: { x: this.calculateLeft(i), y: 820 } as Point,
        mid: { x: this.calculateLeft(i), y: 820 } as Point,
        end: { x: this.jnWidth, y: 820 } as Point,
      } as Label;
    }
    // layer [3]
    i = 3;
    m = this.getMaterial(i);
    if (m) {
      this.labels[start + i] = {
        text: m,
        start: { x: this.calculateLeft(i), y: 925 } as Point,
        mid: { x: this.calculateLeft(i), y: 925 } as Point,
        end: { x: this.jnWidth, y: 925 } as Point,
      } as Label;
    }
    // layer [4]
    i = 4;
    m = this.getMaterial(i);
    if (m) {
      this.labels[start + i] = {
        text: m,
        start: { x: this.calculateLeft(i), y: 1030 } as Point,
        mid: { x: this.calculateLeft(i), y: 1030 } as Point,
        end: { x: this.jnWidth, y: 1030 } as Point,
      } as Label;
    }
    // layer [5]
    i = 5;
    m = this.getMaterial(i);
    if (m) {
      this.labels[start + i] = {
        text: m,
        start: { x: this.calculateLeft(i), y: 1120 } as Point,
        mid: { x: this.calculateLeft(i), y: 1120 } as Point,
        end: { x: this.jnWidth, y: 1120 } as Point,
      } as Label;
    }
    // layer [6]
    i = 6;
    m = this.getMaterial(i);
    if (m) {
      this.labels[start + i] = {
        text: m,
        start: { x: this.calculateLeft(i), y: 1230 } as Point,
        mid: { x: this.calculateLeft(i), y: 1230 } as Point,
        end: { x: this.jnWidth, y: 1230 } as Point,
      } as Label;
    }
    // Fixed label
    this.labels[2] = {
      text: "12.5mm softwood k=0.12",
      start: { x: this.totalThickness + 5, y: 410 } as Point,
      mid: { x: 800, y: 225 } as Point,
      end: { x: this.jnWidth, y: 225 } as Point,
    } as Label;
    // Fixed label
    this.labels[3] = {
      text: "250mm Floor cavity k=2.782W, see Section 2.4.4 of BR497, 45mm Timber joists at 600mm centres",
      start: { x: 800, y: 600 } as Point,
      mid: { x: 900, y: 395 } as Point,
      end: { x: this.jnWidth, y: 395 } as Point,
    } as Label;
  }
  private getMaterial(i: number): string {
    if (this.isLayerHidden(i)) return null;
    const layerDTO: LayerDTO = this.jnData.layers[i];
    const m: MaterialInfo = E06LayerOptions[i][layerDTO.matIndex];
    return m.material;
  }
  private getThickness(i: number): number {
    if (this.isLayerHidden(i)) return 0;
    const layerDTO: LayerDTO = this.jnData.layers[i];
    const m: MaterialInfo = E06LayerOptions[i][layerDTO.matIndex];
    return m.thickness_options[layerDTO.thkIndex].val;
  }
  private calculateLeft(i: number): number {
    let w = 1;
    for (let j = 6; j > i; j--) {
      w += this.getThickness(j);
    }
    return w + this.getThickness(i) / 2;
  }
}
