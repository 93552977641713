<div class="footer">
  <p class="knauf-address">
    <b>Knauf Insulation Technical Support Team</b><br>
    PO Box 10, Stafford Road, St Helens,
    Merseyside, WA10 3NS<br> 01744 766 666
  </p>
  <div class="knauf-box">
    <p class="color-yellow"><b>challenge.</b></p>
    <p class="color-blue"><b>create.</b></p>
    <p class="color-green"><b>care.</b></p>
  </div>
</div>