import { Component, OnInit } from '@angular/core';
import { AnalyticsResults } from 'src/app/interfaces/analyticsResults.interface';
import { JunctionService } from 'src/app/services/junction.service';

@Component({
  selector: 'app-analytics-panel',
  templateUrl: './analytics-panel.component.html',
  styleUrls: ['./analytics-panel.component.css']
})
export class AnalyticsPanelComponent implements OnInit {
  historicAnalytics: AnalyticsResults[] = [];
  currentMonthAnalytics: AnalyticsResults;

  tabsLoadingMap = [true, true];

  constructor(private readonly jnService: JunctionService) { }

  ngOnInit(): void {
    this.allJunctionsInPastYear();
    this.allJunctionsInCurrentMonth();
  }

  private allJunctionsInPastYear = () =>
    this.jnService.getAllJunctionsInPastYear().subscribe({
      next: data => {
        this.historicAnalytics = data
        this.tabsLoadingMap[0] = false;
      },
      error: err => console.error(`Error retrieving monthly analytics data: ${err.message ?? err}`)
    });

  private allJunctionsInCurrentMonth = () =>
    this.jnService.getAllJunctionsInCurrentMonth().subscribe(
      {
        next: data => {
          this.currentMonthAnalytics = data
          this.tabsLoadingMap[1] = false;
        },
        error: err => console.error(`Error retrieving yearly analytics data: ${err.message ?? err}`)
      });
}
