import { createNewJnId } from "src/app/helper/app.util";
import { JnDTO } from "../common/jn.dto";
import { LayerDTO } from "../common/layer.dto";
import { E01DTO } from "./e01.dto";
import { E01Junction } from "./e01.junction.model";

export function getNewE01Junction(): E01Junction {
  const jnDTO = {
    _id: createNewJnId(),
    name: "E-01 Lintel",
    jntype: "E01",
    jndata: {
      layers: [
        {
          matIndex: 0,
          thkIndex: 0,
          kvlIndex: 0,
        } as LayerDTO,
        {
          matIndex: 0,
          thkIndex: 0,
          kvlIndex: 0,
        } as LayerDTO,
        {
          matIndex: 0,
          thkIndex: 0,
          kvlIndex: 0,
        } as LayerDTO,
        {
          matIndex: 0,
          thkIndex: 0,
          kvlIndex: 0,
        } as LayerDTO,
        {
          matIndex: 2,
          thkIndex: 2,
          kvlIndex: 0,
        } as LayerDTO,
        {
          matIndex: 5,
          thkIndex: 0,
          kvlIndex: 0,
        } as LayerDTO,
        {
          matIndex: 0,
          thkIndex: 0,
          kvlIndex: 0,
        } as LayerDTO,
      ],
    } as E01DTO,
  } as JnDTO;
  return new E01Junction(jnDTO);
}
