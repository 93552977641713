import { convertStringToPoints } from "src/app/helper/app.util";
import { Point } from "src/app/interfaces/point.interface";
import { globals } from "src/app/store/global.store";
import { ExtraDTO } from "./extra.dto";

export class Extra {
  constructor(public index: number, private dto: ExtraDTO) {}

  get id(): string {
    return `ext-${this.index}`;
  }
  get className(): string {
    return `cls-${this.id}`;
  }
  get patternName(): string {
    return `pat-${this.id}`;
  }
  get selectedClass(): string {
    return this.isSelected ? "selected-element-extra" : "";
  }
  get isSelected(): boolean {
    return globals.selectedExtra === this.id;
  }
  get base(): Point {
    return this.dto.base;
  }
  get points(): Point[] {
    return convertStringToPoints(this.dto.points);
  }
  get background(): number {
    return this.dto.background;
  }
}
