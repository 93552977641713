export function getTMColor(val: number): string {
    if (val === 0) { return 'white'; }
    // blue
    if (val < 0.2) { return 'MidnightBlue'; }
    if (val < 0.4) { return 'Navy'; }
    if (val < 0.6) { return 'DarkBlue'; }
    if (val < 0.8) { return 'MediumBlue'; }
    if (val < 1.0) { return 'Blue'; }
    // sky
    if (val < 2) { return 'DodgerBlue'; }
    if (val < 3) { return 'DeepSkyBlue'; }
    if (val < 4) { return 'SkyBlue'; }
    if (val < 5) { return 'LightBlue'; }
    // green
    if (val < 6) { return 'Lime'; }
    if (val < 7) { return 'LawnGreen'; }
    if (val < 8) { return 'Chartreuse'; }
    if (val < 9) { return 'GreenYellow'; }
    // yellow
    if (val < 10) { return 'Yellow'; }
    if (val < 11) { return 'Yellow'; }
    if (val < 12) { return 'PaleGoldenrod'; }
    if (val < 13) { return 'Gold'; }
    if (val < 14) { return 'Gold'; }
    // orange
    if (val < 15) { return 'Orange'; }
    if (val < 16) { return 'DarkOrange'; }
    if (val < 17) { return 'Coral'; }
    if (val < 18) { return 'Tomato'; }
    if (val < 19) { return 'OrangeRed'; }
    // red
    if (val < 20) { return 'Red'; }
    if (val < 21) { return 'Crimson'; }
    if (val < 22) { return 'Firebrick'; }
    if (val < 23) { return 'DarkRed'; }
    if (val < 24) { return 'DarkRed'; }
    if (val < 25) { return 'DarkRed'; }
    if (val < 26) { return 'DarkRed'; }
    if (val < 27) { return 'DarkRed'; }
    if (val < 28) { return 'DarkRed'; } else { return 'black'; }
  }
