<div
  class="th-model-area"
  [ngStyle]="{
    'width.px': width,
    'height.px': height
  }"
>
  <canvas
    id="{{ jn.canvasId }}"
    [ngClass]="[canvasClass]"
    (mousemove)="mouseMove($event)"
    (mouseleave)="mouseLeave($event)"
  ></canvas>
  <div
    class="tooltip-text"
    [ngStyle]="{
      visibility: toolTip.visibility,
      'top.px': toolTip.mouse.y,
      'left.px': toolTip.mouse.x
    }"
  >
    {{ toolTip.text }}
  </div>
  <ng-container *ngIf="displayLoader">
    <div style="max-width: 15em">
      <img src="/assets/images/loader.gif" style="opacity: 0.3" />
      <div style="color: #f0f0f0; width: 100%">Not implemented yet</div>
    </div>
  </ng-container>
</div>
