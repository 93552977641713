import { Component, Input } from "@angular/core";
import { Junction } from "src/app/junctions/common/base.junction.model";

@Component({
  selector: "app-jn-svg-container",
  templateUrl: "./jn-svg-container.component.html",
  styleUrls: ["../../../common/common.css"],
})
export class JnSvgContainerComponent {
  @Input() jn: Junction;

  constructor() {}

  get height() {
    return this.jn.vw;
  }
  get width() {
    return this.jn.vw;
  }
}
