<div id="result-box" class="result-box">
  <div class="row">
    <div class="col-md-6">
      <h3>PSI Calculation</h3>
      <div class="result-wrp">
        <p>Q-value:</p>
        <p class="result-number">{{ qValue }}</p>
        <p>[W/m]</p>
      </div>
      <ng-container>
        <div class="result-wrp">
          <p>U-value-1:</p>
          <p class="result-number">{{ uValue1 }}</p>
          <p>W/m².K</p>
        </div>
        <div class="result-wrp">
          <p>U-value-2:</p>
          <p class="result-number">{{ uValue2 }}</p>
          <p>W/m².K</p>
        </div>
      </ng-container>
      <div class="result-wrp">
        <p>F-value:</p>
        <p class="result-number">{{ fValue }}</p>
        <p>&nbsp;</p>
      </div>
      <div class="result-wrp">
        <p>PSI:</p>
        <p class="result-number psi">{{ psiValue }}</p>
        <p>W/mK</p>
      </div>
    </div>
    <div class="col-md-6">
      <div class="buttons-wrp">
        <ng-container *ngIf="view('temperature')">
          <button type="button" class="btn-blue" (click)="toggleView()">
            Junction View
          </button>
        </ng-container>

        <ng-container *ngIf="view('junction')">
          <button type="button" class="btn-blue" (click)="toggleView()">
            Temperature Distribution
          </button>
          <button *ngIf="
              ((authService.getCurrentUser$() | async) && !jn.owner) ||
              (authService.getCurrentUser$() | async)?._id === jn.owner
            " type="button" class="btn-green" data-toggle="modal" data-target="#jnSaveModal"
            (click)="jnName = jn.name">
            Save Junction
          </button>
          <button *ngIf="authService.getCurrentUser$() | async" type="button" class="red-btn"
            (click)="generatePdfData()" data-toggle="modal" data-target="#pdfModal">
            Run PDF Report
          </button>
          <button *ngIf="!(authService.getCurrentUser$() | async)" type="button" class="red-btn" (click)="login()">
            Run PDF Report
          </button>
        </ng-container>
      </div>
    </div>
  </div>
</div>

<!-- PDF modal -->
<div id="pdfModal" class="modal fade" tabindex="-1" aria-labelledby="pdfReportModalLabel" aria-hidden="true"
  style="top: 0" role="dialog">
  <div class="modal-dialog modal-dialog-scrollable mat-modal">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title" id="pdf-dialog-label">
          Junction PSI Calculation Report
        </h4>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body" style="max-height: 50vh">
        <app-pdf-page [jn]="jn"> </app-pdf-page>
      </div>
      <div class="modal-footer" style="background: inherit; justify-content: center">
        <button type="button" title="Close this dialogue" class="btn yellow-btn" data-dismiss="modal">
          Close
        </button>
        <button type="button" title="Close this dialogue" class="btn blue-btn" data-dismiss="modal"
          (click)="downloadPdf()" [disabled]="pdfGenerationBtn.disabled">
          {{ pdfGenerationBtn.text }}
        </button>
      </div>
    </div>
  </div>
</div>

<!-- Junction Save modal -->
<div id="jnSaveModal" class="modal fade" tabindex="-1" aria-labelledby="jnSaveModalLabel" aria-hidden="true"
  style="top: 0" role="dialog">
  <div class="modal-dialog modal-dialog-scrollable mat-modal">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title" id="pdf-dialog-label">Save Junction</h4>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div>
          <mat-form-field appearance="fill" autofilled="true">
            <mat-label>Junction Type</mat-label>
            <input type="text" matInput readonly value="{{ jn.jntype }}" placeholder="Ex. my first junction" />
          </mat-form-field>
          <mat-form-field appearance="fill" autofilled="true">
            <mat-label>Junction Name</mat-label>
            <input type="text" matInput required [(ngModel)]="jnName" placeholder="Ex. my first junction" />
          </mat-form-field>
        </div>
      </div>
      <div class="modal-footer" style="background: inherit; justify-content: center">
        <button type="button" title="Close this dialogue" class="btn yellow-btn" data-dismiss="modal">
          Cancel
        </button>
        <button type="button" title="Save junction under your account and close dialogue" class="btn blue-btn"
          data-dismiss="modal" (click)="saveJunction()" [disabled]="saveJnBtn.disabled">
          {{ saveJnBtn.text }}
        </button>
      </div>
    </div>
  </div>
</div>